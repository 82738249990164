import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UrlQueryService {
  params: Params = {};

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  subscribe() {
    this.route.queryParams.subscribe((params: Params) => this.params = params);
  }

  clear() {
    this.params = {};
  }

  applyParamsToUrl() {
    void this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: this.params
      }
    );
  }
}
