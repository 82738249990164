import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DefaultNavigateComponent } from '@fnc-core/components/default-navigate/default-navigate.component';
import { SettingsSideMenuItems } from '@fnc-core/components/settings-page/settings.constant';
import { PermissionsService } from '@mm-ui/core';

@Component({
  template: '<div></div>'
})
export class DashboardSettingsComponent extends DefaultNavigateComponent {
  protected sideMenuItems = SettingsSideMenuItems;

  constructor(router: Router, permissions: PermissionsService) {
    super(router, permissions);
  }
}
