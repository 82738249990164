import dayjs, { ManipulateType } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import { ISO_DATE_FORMAT, ISO_END_OF_DAY_TIME, ISO_START_OF_DAY_TIME } from '@fnc-shared/constants/date.constant';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const defaultDaysDiff = 5;
export const abbreviatedDateFormat = 'DD. MMM YYYY';

export const isDueDateClose = (dueDate: string | Date, difference: number = defaultDaysDiff) => {
  if (dueDate) {
    const diffInDays = getDiffDays(dueDate);

    if (diffInDays) {
      return diffInDays <= difference;
    }
  }

  return false;
};

export const getDiffDays = (dateOne: string | Date, dateTwo?: string | Date): number | null => {
  if (!dateOne) {
    return null;
  }

  const dateFormattedOne = dayjs(dateOne).utc().startOf('day');
  const dateFormattedTwo = dateTwo ? dayjs(dateTwo).utc().startOf('day') : dayjs().utc().startOf('day');

  return dayjs(dateFormattedOne).diff(dateFormattedTwo, 'day');
};

export const isSameDate = (dateOne: Date, dateTwo: Date) => {
  if (!dateOne || !dateTwo) {
    return false;
  }

  const dateFormattedOne = dayjs(dateOne).utc().startOf('day');
  const dateFormattedTwo = dayjs(dateTwo).utc().startOf('day');

  return !dayjs(dateFormattedOne).diff(dateFormattedTwo, 'day');
};

export const isDateSameOrAfter = (pastDate: string | Date, currentDate: string | Date) => {
  if (!pastDate || !currentDate) {
    return null;
  }

  const dateFormattedOne = dayjs(pastDate).utc().startOf('day');
  const dateFormattedTwo = dayjs(currentDate).utc().startOf('day');

  return dateFormattedTwo.isSameOrAfter(dateFormattedOne);
};

export const isDateSameOrBefore = (futureDate: string | Date, currentDate: string | Date) => {
  if (!futureDate || !currentDate) {
    return null;
  }

  const dateFormattedOne = dayjs(futureDate).utc().startOf('day');
  const dateFormattedTwo = dayjs(currentDate).utc().startOf('day');

  return dateFormattedTwo.isSameOrBefore(dateFormattedOne);
};

export const getFormattedDate = (date: string | Date, template = ISO_DATE_FORMAT) => dayjs(date).format(template);

export const getDateBeforeInWeeks = (weeks: number) => getDateBefore(weeks, 'week');

export const getDateBeforeInDays = (days: number) => getDateBefore(days, 'day');

export const getDateClampedToStartOfDay = (date: Date) => dayjs(dayjs(date).format(ISO_DATE_FORMAT) + ISO_START_OF_DAY_TIME).toDate();

export const getDateClampedToEndOfDay = (date: Date) => dayjs(dayjs(date).format(ISO_DATE_FORMAT) + ISO_END_OF_DAY_TIME).toDate();

const getDateBefore = (value: number, type: ManipulateType) => dayjs(new Date()).subtract(value, type).toDate();

export const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);

  return result;
};
