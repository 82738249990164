<div class="fnc-panel__footer--is-sticky">
  <div>
    <div class="fnc-footer-sticky__buttons">
      <button mmButton size="small" type="submit" kind="primaryRaised" (click)="onSubmit()">
        {{ primaryButtonText | translate }}
      </button>
      <button mmButton kind="secondary" size="small" (click)="onCancel()">
        {{ secondaryButtonText | translate }}
      </button>
    </div>
  </div>
</div>
