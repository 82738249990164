<mm-vertical-header [canExpand]="canExpand">
  <ng-container *ngFor="let item of mainMenu">
    <mm-vh-nav-item
      *mmPermissions="item.rights; operator: '$or'"
      [extraLink]="item.extraLink"
      [href]="item.href"
      [icon]="item.icon"
      [routeObj]="item.routerObj"
      [title]="item.title"
    ></mm-vh-nav-item>
  </ng-container>

  <ng-container *ngFor="let item of bottomMenu" bottom>
    <mm-vh-nav-item
      *mmPermissions="item.rights; operator: '$or'"
      [bullet]="item.bullet"
      [extraLink]="item.extraLink"
      [href]="item.href"
      [icon]="item.icon"
      [menuClass]="item.menuClass"
      [menuTemplate]="item.menuTemplate"
      [routeObj]="item.routerObj"
      [title]="item.title"
    ></mm-vh-nav-item>
  </ng-container>
</mm-vertical-header>

<ng-template #notificationsMenu>
  <mm-sidebar>
    <fnc-notifications-list></fnc-notifications-list>
  </mm-sidebar>
</ng-template>

<ng-template #profileMenu>
  <mm-sidebar>
    <div mmSidebarTitle>{{ "NAVIGATION.BOTTOM.PROFILE" | translate }}</div>
    <a [href]="profileSettingsHref" [icon]="'settings'" mmSidebarLink>{{ "NAVIGATION.BOTTOM.SETTINGS" | translate }}</a>
    <a [href]="logoutHref" [icon]="'logout'" mmSidebarLink>{{ "NAVIGATION.BOTTOM.LOGOUT" | translate }}</a>
  </mm-sidebar>
</ng-template>
