import { Injectable } from '@angular/core';
import { MmAlertService } from '@mm-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlags } from './disabled-features.model';
import { DisabledFeatureMessage } from '@fnc-shared/services/disabled-features/disabled-features.constant';

@Injectable({
  providedIn: 'root'
})
export class DisabledFeaturesService {
  private static flagFromRole(role: string) {
    return role.replace(/^FF_/g, '');
  }

  list = new Set<string>();

  get flags() {
    const flags: FeatureFlags = {};
    Array.from(this.list).forEach(item => (flags[DisabledFeaturesService.flagFromRole(item)] = true));

    return flags;
  }

  private isAlertsShown = false;

  constructor(private readonly mmAlertService: MmAlertService, private readonly translateService: TranslateService) {}

  showAlerts() {
    !this.isAlertsShown &&
      Array.from(this.list).forEach(role => {
        const { type, alert } = DisabledFeatureMessage[role];
        this.mmAlertService.push('global', {
          autoHideTime: 0,
          type,
          size: 'large',
          alertDescription: this.translateService.instant(alert) as string
        });
      });
    this.isAlertsShown = true;
  }
}
