import { Directive, HostListener } from '@angular/core';
import { ScrollService } from '../services/scroll.service';

@Directive({
  selector: '[fncScrollListener]'
})
export class ScrollListenerDirective {
  constructor(private readonly scrollService: ScrollService) { }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    this.scrollService.scrollableContainerEvent.next(event);
  }
}
