import { Injectable } from '@angular/core';
import { DataLayer, DataLayerData } from '@fnc-core/services/google-analytics/google-analytics.interfaces';
import { GAEventType } from '@fnc-core/services/google-analytics/google-analytics.constants';
import { environment } from '@fnc-env/environment';
import { UserService } from '@fnc-core/services/user/user.service';

declare let dataLayer: DataLayer;

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  userId: string;

  constructor(readonly userService: UserService) {
    this.userId = this.userService.profile.id.toString();
  }

  pushEvent(event: GAEventType, data: DataLayerData) {
    if (!environment.googleTagManagerId) {
      return;
    }

    dataLayer.push({
      user_id: this.userId,
      event,
      ...data
    });
  }
}
