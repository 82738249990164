import { Pipe, PipeTransform } from '@angular/core';
import { MmDatePipe } from '@mm-ui/components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

@Pipe({
  name: 'monthYear'
})
export class MonthYearDatePipe implements PipeTransform {
  readonly dateFormat = 'MMM YYYY';

  constructor(private readonly mmDatePipe: MmDatePipe) {}

  transform(value: string): string {
    return this.mmDatePipe.transform(value, this.dateFormat, true);
  }
}
