import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { NotificationChannel } from '@fnc-core/services/notification-events/notification-events.constant';
import { Notification } from '@mm-ui/core';

export interface NotificationState extends EntityState<Notification<NotificationChannel>> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'notification' })
export class NotificationStore extends EntityStore<NotificationState, Notification<NotificationChannel>> {
  constructor() {
    super();
  }
}
