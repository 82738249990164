import { ColumnConfig } from '@mm-ui/components/lib/components/datatable/models/datatable.model';
import { EditableTableColumnConfig } from '@mm-ui/components/lib/components/editable-table/models/editable-column-config.model';
import { PositionItem, SwitchInputMode } from '@fnc-shared-fnc/constants/vendor-document-form.constant';

export declare type SortingValueGetter<T> = (obj: T) => string | Date;

export const getSortingOrder = <T>(a: T, b: T, order: number, valueGetter: string | SortingValueGetter<T>, nullValueFirst = true) => {
  let aValue: string | Date;
  let bValue: string | Date;

  if (typeof valueGetter === 'string') {
    aValue = (a as any)[valueGetter] as string | Date;
    bValue = (b as any)[valueGetter] as string | Date;
  }

  if (typeof valueGetter === 'function') {
    aValue = valueGetter(a);
    bValue = valueGetter(b);
  }

  if (aValue === null) {
    return nullValueFirst ? -order : order;
  }

  if (bValue === null) {
    return nullValueFirst ? order : -order;
  }

  if (aValue > bValue) {
    return order;
  }

  if (aValue < bValue) {
    return -order;
  }

  return 0;
};

export const mapUnitsGtinVan = (units: PositionItem[]): PositionItem[] => {
  return units.map(unit => {
    if (unit.gtinVan) {
      return unit;
    }

    const mode = unit.gtin ? SwitchInputMode.GTIN : SwitchInputMode.VAN;
    const content = unit.gtin ? unit.gtin : unit.van || null;

    return {
      ...unit,
      gtinVan: {
        mode,
        content
      }
    };
  });
};

export const mapEditableColumnsConfig = (tableColumnConfig: ColumnConfig[], formColumnConfig: Partial<EditableTableColumnConfig>[]) => {
  return tableColumnConfig.map(column => {
    const extendedColumn = formColumnConfig.find(col => column.prop === col.prop);

    return { ...column, ...extendedColumn };
  });
};

export const intersectRows = <T extends { id: number }>(tableRows: (T | RecordOf<never>)[], updateRows: T[]) =>
  tableRows.map((tableRow: T) => ({ ...tableRow, ...(updateRows.find(row => row.id === tableRow.id) ?? {}) }));
