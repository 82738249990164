<div *ngIf="exportRecord" class="fnc-reports-export-link">
  <ng-container [ngSwitch]="exportRecord.status">
    <ng-container *ngSwitchCase="reportStatus.FAILED">
      <mm-icon class="fnc-reports-export-link__error-icon" kind="alert-error-small" size="0.6"></mm-icon>
      <span class="fnc-reports-export-link__error">
        {{ 'ACCOUNT_REPORTS.EXPORT.COMMON_ERROR' | translate }}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="reportStatus.COMPLETED">
      <span (click)="onDownloadReport(exportRecord)" class="fnc-reports-export-link__file-name">
        {{ exportRecord.files[0]?.name }} ({{ exportRecord.createdAt | mmDate: settingsService.locale.shortDateTime }})
      </span>
      <a [download]="exportRecord.files[0]?.name" class="fnc-hidden fnc-reports-export-link__anchor"></a>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <mm-icon *ngSwitchDefault animate="rotate" class="fnc-reports-export-link__progress-icon" kind="reload" size="0.6"></mm-icon>
      <span class="fnc-reports-export-link__progress">
        {{ 'ACCOUNT_REPORTS.EXPORT.GENERATING' | translate: { name: exportRecord.name } }}
      </span>
    </ng-container>
  </ng-container>
</div>
