import { Component, Input } from '@angular/core';

@Component({
  selector: 'fnc-permitted-link',
  template: `
    <a
      *mmPermissions="permissions; else noPermissions"
      [routerLink]="routerLink"
      [title]="title"
      (click)="onClick($event)"
      target="_blank"
    >
      <ng-container *ngTemplateOutlet="value"></ng-container>
    </a>
    <ng-template #value>
      <ng-content></ng-content>
    </ng-template>
    <ng-template #noPermissions>
      <span *ngIf="!hideWithoutPermissions"><ng-container *ngTemplateOutlet="value"></ng-container></span>
    </ng-template>
  `
})
export class PermittedLinkComponent {
  @Input() permissions: string | string[];
  @Input() routerLink?: string[] | string | null;
  @Input() title = '';
  @Input() hideWithoutPermissions = false;

  onClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
