import { IDBPDatabase } from 'idb';
import { IDBPTransaction, StoreNames } from 'idb/build/esm/entry';
import { FailedMessageRenderer } from '@fnc-modules/failed-messages/models/failed-message.interface';
import { DbMigrationCmd } from '@fnc-shared/services/db/db-migrationCmd.constant';
import { dbMigrations } from '@fnc-shared/services/db/migrations/db-migrations';

export const preMigrationActions = (
  database: IDBPDatabase<FailedMessageRenderer>,
  store: string,
  _transaction: IDBPTransaction<FailedMessageRenderer, StoreNames<FailedMessageRenderer>[], 'versionchange'>
) => {
  const actions = dbMigrations[store as keyof typeof dbMigrations];
  actions.forEach(action => {
    switch (action.cmd) {
      case DbMigrationCmd.RESET:
        if (database.objectStoreNames.contains(store)) {
          database.deleteObjectStore(store);
        }
        database.createObjectStore(store, { keyPath: action.keyPath });
        break;
      case DbMigrationCmd.CREATE:
        if (!database.objectStoreNames.contains(store)) {
          database.createObjectStore(store, { keyPath: action.keyPath });
        }
        break;
    }
  });
};

export const applyMigrationActions = (
  database: IDBPDatabase<FailedMessageRenderer>,
  stores: string[],
  transaction: IDBPTransaction<FailedMessageRenderer, StoreNames<FailedMessageRenderer>[], 'versionchange'>
) => {
  stores.forEach(store => preMigrationActions(database, store, transaction));
  transaction.oncomplete = () =>
    stores.map(store =>
      dbMigrations[store as keyof typeof dbMigrations].forEach(action => {
        switch (action.cmd) {
          case DbMigrationCmd.PUT:
            void database.put(store, action.data);
            break;
          case DbMigrationCmd.DELETE:
            void database.delete(store, action.key);
            break;
        }
      })
    );
};
