import { arrayFiltersMap } from './array-filters.constant';
import { binaryFiltersMap } from './binary-filters.constant';
import { dateRangeFiltersMap } from './date-range-filters.constant';
import { FilterTypes } from './filter-types.constant';
import { regularFiltersMap } from './regular-filters.constant';
import { searchFiltersMap } from './search-filters.constant';

export const filterTypesMap: RecordOf<FilterTypes> = {
  ...arrayFiltersMap,
  ...binaryFiltersMap,
  ...dateRangeFiltersMap,
  ...regularFiltersMap,
  ...searchFiltersMap
};
