import { EntityState } from '@datorama/akita';
import { ColorType } from '@mm-ui/components/lib/components/icon/color.type';
import { BigNumber } from 'bignumber.js';
import { DocumentType } from '@fnc-shared-fnc/constants/document-type.constant';
import { InvoiceStatus } from '@fnc-shared-fnc/constants/invoice-status.constant';
import { FulfillmentOrderBlock, VendorDocumentConflicts, VendorNoteView } from '@fnc-shared-fnc/constants/vendor-document-form.constant';
import { MatchingConflict } from '@fnc-shared-fnc/models/matching-conflicts.model';

export type VendorNotesState = EntityState<VendorNoteView>;

export interface VendorNotesServerResponse {
  data: VendorNoteView[];
  itemsPerPage: number;
  page: number;
  total: number;
}

export enum VendorNoteOperationType {
  ACCEPT = 'ACCEPT',
  ACCEPTEDIT = 'ACCEPTEDIT',
  SETTLE = 'SETTLE',
  APPROVE = 'APPROVE',
  EDIT = 'EDIT',
  DECLINE = 'DECLINE',
  MARK_AS_IMPORTANT = 'MARK_AS_IMPORTANT',
  MARK_AS_NOT_IMPORTANT = 'MARK_AS_NOT_IMPORTANT',
  VIEW_VENDOR_BALANCE = 'VIEW_VENDOR_BALANCE',
  VIEW_PAYMENT_DOCUMENT = 'VIEW_PAYMENT_DOCUMENT',
  SUBMIT_FOR_PAYMENT = 'SUBMIT_FOR_PAYMENT',
  UPLOAD_FILES = 'UPLOAD_FILES',
  REPLACE_FILES = 'REPLACE_FILES',
  REVERT = 'REVERT_TO_WAITING_FOR_CLEARING'
}

export enum VendorNoteStatus {
  NEW = 'NEW',
  DRAFT = 'DRAFT',
  WAITING_FOR_CLARIFICATION = 'WAITING_FOR_CLARIFICATION',
  WAITING_FOR_CLEARING = 'WAITING_FOR_CLEARING',
  APPROVED = 'APPROVED',
  SUBMITTED_FOR_PAYMENT = 'SUBMITTED_FOR_PAYMENT',
  SETTLED = 'SETTLED',
  DECLINED = 'DECLINED',
  APPLIED = 'APPLIED'
}

export type VendorNoteType = DocumentType.CREDIT_NOTE | DocumentType.DEBIT_NOTE | DocumentType.SKONTO_NOTE;

export enum VendorNoteActionCmd {
  ACCEPT = 'accept',
  ACCEPTEDIT = 'acceptEdit',
  APPROVE = 'approve',
  SETTLE = 'settle',
  EDIT = 'edit',
  DECLINE = 'decline',
  CHANGE_IMPORTANCE = 'changeImportance',
  VIEW_VENDOR_BALANCES = 'viewVendorBalances',
  VIEW_PAYMENT_DOCUMENT = 'viewPaymentDocument',
  UPLOAD_FILES = 'uploadFiles',
  REPLACE_FILES = 'replaceFiles',
  EXPORT_NOTE = 'exportNote',
  REVERT = 'revert',
  VIEW_MATСHING_PAGE = 'viewMatchingPage',
  VIEW_IN_FO_PAGE = 'viewInFinanceOffice',
  EDIT_COMMENT = 'editComment'
}

export enum VendorNotePositionType {
  PRODUCT = 'PRODUCT',
  SERVICE = 'SERVICE'
}

export type VendorNoteActionCmdFunctions = (...args: unknown[]) => void;

export type VendorNoteActionCmdListRecord = Record<VendorNoteActionCmd, VendorNoteActionCmdFunctions>;

export interface VendorNoteStatusPayload {
  action: VendorNoteOperationType;
  reason: string;
}

export interface VendorNoteStatusReason {
  title: string;
  content: string;
  color: ColorType;
}

export interface VendorNoteViewData {
  noteData: VendorNoteView;
  hasDiscount: boolean;
  bruttoSubtotal: BigNumber;
  nettoSubtotal: BigNumber;
  bruttoTotal: BigNumber;
  nettoTotal: BigNumber;
  calculatedBruttoTotal: BigNumber;
  calculatedNettoTotal: BigNumber;
  vatAmountsTotal: BigNumber;
  calculatedVatAmountsTotal: BigNumber;
  vatAmountsSubtotal: BigNumber;
  unitBlocks: FulfillmentOrderBlock[];
  conflicts: Partial<NoteDocumentConflicts>;
}

export interface NoteDocumentConflicts extends VendorDocumentConflicts {
  noteDate: MatchingConflict[];
}

export const InvalidInvoiceStatusToStartWorkflow = [
  InvoiceStatus.DRAFT,
  InvoiceStatus.WAITING_FOR_CLEARING,
  InvoiceStatus.WAITING_FOR_APPROVAL,
  InvoiceStatus.SUBMITTED_FOR_PAYMENT,
  InvoiceStatus.SUSPENDED,
  InvoiceStatus.DECLINED
];

export enum VendorNoteReasonType {
  CORRECTION = 'CORRECTION',
  RETURN = 'RETURN'
}
