import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asWeeks'
})
export class AsWeeksPipe implements PipeTransform {
  readonly daysInWeek = 7;

  transform(value: WeakNumber): string {
    const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;
    const weeksString = parsedValue > this.daysInWeek ? `${Math.floor(parsedValue / this.daysInWeek)}w` : '';
    const daysString = parsedValue % this.daysInWeek ? ` ${parsedValue % this.daysInWeek}d` : '';

    return weeksString + daysString;
  }
}
