import { Directive, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SideMenuProtected, SideMenuItemProtected } from '@fnc-shared/interfaces/side-menu.interface';
import { Path } from '@fnc-shared/constants/path.constant';
import { PermissionsService } from '@mm-ui/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class DefaultNavigateComponent implements OnInit {
  protected sideMenuItems: SideMenuProtected[];

  constructor(protected router: Router, protected permissions: PermissionsService) {}

  ngOnInit() {
    void this.router.navigate((this.findPermittedItem().routerLink || ['/', Path.home]) as string[]);
  }

  protected findPermittedItem(): Partial<SideMenuItemProtected> {
    let permittedItem: SideMenuItemProtected;
    for (const group of this.sideMenuItems) {
      if (group.items) {
        for (const subGroup of group.items) {
          permittedItem = subGroup.items.find((item: SideMenuItemProtected) => this.permissions.isPermitted(item.rights));
          if (permittedItem) {
            return permittedItem;
          }
        }
      }
    }

    return { routerLink: '' };
  }
}
