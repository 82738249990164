import { Route } from '@angular/router';
import { DictionaryResolverService, PermissionsGuard } from '@mm-ui/core';
import { TranslationFulfilmentModules } from '@fnc-app/routes/translation-modules';
import { DashboardFulfilmentComponent } from '@fnc-core/components/dashboard-fulfilment/dashboard-fulfilment.component';
import { FulfilmentPageComponent } from '@fnc-core/components/fulfilment-page/fulfilment-page.component';
import { InitGuard } from '@fnc-core/guards/init.guard';
import { Dictionary } from '@fnc-shared/constants/dictionary.constant';
import { Path } from '@fnc-shared/constants/path.constant';

export const FulfilmentRoute: Route = {
  path: Path.fulfilment,
  canActivate: [InitGuard],
  component: FulfilmentPageComponent,
  data: {
    breadcrumb: 'NAVIGATION.MAIN.FULFILMENT',
    i18n: TranslationFulfilmentModules
  },
  children: [
    {
      path: '',
      canActivate: [PermissionsGuard],
      component: DashboardFulfilmentComponent,
      data: {
        permissions: {
          rule: {
            $or: ['ROLE_CAN_VIEW_SALE_ORDERS', 'ROLE_CAN_VIEW_RETURNS_LIST']
          },
          navigateTo: ['/', Path.forbidden]
        }
      }
    },
    {
      path: Path.returns,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/buyer-returns/buyer-returns.module').then(m => m.BuyerReturnsModule),
      data: {
        breadcrumb: 'NAVIGATION.FULFILMENT.RETURNS',
        title: 'NAVIGATION.FULFILMENT.RETURNS',
        permissions: {
          rule: 'ROLE_CAN_VIEW_RETURNS_LIST',
          navigateTo: ['/', Path.forbidden]
        },
        dictionaries: Dictionary.SALES_CHANNELS,
        hasInnerPanel: true
      },
      resolve: {
        dictionaries: DictionaryResolverService
      }
    },
    {
      path: Path.saleOrders,
      loadChildren: () => import('../modules/sale-orders/orders.module').then(m => m.SaleOrdersModule),
      canActivate: [PermissionsGuard],
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.SALE_ORDERS',
        title: 'NAVIGATION.ACCOUNTING.SALE_ORDERS',
        permissions: { rule: 'ROLE_CAN_VIEW_SALE_ORDERS', navigateTo: ['/', Path.forbidden] },
        dictionaries: [Dictionary.SALES_PLATFORMS, Dictionary.SALES_COUNTRIES, Dictionary.KEY_ACCOUNTS_SALES_CHANNELS]
      }
    }
  ]
};
