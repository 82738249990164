import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductPopupService } from '@fnc-shared-fnc/services/product-popup-service/product-popup.service';
import { DEFAULT_PRODUCT_IMG_URL } from '@fnc-shared/constants/default-product-image.constant';
import { TOOLTIP_DELAY } from '@fnc-shared/constants/delay.constant';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProductData, ProductDataMediaType, ProductDataName } from './product-popup.interface';

@Component({
  selector: 'fnc-product-popup',
  templateUrl: './product-popup.component.html',
  styleUrls: ['./product-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProductPopupService]
})
export class ProductPopupComponent {
  @Input() placement = 'auto';
  @Input() popoverDelay = TOOLTIP_DELAY;
  @Input() gtin: string;
  @Input() sku: string;

  isLoading$ = new BehaviorSubject(true);
  productData$ = new BehaviorSubject<ProductData | RecordOf<never>>(null);
  imageUrl$ = new BehaviorSubject('');

  constructor(private readonly popupService: ProductPopupService) { }

  loadData() {
    this.startLoading();

    if (this.productData$.value) {
      return;
    }

    if (this.gtin || this.sku) {
      this.popupService.getProductInfo({ gtin: this.gtin, sku: this.sku })
        .pipe(
          catchError(error => {
            this.completeLoading();

            return throwError(error);
          })
        )
        .subscribe(data => this.setData(data));

      return;
    }

    this.completeLoading();
  }

  completeLoading() {
    this.isLoading$.next(false);
  }

  startLoading() {
    this.isLoading$.next(true);
  }

  private setData(data: ProductData) {
    const name = this.getProductName(data.names);
    this.productData$.next({ ...data, name });
    const imgUrl = data.media.find(item => item.type === ProductDataMediaType.IMAGE)?.url;

    if (imgUrl) {
      this.setImgUrl(imgUrl);
    } else {
      this.setDefaultImage();
    }
  }

  private getProductName(names: ProductDataName[]) {
    return this.getNameByLocale(names, 'en')?.value
      ?? this.getNameByLocale(names, 'de')?.value
      ?? names[0]?.value
      ?? '';
  }

  private getNameByLocale(names: ProductDataName[], locale: string) {
    return names.find(el => el.locale.toLowerCase().includes(locale));
  }

  private setDefaultImage() {
    this.imageUrl$.next(DEFAULT_PRODUCT_IMG_URL);
  }

  private setImgUrl(imgUrl: string) {
    const image = new Image();
    image.src = imgUrl;
    image.onload = () => {
      this.imageUrl$.next(imgUrl);
    };
    image.onerror = () => {
      this.setDefaultImage();
    };
  }
}
