<div class="fnc-status-transition m-row m-col-xs12of12">
  <div *ngIf="oldStatusTemplate" class="fnc-view-page-row" [ngClass]="isTransition ? 'm-col-xs5of12' : 'm-col-xs12of12'">
    <ng-container *ngTemplateOutlet="oldStatusTemplate"></ng-container>
  </div>
  <div *ngIf="oldStatus && !oldStatusTemplate" class="fnc-view-page-row" [ngClass]="isTransition ? 'm-col-xs5of12' : 'm-col-xs12of12'">
    <ng-container [ngTemplateOutlet]="statusChip" [ngTemplateOutletContext]="{ status: oldStatus }"></ng-container>
  </div>
  <div *ngIf="isTransition" class="fnc-view-page-row m-col-xs2of12">
    <mm-icon [size]="0.8" kind="arrow-right"></mm-icon>
  </div>
  <div *ngIf="newStatusTemplate" class="fnc-view-page-row" [ngClass]="isTransition ? 'm-col-xs5of12' : 'm-col-xs12of12'">
    <ng-container *ngTemplateOutlet="newStatusTemplate"></ng-container>
  </div>
  <div *ngIf="newStatus && !newStatusTemplate" class="fnc-view-page-row" [ngClass]="isTransition ? 'm-col-xs5of12' : 'm-col-xs12of12'">
    <ng-container [ngTemplateOutlet]="statusChip" [ngTemplateOutletContext]="{ status: newStatus }"></ng-container>
  </div>
</div>

<ng-template #statusChip let-status="status">
  <span [color]="status.color" [textColor]="status.textColor" mmTag>
    {{ status.text | translate }}
  </span>
</ng-template>
