import { Injectable } from '@angular/core';
import { ApiService } from '@mm-ui/core';
import { ApiOptions } from '@mm-ui/core/lib/services/api/api-options.interface';
import { catchError } from 'rxjs/operators';
import { URLDescriptor } from '@mm-ui/core/lib/services/api/url-descriptor.interface';
import { HttpErrorExtResponse } from '@mm-ui/core/lib/services/api/http-error-ext-response.interface';
import { environment } from '@fnc-env/environment';
import { HttpResponseHandlerService } from '@fnc-shared/services/http-response-handler.service';

@Injectable({
  providedIn: 'root'
})
export class PlcModalService {
  plcStatus = {
    url: `${environment.demandPlanningApiPath}v1/products/lifecycle`
  } as URLDescriptor;
  private readonly options: ApiOptions = { ignoreBasePath: true };

  constructor(
    private readonly apiService: ApiService,
    private readonly httpResponseHandlerService: HttpResponseHandlerService
  ) {}

  list(sku: string) {
    return this.apiService
      .get(`${this.plcStatus.url}`, undefined, { sku }, this.options)
      .pipe(catchError((error: HttpErrorExtResponse) => this.httpResponseHandlerService.catchError(error)));
  }
}
