import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { SettingsService } from '@fnc-core/services/settings/settings.service';
import { environment } from '@fnc-env/environment';
import { url } from '@fnc-modules/account-reports/components/account-reports-export/account-reports-export.url';
import { ReportStatus } from '@fnc-shared/constants/report-status.constant';
import { ExportRecord } from '@fnc-shared/models/export-record';

@Component({
  selector: 'fnc-reports-export-link',
  templateUrl: './reports-export-link.component.html',
  styleUrls: ['./reports-export-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsExportLinkComponent {
  @Input() exportRecord: ExportRecord;
  reportStatus = ReportStatus;

  constructor(
    public settingsService: SettingsService,
    private readonly elementRef: ElementRef
  ) {}

  onDownloadReport(record: ExportRecord) {
    const link: HTMLLinkElement = this.elementRef.nativeElement.getElementsByClassName('fnc-reports-export-link__anchor')[0];
    link.href = `${environment.baseApiPath}${url.reportsInfo.url}/${record.id}/file/${record.files?.[0].id}`;
    link.click();
  }
}
