import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationChannel, NotificationChannelName } from '@fnc-core/services/notification-events/notification-events.constant';
import { Path } from '@fnc-shared/constants/path.constant';
import { Notification } from '@mm-ui/core';

@Injectable()
export class NotificationsListService {
  constructor(private readonly router: Router) {}

  getNavigationRoute(notification: Notification<NotificationChannel>) {
    let navigationUrl = '';
    if (notification.channel === NotificationChannelName.FINANCE_REPORTS) {
      navigationUrl = this.router.createUrlTree(
        ['/', Path.reports, Path.financeReports],
        {
          queryParams: {
            '_filters.TYPE': notification.parameters.row.type
          }
        }
      ).toString();
    }

    return navigationUrl;
  }
}
