import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaymentTermCondition, Vendor, VendorAddressBlock, VendorsService } from '@fnc-core/services/vendors/state';

@Component({
  selector: 'fnc-vendor-properties',
  templateUrl: './vendor-properties.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VendorPropertiesComponent implements OnInit {
  @Input() title: string;
  @Input() vendorData: Vendor;
  addressBlock: VendorAddressBlock;
  currentPaymentTermsCondition: PaymentTermCondition;
  showVendorManagers = false;

  @Input('vendorData') get vendor() {
    return this.vendorData;
  }

  set vendor(vendorData: Vendor) {
    this.vendorData = vendorData;
    this.vendorInfo(vendorData);
  }

  get currentPaymentTermsConditionLabel() {
    return this.currentPaymentTermsCondition
      ? `${this.currentPaymentTermsCondition.value} ${this.translateService.instant('COMMON.LABELS.DAYS') as string}`
      : (this.translateService.instant('COMMON.NOT_FOUND') as string);
  }

  constructor(
    private readonly vendorService: VendorsService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.currentPaymentTermsCondition = this.vendorService.getActivePaymentTermCondition(this.vendor);
    this.showVendorManagers = !!this.vendor?.vendorManager || !!this.vendor?.vendorIntegrationManager;
  }

  private vendorInfo(vendor: Vendor) {
    this.addressBlock = vendor ? (this.vendorService.getVendorAddressInfo(vendor.addresses) as VendorAddressBlock) : null;
  }
}
