import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MmModalService } from '@mm-ui/components';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'fnc-modal-info-window',
  templateUrl: './modal-info-window.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ModalInfoWindowComponent {
  @ViewChild('infoModal', { static: true }) template: TemplateRef<ImplicitAny>;
  @Input() title = '';
  @Input() footerTpl: TemplateRef<ImplicitAny>;
  @Output() success = new EventEmitter();
  modalRef: BsModalRef;

  constructor(private readonly modalService: MmModalService) {}

  open(options?: ModalOptions) {
    this.modalRef = this.modalService.show(this.template, options);
  }

  hide() {
    this.modalRef.hide();
    this.success.emit();
  }
}
