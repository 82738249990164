import { URLDescriptor } from '@mm-ui/core/lib/services/api/url-descriptor.interface';

export const url: RecordOf<URLDescriptor> = {
  notifications: {
    url: 'v1/notifications'
  },
  channels: {
    url: 'v1/notifications/channels'
  },
  setImportance: {
    url: 'v1/notifications/:id/importance'
  },
  setState: {
    url: 'v1/notifications/:id/state'
  }
};
