import { Component } from '@angular/core';
import { ActivationStart, ChildActivationEnd, ChildActivationStart, Event, NavigationEnd, Router } from '@angular/router';
import { QueryParamsService } from '@mm-ui/core';
import { filter, map } from 'rxjs/operators';
import { GoogleAnalyticsService } from './modules/core/services/google-analytics/google-analytics.service';
import { TitleService } from '@fnc-shared/services/title.service';
import { AppService } from '@fnc-core/services/app/app.service';
import { AnalyticsSettingsService } from '@fnc-core/services/google-analytics/analytics-settings/analytics-settings.service';

@Component({
  selector: 'fnc-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
    router: Router,
    appService: AppService,
    queryParamsService: QueryParamsService,
    titleService: TitleService,
    googleAnalyticsService: GoogleAnalyticsService,
    analiticsSettingsService: AnalyticsSettingsService
  ) {
    googleAnalyticsService.startTracking();
    analiticsSettingsService.loadGtmByDefault();

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        queryParamsService.paramsInterceptor(event, appService.getQueryMapParams());
      }

      appService.routerInterceptor(event);
    });

    router.events
      .pipe(
        filter(event => event instanceof ChildActivationStart || event instanceof ActivationStart),
        map((event: ChildActivationEnd) => event.snapshot?.data.title),
        filter(Boolean)
      )
      .subscribe((title: string) => {
        titleService.setTitle(title);
      });
  }
}
