import { Pipe, PipeTransform } from '@angular/core';
import { ShortNumberSymbols } from '@fnc-shared/constants/symbols';

@Pipe({
  name: 'shortNumber'
})

export class ShortNumberPipe implements PipeTransform {
  readonly millionsExponent = 6;
  readonly thousandsExponent = 3;
  readonly base = 10;

  readonly powers = [
    { postfix: ShortNumberSymbols.MILLIONS, value: Math.pow(this.base, this.millionsExponent) },
    { postfix: ShortNumberSymbols.THOUSANDS, value: Math.pow(this.base, this.thousandsExponent) }
  ];

  transform(nr: number, startValue: number) {
    if (isNaN(nr)) {
      return null;
    }
    if (nr === null) {
      return null;
    }

    if (nr > startValue) {
      return this.calculateShortNumber(nr);
    } else {
      return nr;
    }
  }

  calculateShortNumber(nr: number) {
    const sign = nr < 0 ? '-' : '+';
    let abs = Math.abs(nr);
    let key = '';

    for (const item of this.powers) {
      let reduced = abs / item.value;

      reduced = Math.round(reduced);

      if (reduced >= 1) {
        abs = reduced;
        key = item.postfix;
        break;
      }
    }

    return `${sign}${abs}${key}`;
  }
}
