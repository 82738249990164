import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MmChartsModule } from '@mm-ui/charts';
import { MmAddDashPipe, MmBoxedLabelModule, MmFormatNumberPipe, MmIconModule, MmLoaderModule, MmPipesModule } from '@mm-ui/components';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { WINDOW_PROVIDERS } from './services/window/window.provider';
import { SalesForecastingGraphComponent } from '@fnc-shared-supply-chain/components/sales-forecasting-graph/sales-forecasting-graph.component';
import { AsWeeksPipe } from '@fnc-shared/pipes/as-weeks.pipe';
import { FormatNumberPipe } from '@fnc-shared/pipes/format-number.pipe';
import { MonthYearDatePipe } from '@fnc-shared/pipes/month-year-date.pipe';
import { ShortNumberPipe } from '@fnc-shared/pipes/short-number.pipe';
import { CustomValidatorsService } from '@fnc-shared/services/custom-validators.service';
import { PageLimitStateService } from '@fnc-shared/services/page-limit-state/page-limit-state.service';
import { VendorInvoiceHelpersService } from '@fnc-shared/services/vendor-invoice-helpers/vendor-invoice-helpers.service';
import { externalExport } from '@fnc-shared/shared-external-exports';
import { internalExport } from '@fnc-shared/shared-internal-exports';
import { ProductPopupComponent } from '@fnc-shared-fnc/components/product-pop-up/product-popup.component';

export const sharedExports = [
  ...externalExport,
  ...internalExport,
  FormsModule,
  MmIconModule,
  ReactiveFormsModule,
  TranslateModule,
  TooltipModule,
  PopoverModule,
  MmBoxedLabelModule
];

@NgModule({
  imports: [
    ...externalExport,
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      useDefaultLang: false
    }),
    MmLoaderModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    MmChartsModule,
    MmPipesModule,
    MmIconModule
  ],
  declarations: [...internalExport, SalesForecastingGraphComponent, ProductPopupComponent],
  providers: [
    MmFormatNumberPipe,
    CustomValidatorsService,
    FormatNumberPipe,
    MmAddDashPipe,
    MonthYearDatePipe,
    ShortNumberPipe,
    AsWeeksPipe,
    WINDOW_PROVIDERS,
    PageLimitStateService,
    VendorInvoiceHelpersService
  ],
  exports: [sharedExports, SalesForecastingGraphComponent, ProductPopupComponent]
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class SharedModule {}
