import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { AccountingSideMenuItems } from '@fnc-core/components/accounting-page/accounting-side-menu-items.constant';
import { UiLoaderQuery } from '@fnc-core/services/ui/state/ui-loader.query';
import { Path } from '@fnc-shared/constants/path.constant';
import { SideMenuItemProtected } from '@fnc-shared/interfaces/side-menu.interface';
import { ScrollService } from '@fnc-shared/services/scroll.service';
import { MmLoaderService } from '@mm-ui/components';
import { PermissionsService } from '@mm-ui/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './accounting-page.component.html'
})
export class AccountingPageComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('pageSideContent', { read: ElementRef, static: true }) pageSideContent: ElementRef;
  @ViewChild('pageScrollContainer', { static: true }) pageContainer: ElementRef;
  path = Path;
  sideMenu = AccountingSideMenuItems;
  items: SideMenuItemProtected[] = [];
  twoColContentGrid = false;
  hasInnerPanel = false;
  isHorizontalHeader = true;

  private mainPageSubscription: Subscription;

  constructor(
    private readonly uiLoaderQuery: UiLoaderQuery,
    private readonly mmLoaderService: MmLoaderService,
    private readonly route: ActivatedRoute,
    private readonly scrollService: ScrollService,
    private readonly permissionsService: PermissionsService
  ) {
    route.url.subscribe(() => {
      const snapshot = this.getRelevantRouteSnapshot(route.snapshot);
      this.twoColContentGrid = snapshot.data.twoColContentGrid ?? false;
      this.hasInnerPanel = snapshot.data.hasInnerPanel ?? false;
    });
    this.isHorizontalHeader = this.permissionsService.isPermitted('FF_FP_HORIZONTAL_MENU');
  }

  ngOnInit() {
    this.scrollService.addScrollContainer(this.pageContainer);
    this.buildMenu();
  }

  ngAfterViewChecked() {
    !this.mainPageSubscription && this.subscribeOnMainPageLoading();
  }

  ngOnDestroy() {
    this.scrollService.removeScrollContainer(this.pageContainer);
    this.mmLoaderService.stopForce('pageSideContent');
    this.mainPageSubscription.unsubscribe();
  }

  buildMenu() {
    this.items = this.sideMenu
      .map(group => ({
        title: group.title,
        items: group.items
          .map(subGroup => ({
            ...subGroup,
            items: subGroup.items.filter(item => this.permissionsService.isPermitted(item.rights))
          }))
          .filter(subGroup => subGroup.items.length)
      }))
      .filter(group => group.items.length);
  }

  private subscribeOnMainPageLoading() {
    this.mainPageSubscription = this.uiLoaderQuery.mainPage$
      .pipe(
        untilDestroyed(this),
        tap(value => {
          if (this.pageSideContent && value) {
            this.mmLoaderService.start('pageSideContent', this.pageSideContent);
          } else {
            this.mmLoaderService.stopForce('pageSideContent');
          }
        })
      )
      .subscribe();
  }

  private getRelevantRouteSnapshot(snapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (snapshot.firstChild) {
      return this.getRelevantRouteSnapshot(snapshot.firstChild);
    }

    return snapshot;
  }
}
