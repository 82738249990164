import { Component } from '@angular/core';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';

@Component({
  template: ` <ng-template [fncTemplateRequestor]="component" let-row="row" let-value="value">
    <input
      mmInput
      type="text"
      class="m-input-element"
      [id]="property + '__' + row.id"
      [ngClass]="hasError ? 'm-input-hasError' : null"
      [disabled]="isDisabled"
      [placeholder]="placeholder | translate"
      [(ngModel)]="row[property]"
      (ngModelChange)="valueChanged(row.id)"
    />
  </ng-template>`,
  styleUrls: ['./input-cell.component.scss']
})
export class InputCellComponent extends CellComponent {
  placeholder = '';
  value: string | undefined;
  isDisabled = false;
  hasError = false;
  property = '';

  valueChanged = (_id: WeakNumber): void => void {};
}
