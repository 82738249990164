import { URLDescriptor } from '@mm-ui/core/lib/services/api/url-descriptor.interface';

export const URLS: RecordOf<URLDescriptor> = {
  productGtin: {
    url: 'v1/products/gtin/:gtin'
  },
  productSku: {
    url: 'v1/products/sku/:sku'
  }
};
