<div *ngIf="isLinkStyle()">
  <button
    *ngIf="actions.length"
    [mmContent]="actionsTemplate"
    [mmPlacement]="placement"
    class="m-button-dot-three-table"
    icon="dot-three-vertical"
    mmActionTableMenu
    mmButton
    mmOverlayClassName="test-placement-menu"
  ></button>
</div>

<ng-template #actionsTemplate>
  <ng-container *ngFor="let actionGroup of kebabButtons; index as i">
    <ng-container *ngFor="let action of actionGroup">
      <mm-action-table-menu-item
        (click)="cmdList[action.cmd](action.params, action.title, $event)"
        [ngClass]="{ 'mm-action-table-menu-item-disabled': action.disabled }"
        value="action"
      >
        <span>{{ action.label | translate }}</span>
      </mm-action-table-menu-item>
    </ng-container>
    <hr *ngIf="isSeparatorVisible(kebabButtons, i)" />
  </ng-container>
</ng-template>

<div *ngIf="isButtonStyle()">
  <ng-container *ngFor="let action of buttons">
    <button
      (click)="cmdList[action.cmd](action.params, action.title, $event)"
      [disabled]="action.disabled"
      [iconOrder]="action.buttonIconOrder"
      [icon]="action.buttonIcon"
      [kind]="action.buttonType"
      [ngClass]="['fnc-action-menu-button', action.class || '']"
      label="{{ action.buttonLabel | translate }}"
      mmButton
      size="small"
    ></button>
  </ng-container>
  <button
    *ngIf="isKebabButtonVisible()"
    [mmContent]="actionsTemplate"
    [mmPlacement]="placement"
    class="m-button-secondary m-button-dot-three fnc-action-menu-button"
    icon="dot-three-vertical"
    mmActionTableMenu
    mmButton
  ></button>
</div>
