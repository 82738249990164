import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { InstallationServiceRoute } from './routes/installation-services.route';
import { AdminPortalRoute } from '@fnc-app/routes/admin-portal.route';
import { FinancePortalRoute } from '@fnc-app/routes/finance-portal.route';
import { ForbiddenRoute } from '@fnc-app/routes/forbidden.route';
import { FulfilmentRoute } from '@fnc-app/routes/fulfilment.route';
import { HomeRoute } from '@fnc-app/routes/home.route';
import { ReportsRoute } from '@fnc-app/routes/reports.route';
import { SettingsRoute } from '@fnc-app/routes/settings.route';
import { SignInRoute } from '@fnc-app/routes/signin.route';
import { SupplyChainRoute } from '@fnc-app/routes/supply-chain.route';
import { Path } from '@fnc-shared/constants/path.constant';
import { OfferManagementRoute } from '@fnc-app/routes/offer-management.route';
import { ProductDataRoute } from '@fnc-app/routes/product-data.route';

const routes: Route[] = [
  HomeRoute,
  FinancePortalRoute,
  FulfilmentRoute,
  OfferManagementRoute,
  ReportsRoute,
  SettingsRoute,
  SupplyChainRoute,
  InstallationServiceRoute,
  ForbiddenRoute,
  SignInRoute,
  AdminPortalRoute,
  ProductDataRoute,
  { path: '**', redirectTo: Path.home }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
