export const BLACK_RIGHT_POINTING_TRIANGLE = '\u25B6';
export const DASH_SYMBOL = '\u2014';
export const PERCENT_SYMBOL = '\u0025';
export const INFINITY_SYMBOL = '\u221E';
export const TAB_SYMBOL = '\u0009';
export const LONG_DASH_SYMBOL = '\u2014';

export enum ShortNumberSymbols {
  THOUSANDS = 'K',
  MILLIONS = 'M'
}
