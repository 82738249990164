import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { SideMenuProtected } from '@fnc-shared/interfaces/side-menu.interface';
import { Path } from '@fnc-shared/constants/path.constant';

export const SettingsSideMenuItems: SideMenuProtected[] = [
  {
    title: _('NAVIGATION.GROUP.ACCOUNTING_SETTINGS'),
    items: [
      {
        items: [
          {
            routerLink: ['/', Path.settings, Path.accountingPeriods],
            title: _('NAVIGATION.SETTINGS.ACCOUNTING_PERIODS'),
            rights: ['ROLE_CAN_MANAGE_ACCOUNTING_PERIOD']
          },
          {
            routerLink: ['/', Path.settings, Path.vendorsControlPanel],
            title: _('NAVIGATION.SETTINGS.VENDORS_CONTROL_PANEL'),
            rights: { $or: ['ROLE_CAN_VIEW_VENDOR_INVOICES', 'ROLE_CAN_MANAGE_VENDOR_SETTINGS'] }
          }
        ]
      }
    ]
  }
];
