import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OfferSideMenuItems } from '@fnc-core/components/offer-page/offer-side-menu-items.constant';
import { AccountingPageComponent } from '../accounting-page/accounting-page.component';

@UntilDestroy()
@Component({
  templateUrl: './offer-page.component.html'
})
export class OfferPageComponent extends AccountingPageComponent {
  sideMenu = OfferSideMenuItems;
}
