import { ComponentFactoryResolver, Injectable, Injector, Type } from '@angular/core';
import { Cell } from '@fnc-shared/components/cells/cell';

@Injectable({
  providedIn: 'root'
})
export class TemplateRegistryService {
  constructor(private readonly componentFactoryResolver: ComponentFactoryResolver, private readonly injector: Injector) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getTemplate<T extends Cell>(component: Type<T>, subactions = (_templateInstance: InstanceType<Type<T>>) => {}) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    const createdComponent = factory.create(this.injector);
    const instance: InstanceType<Type<T>> = createdComponent.instance;
    createdComponent.changeDetectorRef.detectChanges();
    subactions(instance);

    return instance.templateRef;
  }
}
