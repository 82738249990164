import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';

@Component({
  template: ` <ng-template [fncTemplateRequestor]="component" let-row="row" let-value="value">
    <div [id]="property + '__' + row.id" [class]="{ 'fnc-toggle-cell--disabled': isDisabled || row.isProcessing }">
      <mm-toggle-button
        (change)="changeSettings($event, row.id, value)"
        *ngIf="value !== ''"
        [active]="value"
        [falseLabel]="falseLabel | translate"
        [trueLabel]="trueLabel | translate"
        [class]="{ 'fnc-toggle-cell': !isLongCell, 'fnc-toggle-long-cell': isLongCell }"
      ></mm-toggle-button>
    </div>
  </ng-template>`,
  styleUrls: ['./toggle-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleCellComponent extends CellComponent {
  isDisabled = false;
  isLongCell = false;
  falseLabel = '';
  trueLabel = '';
  property = '';

  changeSettings = (_newValue: boolean, _id: number, _value: boolean): void => void {};
}
