import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';
import { TOOLTIP_DELAY } from '@fnc-shared/constants/delay.constant';

@Component({
  template: `<ng-template [fncTemplateRequestor]="component" let-value="value">
      <fnc-secondary-txt *ngIf="value.length > 1">
        {{ 'COMMON.AUDIT_LOG.MIXED_LABEL' | translate }}
      </fnc-secondary-txt>
      <ng-container *ngIf="value.length === 0">
        {{ '' | mmAddDash }}
      </ng-container>
      <ng-container *ngIf="value.length === 1">
        {{ value[0] | translate: translationParams }}
      </ng-container>
  </ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeysCellComponent extends CellComponent {
  tooltipDelay = TOOLTIP_DELAY;
  translationParams: RecordString;
}
