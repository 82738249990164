import { Component } from '@angular/core';
import { AccountingPageComponent } from '@fnc-core/components/accounting-page/accounting-page.component';
import { SupplyChainSideMenuItems } from '@fnc-core/components/supply-chain-page/supply-chain-side-menu-items.constant';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'fnc-supply-chain-page',
  templateUrl: './supply-chain-page.component.html'
})
export class SupplyChainPageComponent extends AccountingPageComponent {
  sideMenu = SupplyChainSideMenuItems;
}
