export interface ProductData {
  id: number;
  sku: string;
  brand: ProductDataBrand;
  externalReferences: ProductDataExternalRef;
  manufacturer: string;
  media: ProductDataMedia[];
  mpn: string;
  names: ProductDataName[];
  packages: ProductDataPackage[];
  commodityCode: string;
  weightNetGrams: number;
  countryOfMnufacture: string;
  name: string;
}

export interface ProductDataExternalRef {
  pimMid: string;
  pimUuid: string;
}

export interface ProductDataBrand {
  name: string;
  ownBrand: boolean;
}

export interface ProductDataPackage {
  gtin: string;
  type: string;
  additionalGtins: string[];
}

export interface ProductDataName {
  locale: string;
  value: string;
}

export interface ProductDataMedia {
  url: string;
  type: ProductDataMediaType;
}

export enum ProductDataMediaType {
  IMAGE = 'image'
}
