import { Route } from '@angular/router';
import { TranslationSettingsModules } from '@fnc-app/routes/translation-modules';
import { DashboardSettingsComponent } from '@fnc-core/components/dashboard-settings/dashboard-settings.component';
import { SettingsPageComponent } from '@fnc-core/components/settings-page/settings-page.component';
import { InitGuard } from '@fnc-core/guards/init.guard';
import { Path } from '@fnc-shared/constants/path.constant';
import { PermissionsGuard } from '@mm-ui/core';

export const SettingsRoute: Route = {
  path: Path.settings,
  canActivate: [InitGuard],
  component: SettingsPageComponent,
  data: {
    breadcrumb: 'NAVIGATION.MAIN.SETTINGS',
    i18n: TranslationSettingsModules
  },
  children: [
    {
      path: '',
      canActivate: [PermissionsGuard],
      component: DashboardSettingsComponent,
      data: {
        permissions: {
          rule: {
            $or: [
              'ROLE_CAN_MANAGE_ACCOUNTING_PERIOD',
              'ROLE_CAN_VIEW_VENDOR_INVOICES'
            ]
          }
        },
        navigateTo: ['/', Path.forbidden]
      }
    },
    {
      path: Path.accountingPeriods,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/accounting-periods/accounting-periods.module').then(m => m.AccountingPeriodsModule),
      data: {
        breadcrumb: 'NAVIGATION.SETTINGS.ACCOUNTING_PERIODS',
        title: 'NAVIGATION.SETTINGS.ACCOUNTING_PERIODS',
        permissions: {
          rule: ['ROLE_CAN_MANAGE_ACCOUNTING_PERIOD'],
          navigateTo: ['/', Path.forbidden]
        }
      }
    },
    {
      path: Path.vendorsControlPanel,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/vendors-control-panel/vendors-control-panel.module').then(m => m.VendorsControlPanelModule),
      data: {
        breadcrumb: 'NAVIGATION.SETTINGS.VENDORS_CONTROL_PANEL',
        title: 'NAVIGATION.SETTINGS.VENDORS_CONTROL_PANEL',
        permissions: {
          rule: {
            $or: [
              'ROLE_CAN_VIEW_VENDOR_INVOICES',
              'ROLE_CAN_MANAGE_VENDOR_SETTINGS'
            ]
          },
          navigateTo: ['/', Path.forbidden]
        }
      }
    }
  ]
};
