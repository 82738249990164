export const GA360Country = 'DE';

export enum GAEventType {
  PAGEVIEW = 'pageview',
  STANDARD_EVENT = 'standard_event',
  INTERACTION = 'interaction',
  NAVIGATION = 'navigation'
}

export enum GAEventName {
  PAGE_VIEW = 'page_view',
  INTERACTION = 'interaction',
  NAVIGATION = 'navigation'
}

export enum GAActionType {
  ADD_COMMENT = 'add comment',
  ADD_FILTER = 'add filter',
  ADD_ITEM = 'add item',
  ADD_SERVICE = 'add service',
  ADD_SERVICE_PARTNER = 'add service partner',
  ASSIGN_LABELS = 'assign labels',
  ASSIGN_LABELS_PER_PRODUCT = 'assign labels per product',
  BLOCK_PRODUCTS = 'block products',
  BULK_TOGGLE_ACTIVE_SALE_UNIT = 'bulk toggle active untis',
  BULK_RECALCULATE_SALE_UNIT = 'bulk recalculate units',
  CANCEL_ITEMS = 'cancel items',
  CLONE_DRAFT = 'clone draft',
  CLONE_TO_DRAFT = 'clone to draft',
  CREATE_ORDER_DRAFT = 'create order draft',
  DELETE_DRAFT = 'delete draft',
  DELETE_ITEM = 'delete item',
  DESELECT_INDIVIDUAL_ITEM = 'deselect individual item',
  DOWNLOAD_FORECAST_TEMPLATE = 'download forecast template',
  DOWNLOAD_ORDER = 'download order',
  DOWNLOAD_PREORDER = 'download pre-order',
  DRAFT_PLC_COUNTRY_SWITCH = 'Draft_PLC_Country_Switch',
  DRAFT_PLC_OPEN = 'Draft_PLC_open',
  EDIT_SERVICE = 'edit service',
  EDIT_SERVICE_PARTNER = 'edit service partner',
  EXPAND_CATEGORIES = 'expand categories',
  EXPORT_SALE_UNITS_PBI = 'export sale units pbi',
  EXPAND_SALES_CHART_PANEL = 'expand sales chart panel',
  EXPAN_SALE_UNIT_DETAIL = 'expand unit detail',
  EXPAND_WAREHOUSE = 'expand warehouse',
  GENERAL_TAB = 'general',
  HOVER_ETA = 'hover eta',
  HOVER_STOCK_INFORMATION = 'hover stock information',
  HOVER_TOTAL_PALLETS = 'hover total pallets',
  ITEMS_PER_PAGE = 'items per page',
  LOAD_ALL = 'load all',
  LOAD_MORE = 'load 10 more',
  MANUAL_FORECAST_OVERRIDE = 'manual forecast override',
  METRICS_EXPANDED = 'metrics expanded',
  MODIFY_ETA = 'modify eta',
  MODIFY_QTY = 'modify qty',
  MODIFY_STATUS = 'modify status',
  MOVE = 'move',
  OPEN_PRODUCT_DETAILS = 'open product details',
  PROMOTION_TAB = 'promotion',
  PROPOSED_QUANTITY_BREAKDOWN = 'proposed quantity breakdown',
  REMOVE_PARTNER = 'remove partner',
  REMOVE_SERVICE = 'remove service',
  REPLENISHMENT_PLC_COUNTRY_SWITCH = 'Replenishment_PLC_Country_Switch',
  REPLENISHMENT_PLC_OPEN = 'Replenishment_PLC_open',
  SELECT_INDIVIDUAL_ITEM = 'select individual item',
  SELECT_INVALID_ITEMS = 'select invalid items',
  SELECT_INVENTORY = 'select inventory',
  SELECT_MCOM_ACTION = 'select mcom',
  SELECT_PAGES = 'select pages',
  SELECT_VALID_ITEMS = 'select valid items',
  SHOW_HISTORY = 'show history',
  SHOW_INFORMATION = 'show information',
  SORT_ASCENDING = 'sort ascending',
  SORT_DESCENDING = 'sort descending',
  SUBMIT_DRAFT = 'submit order draft',
  SWITCH_WAREHOUSE = 'switch warehouse',
  RECALCULATE_SALE_UNIT = 'recalculate unit',
  PRICE_BREAKDOWN = 'price breakdown',
  PRICE_BREAKDOWN_MENU = 'price breakdown menu',
  TOGGLE_ACTIVE_SALE_UNIT = 'toggle unit active',
  UNBLOCK_PRODUCTS = 'unblock products',
  UPLOAD_FORECAST = 'upload forecast',
  UPLOAD_ITEMS_TO_ORDER_DRAFT = 'upload items to order draft',
  UPLOAD_SEARCH = 'upload search',
  UPLOAD_SERVICE_LEVEL = 'upload sl'
}

export enum GAPageTitle {
  INVENTORY_MANAGEMENT_MCOM = 'inventory management|MCOM',
  INVENTORY_MANAGEMENT_SALE_UNITS = 'inventory management|sale units',
  INSTALLATION_SERVICES_PARTNERS = 'installation services|partners',
  INSTALLATION_SERVICES_SERVICES = 'installation services|services',
  SUPPLY_CHAIN_DEMAND_PLANNING = 'supply chain|demand planning',
  SUPPLY_CHAIN_REPLENISHMENT = 'supply chain|replenishment',
  SUPPLY_CHAIN_ORDER_DRAFTS = 'supply chain|order drafts',
  SUPPLY_CHAIN_ORDER_DRAFTS_CREATE_ORDER_DRAFTS = 'supply chain|order drafts|create order drafts',
  SUPPLY_CHAIN_ORDER_DRAFTS_DRAFT_NUMBER = 'supply chain|order drafts|draft',
  SUPPLY_CHAIN_SUPPLY_ORDERS = 'supply chain|supply orders',
  SUPPLY_CHAIN_SUPPLY_ORDERS_ORDER_NUMBER = 'supply chain|supply orders|order',
  SUPPLY_CHAIN_SALES_FORECASTING = 'supply chain|sales forecasting',
  UNDEFINED = 'undefined'
}

export enum GACategory {
  AVAILABILITY = 'availability',
  COMMENT = 'comment',
  CREATED = 'created',
  ETA = 'eta',
  INCOMING_STOCKS = 'incoming stocks',
  INSTALLATION_SERVICES = 'installation services',
  MCOM_UPDATE = 'mcom update',
  PLANNED_QTY = 'planned qty',
  PRODUCT_INFORMATION = 'product information',
  PRODUCT_LIFE_CYCLE = 'product life cycle',
  STOCK_INFORMATION = 'stock information',
  UNDEFINED = 'undefined'
}

export enum GALabel {
  COLLAPSED = 'collapsed',
  ERROR = 'with error',
  EXPANDED = 'expanded',
  SUCCESS = 'succeeded',
  UNDEFINED = 'undefined'
}
