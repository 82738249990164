import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionsService } from '@mm-ui/core';
import { DefaultNavigateComponent } from '@fnc-core/components/default-navigate/default-navigate.component';
import { ProductDataSideMenuItems } from '@fnc-core/components/product-data-page/product-data-side-menu-items.constant';

@Component({
  template: '<div></div>'
})
export class DashboardProductDataComponent extends DefaultNavigateComponent {
  protected sideMenuItems = ProductDataSideMenuItems;

  constructor(router: Router, permissions: PermissionsService) {
    super(router, permissions);
  }
}
