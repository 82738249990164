import { Route } from '@angular/router';
import { TranslationReportsModules } from '@fnc-app/routes/translation-modules';
import { DashboardReportsComponent } from '@fnc-core/components/dashboard-reports/dashboard-reports.component';
import { ReportsPageComponent } from '@fnc-core/components/reports-page/reports-page.component';
import { InitGuard } from '@fnc-core/guards/init.guard';
import { Dictionary } from '@fnc-shared/constants/dictionary.constant';
import { Path } from '@fnc-shared/constants/path.constant';
import { DictionaryResolverService, PermissionsGuard } from '@mm-ui/core';

export const ReportsRoute: Route = {
  path: Path.reports,
  canActivate: [InitGuard],
  component: ReportsPageComponent,
  data: {
    breadcrumb: 'NAVIGATION.MAIN.REPORTS',
    i18n: TranslationReportsModules
  },
  children: [
    {
      path: '',
      canActivate: [PermissionsGuard],
      component: DashboardReportsComponent,
      data: {
        title: 'NAVIGATION.MAIN.REPORTS',
        permissions: {
          rule: { $or: ['ROLE_CAN_MANAGE_FINANCE_REPORTS'] },
          navigateTo: ['/', Path.forbidden]
        }
      }
    },
    {
      path: Path.financeReports,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/reports-finance/reports-finance.module').then(m => m.ReportsFinanceModule),
      data: {
        breadcrumb: 'NAVIGATION.REPORTS.FINANCE',
        title: 'NAVIGATION.REPORTS.FINANCE',
        permissions: {
          rule: ['ROLE_CAN_MANAGE_FINANCE_REPORTS'],
          navigateTo: ['/', Path.forbidden]
        },
        twoColContentGrid: true,
        hasInnerPanel: true
      }
    },
    {
      path: Path.intrastatReports,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/reports-intrastat/reports-intrastat.module').then(m => m.ReportsIntrastatModule),
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.REPORTS.INTRASTAT',
        title: 'NAVIGATION.REPORTS.INTRASTAT',
        permissions: {
          rule: ['ROLE_CAN_MANAGE_INTRASTAT'],
          navigateTo: ['/', Path.forbidden]
        },
        hasInnerPanel: true,
        dictionaries: [
          Dictionary.COUNTRIES, Dictionary.INTRASTAT_STATUSES, Dictionary.INTRASTAT_PRODUCT_STATUSES,
          Dictionary.INTRASTAT_UNITS_OF_MEASUREMENT
        ]
      }
    }
  ]
};
