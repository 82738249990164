import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ShippingInformation } from '@fnc-modules/fulfilment-orders/models/orders.interface';
import { AddressPropertiesService } from '@fnc-shared/components/entity-properties/address-properties/address-properties.service';
import {
  AddressBlock,
  AddressControlConfig,
  AddressType,
  AddressUpdateEvent
} from '@fnc-shared/components/entity-properties/address-properties/interfaces/address-properties.interface';
import { validateAllFormFields } from '@fnc-shared/helpers/forms/control-validators';
import { MmModalService } from '@mm-ui/components';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'fnc-address-properties',
  templateUrl: './address-properties.component.html',
  styleUrls: ['./address-properties.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddressPropertiesComponent implements OnChanges {
  @Input() title: string;
  @Input() type: AddressType;
  @Input() addressInfo: ShippingInformation;
  @Input() isLast = false;
  @Input() isEditableAddress = false;
  @Output() dataUpdate = new EventEmitter<AddressUpdateEvent>();
  @ViewChild('editModal') editModal: TemplateRef<ImplicitAny>;

  addressBlock: AddressBlock;
  modalRef: BsModalRef;
  form: FormGroup;
  controlsConfig: AddressControlConfig[];

  constructor(
    public addressService: AddressPropertiesService,
    private readonly modalService: MmModalService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.addressInfo) {
      this.controlsConfig = this.addressService.getControlsConfig(this.type, this.addressInfo);
      this.addressBlock = this.addressService.getFormattedAddress(this.type, this.addressInfo);
    }
  }

  onEditAddress() {
    this.form = this.formBuilder.group(this.addressService.getFormBuilderConfig(this.controlsConfig));
    this.modalRef = this.modalService.show(this.editModal, {
      animated: false,
      ignoreBackdropClick: true
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.dataUpdate.emit({
        type: this.type,
        data: this.form.value
      });
      this.modalRef.hide();
    } else {
      validateAllFormFields(this.form);
    }
  }
}
