import { Route } from '@angular/router';
import { PermissionsGuard } from '@mm-ui/core';
import { TranslationProductDataModules } from '@fnc-app/routes/translation-modules';
import { InitGuard } from '@fnc-core/guards/init.guard';
import { Path } from '@fnc-shared/constants/path.constant';
import { ProductDataPageComponent } from '@fnc-core/components/product-data-page/product-data-page.component';
import { DashboardProductDataComponent } from '@fnc-core/components/dashboard-product-data/dashboard-product-data.component';

export const ProductDataRoute: Route = {
  path: Path.productData,
  canActivate: [InitGuard],
  component: ProductDataPageComponent,
  data: {
    breadcrumb: 'NAVIGATION.MAIN.PRODUCT_DATA',
    i18n: TranslationProductDataModules
  },
  children: [
    {
      path: '',
      canActivate: [PermissionsGuard],
      component: DashboardProductDataComponent,
      data: {
        title: 'NAVIGATION.MAIN.PRODUCT_DATA',
        permissions: {
          rule: { $or: ['ROLE_ADMIN', 'ROLE_CAN_MANAGE_ERP_ATTRIBUTES'] },
          navigateTo: ['/', Path.forbidden]
        }
      }
    },
    {
      path: Path.attributes,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/attribute-management/attribute-management.module').then(m => m.AttributeManagementModule),
      data: {
        breadcrumb: 'NAVIGATION.PRODUCT_DATA.ATTRIBUTES_MANAGEMENT',
        title: 'NAVIGATION.PRODUCT_DATA.ATTRIBUTES_MANAGEMENT',
        permissions: {
          rule: { $or: ['ROLE_ADMIN', 'ROLE_CAN_MANAGE_ERP_ATTRIBUTES'] },
          navigateTo: ['/', Path.forbidden]
        }
      }
    }
  ]
};
