import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ScrollService } from '@fnc-shared/services/scroll.service';

@Component({
  selector: 'fnc-scroll-container',
  templateUrl: './scroll-container.component.html',
  styleUrls: ['./scroll-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScrollContainerComponent implements OnInit, OnDestroy {
  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;

  constructor(
    private readonly scrollService: ScrollService
  ) { }

  ngOnInit() {
    this.scrollService.addScrollContainer(this.scrollContainer);
  }

  ngOnDestroy() {
    this.scrollService.removeScrollContainer(this.scrollContainer);
  }
}
