import { Validators } from '@angular/forms';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { ShippingInformation } from '@fnc-app/modules/fulfilment-orders/models/orders.interface';
import { AddressControlConfig } from './interfaces/address-properties.interface';

export const addressControls: AddressControlConfig[] = [
  {
    controlName: 'companyName',
    label: _('EDIT_ADDRESS_MODAL.COMPANY_NAME'),
    valueGetter: (addressInfo: ShippingInformation) => addressInfo.companyName
  },
  {
    controlName: 'buyerName',
    label: _('EDIT_ADDRESS_MODAL.BUYER_NAME'),
    valueGetter: (addressInfo: ShippingInformation) => addressInfo.name
  },
  {
    controlName: 'addressLine1',
    validators: [Validators.required],
    label: _('EDIT_ADDRESS_MODAL.ADDRESS_LINE_1'),
    valueGetter: (addressInfo: ShippingInformation) => addressInfo.addressLine1
  },
  {
    controlName: 'addressLine2',
    label: _('EDIT_ADDRESS_MODAL.ADDRESS_LINE_2'),
    valueGetter: (addressInfo: ShippingInformation) => addressInfo.addressLine2
  },
  {
    controlName: 'country',
    validators: [Validators.required],
    label: _('EDIT_ADDRESS_MODAL.COUNTRY'),
    valueGetter: (addressInfo: ShippingInformation) => addressInfo.country
  },
  {
    controlName: 'city',
    validators: [Validators.required],
    label: _('EDIT_ADDRESS_MODAL.CITY'),
    valueGetter: (addressInfo: ShippingInformation) => addressInfo.city
  },
  {
    controlName: 'zip',
    validators: [Validators.required],
    label: _('EDIT_ADDRESS_MODAL.ZIP'),
    valueGetter: (addressInfo: ShippingInformation) => addressInfo.zip
  }
];
