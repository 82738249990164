import { Component } from '@angular/core';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';

@Component({
  template: `
    <ng-template [fncTemplateRequestor]="component" let-row="row" let-value="value">
      <div class="fnc-table__cell-inline-wrapper">
        <mm-star (click)="onPreventedClick($event)" (starClick)="changeImportance(row, $event)" [value]="row.isImportant"></mm-star>
        <span> {{ value }} </span>
      </div>
    </ng-template>`
})
export class ImportantCellComponent extends CellComponent {
  onPreventedClick(event: MouseEvent) {
    event.stopPropagation();
  }

  changeImportance(_row: object, _isImportant: boolean) {}
}
