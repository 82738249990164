import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MmDatepickerService } from '@mm-ui/components';
import {
  ApiModule,
  LocalizationModule,
  LocalizationService,
  NotificationClientService,
  NotificationService,
  PermissionsGuard,
  SentryHandlerService
} from '@mm-ui/core';
import { Angulartics2Module } from 'angulartics2';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '@fnc-core/core.module';
import { environment } from '@fnc-env/environment';
import { DbService } from '@fnc-shared/services/db.service';
import { DisabledFeaturesService } from '@fnc-shared/services/disabled-features/disabled-features.service';
import { SharedModule } from '@fnc-shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ApiModule.forRoot({ basePath: environment.baseApiPath }),
    LocalizationModule.forRoot({
      languageUrl: 'i18n/:locale.json',
      supportedLanguages: ['en', 'de'],
      defaultLanguage: 'en'
    }),
    BsDatepickerModule.forRoot(),
    Angulartics2Module.forRoot({
      gst: {
        trackingIds: [environment.gaTrackingId]
      }
    })
  ],
  providers: [
    LocalizationService,
    PermissionsGuard,
    MmDatepickerService,
    NotificationClientService,
    NotificationService,
    {
      provide: ErrorHandler,
      useClass: SentryHandlerService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (db: DbService) => () => db.connectToDb(),
      deps: [DbService, DisabledFeaturesService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AppModule {}
