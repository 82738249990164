import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { SwitchInputValue } from '@mm-ui/components';
import { ErrorResponse } from '@mm-ui/core/lib/services/api/error-response.interface';
import { BigNumber } from 'bignumber.js';
import { StatusReasonItem } from '@fnc-modules/clearing-office/models/matching-document';
import { VendorNoteReasonType, VendorNoteStatus, VendorNoteType } from '@fnc-modules/vendor-notes/models/notes';
import { DocumentSourceType } from '@fnc-shared-fnc/constants/document-type.constant';
import { InvoiceStatus } from '@fnc-shared-fnc/constants/invoice-status.constant';
import { MatchingConflict, MatchingConflictStatus } from '@fnc-shared-fnc/models/matching-conflicts.model';
import { DestinationCountry } from '@fnc-shared-fnc/models/order-units.model';
import { VendorInvoicePositionValidationError } from '@fnc-shared-fnc/models/vendor-invoice-position-validation-error.model';
import { UploadedFile } from '@fnc-shared/components/modal-file-manager-window/file-manager.constant';
import { ConnectedDocuments } from '@fnc-shared-fnc/components/connected-documents/connected-documents.interface';
import { ExpectedCreditNoteStatus } from '@fnc-shared-fnc/components/expected-credit-note-status/expected-credit-note-status.constant';

export const FIELD_MAX_LENGTH = 56;
export interface InvoicePayload {
  vendorId: number;
  vendorName?: string;
  number: string;
  date: string | Date;
  dueDate?: string | Date;
  dueDateManual: string | Date;
  weee: string;
  amount: number;
  amountWoVat?: number;
  amountWoVatCalc?: number;
  isImportant: boolean;
  positions: PositionItem[];
  amountsPerVat: VatItem[];
  earlyPayments: EarlyPayment[];
  summaryText?: string;
  accountingPeriodNumber: string;
  vatId?: string;
  files?: UploadedFile[];
}

export interface InvoiceView extends InvoicePayload {
  id: number;
  status: InvoiceStatus;
  sourceType: DocumentSourceType;
  lastReason: StatusReasonItem[];
  hasActiveNote: boolean;
  linkedDocuments: Partial<Note>[];
  isAvailableInFo: boolean;
  connectedEntities: ConnectedDocuments;
  comment?: string;
  paymentFile?: PaymentFile;
  metadata?: ErrorResponse;
  warningMessages?: string[];
  matchingConflictsStatus: MatchingConflictStatus;
  createdAt: Date;
  bookedAt: Date;
  expectedCreditNoteStatus: ExpectedCreditNoteStatus;
  destinationCountry?: string;
}

export interface PaymentFile {
  id: number;
  bookDate: Date;
  amount: number;
  amountWoVat: number;
}

export interface VatItem {
  vat: number;
  vatAmount: number;
  amount: number;
}

export interface PositionItem {
  id?: number;
  uuid?: string;
  gtin?: string;
  van?: string;
  gtinVan?: SwitchInputValue;
  title: string;
  type: string;
  quantity: number;
  price: number | BigNumber;
  priceWoVat: number | BigNumber;
  vat: number | BigNumber;
  discount?: number | BigNumber;
  groupId: number;
  deliveryNoteNumber?: string;
  deliveryDate: Date;
  fulfillmentOrderId: string;
  destinationCountry?: DestinationCountry;
  sourceCountry?: string;
  total: number | BigNumber;
  totalWoVat: number | BigNumber;
  vatAmount: number | BigNumber;
  metadata?: ErrorResponse;
  conflicts?: VendorInvoicePositionValidationError[];
  _rowId?: number;
  _rowIndex?: number;
  correctionType?: VendorNoteCorrectionType;
}

export enum VendorNoteCorrectionType {
  PRICE = 'PRICE',
  QUANTITY = 'QUANTITY'
}

export interface FulfillmentOrderBlock {
  groupId?: number;
  deliveryDate: Date;
  destinationCountry?: DestinationCountry;
  conflicts?: MatchingConflict[];
  deliveryNoteNumber?: string;
  fulfillmentOrderId: string;
  sourceCountry?: string;
  units: PositionItem[];
}

export enum PositionItemType {
  SERVICE = 'SERVICE',
  PRODUCT = 'PRODUCT'
}

export enum SwitchInputMode {
  GTIN = 'GTIN',
  VAN = 'VAN'
}

export const PositionItemTypes = [
  {
    type: 'SERVICE',
    text: _('DOCUMENT_FORM.POSITIONS.SERVICE')
  },
  {
    type: 'PRODUCT',
    text: _('DOCUMENT_FORM.POSITIONS.PRODUCT')
  }
];

export const NoteTypes = [
  { value: 'CREDIT_NOTE', label: _('DOCUMENT_FORM.LABELS.NOTE_CREDIT'), name: 'CREDIT' },
  { value: 'DEBIT_NOTE', label: _('DOCUMENT_FORM.LABELS.NOTE_DEBIT'), name: 'DEBIT' }
];

export const CreditNoteReasons = [
  { value: 'CORRECTION', label: _('DOCUMENT_FORM.REASON_TYPE.CORRECTION'), name: 'CORRECTION' },
  { value: 'RETURN', label: _('DOCUMENT_FORM.REASON_TYPE.RETURN'), name: 'RETURN' }
];

export enum DocumentType {
  INVOICE = 'INVOICE',
  CREDIT_NOTE = 'CREDIT_NOTE',
  DEBIT_NOTE = 'DEBIT_NOTE'
}

export enum DocumentAction {
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

export enum DocumentEntity {
  INVOICE = 'INVOICE',
  NOTE = 'NOTE'
}

export interface NotePayload {
  type: VendorNoteType;
  vendorId: number;
  invoiceId: number;
  vendorName: string;
  number: string;
  date: string | Date;
  isImportant: boolean;
  positions: PositionItem[];
  amountsPerVat: VatItem[];
  amountWoVat?: number;
  amountWoVatCalc?: number;
  amount: number;
  status: VendorNoteStatus;
  accountingPeriodNumber: string;
  vatId?: string;
  files?: UploadedFile[];
  creditNoteReason?: VendorNoteReasonType;
}

export interface Note extends NotePayload {
  id: number;
  invoice?: Partial<InvoiceView>;
  sourceType: DocumentSourceType;
  createdAt: Date;
  bookedAt?: Date;
}

export interface VendorNoteView extends Note {
  lastReason: RecordString;
  paymentFileId: number;
  sourceType: DocumentSourceType;
  total: number;
  files?: UploadedFile[];
  connectedEntities?: ConnectedDocuments;
  destinationCountry?: string;
  invoice: InvoiceView;
  hasPositions?: boolean;
  createdAt: Date;
  matchingConflictsStatus: MatchingConflictStatus;
  creditNoteReason?: VendorNoteReasonType;
  isAvailableInFo?: boolean;
  comment?: string;
}

export interface OrderCompact {
  id: number;
  number?: string;
  vendorId?: number;
}

export interface EarlyPayment {
  dueDate: string | Date;
  discount: number;
  amount: number;
  amountWoVat: number;
}

export interface VendorDocumentConflicts {
  amountsPerVat: MatchingConflict[];
  grandTotals: MatchingConflict[];
  positionsGeneral: MatchingConflict[];
  positionsPerLine: MatchingConflict[];
  date: MatchingConflict[];
  vendorName: MatchingConflict[];
  vatId: MatchingConflict[];
  dueDate: MatchingConflict[];
  contractualDueDate: MatchingConflict[];
}
