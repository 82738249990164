import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fnc-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.scss']
})
export class CommentBoxComponent {
  @Input() comment: string;
  @Input() title: string;
  @Input() placeholder: string;
  @Output() editComment = new EventEmitter<void>();

  onEditComment() {
    this.editComment.emit();
  }
}
