export enum InvoiceStatus {
  NEW = 'NEW',
  ALL = 'ALL',
  WAITING_FOR_CORRECTION = 'WAITING_FOR_CORRECTION',
  DRAFT = 'DRAFT',
  WAITING_FOR_CLEARING = 'WAITING_FOR_CLEARING',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  APPROVED = 'APPROVED',
  SUBMITTED_FOR_PAYMENT = 'SUBMITTED_FOR_PAYMENT',
  SETTLED = 'SETTLED',
  DECLINED = 'DECLINED',
  SUSPENDED = 'SUSPENDED',
  REVISED = 'REVISED'
}

export enum InvoiceSearchableStatus {
  DRAFT = 'DRAFT',
  WAITING_FOR_CLEARING = 'WAITING_FOR_CLEARING',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  WAITING_FOR_CORRECTION = 'WAITING_FOR_CORRECTION',
  APPROVED = 'APPROVED',
  SUBMITTED_FOR_PAYMENT = 'SUBMITTED_FOR_PAYMENT',
  REVISED = 'REVISED',
  SETTLED = 'SETTLED',
  SUSPENDED = 'SUSPENDED',
  DECLINED = 'DECLINED'
}

export interface InvoiceStatusReason {
  waitingForApproval?: string;
  approved?: string;
}
