import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from '@fnc-core/services/settings/settings.service';
import { MmFormatNumberPipe } from '@mm-ui/components';
import BigNumber from 'bignumber.js';

@Pipe({
  name: 'fncFormatNumber'
})
export class FormatNumberPipe implements PipeTransform {
  constructor(
    private readonly settingsService: SettingsService,
    private readonly numberPipe: MmFormatNumberPipe
  ) { }

  transform(value: WeakNumber | BigNumber, digitsAfterDecimalPoint: WeakNumber = 2) {
    return this.numberPipe.transform(value, this.settingsService.locale.id, digitsAfterDecimalPoint);
  }
}
