import { Injectable } from '@angular/core';
import { VendorNoteStatus } from '../../../vendor-notes/models/notes';
import { Color, ColorDegree, tint } from '@fnc-shared/constants/color';

@Injectable({
  providedIn: 'root'
})
export class NotesHelpersService {
  getNoteTagColor(status: VendorNoteStatus) {
    switch (status) {
      case VendorNoteStatus.DRAFT:
        return Color.RED;
      case VendorNoteStatus.SUBMITTED_FOR_PAYMENT:
        return tint(Color.GREEN, ColorDegree.L60);
      case VendorNoteStatus.APPROVED:
        return tint(Color.GREEN, ColorDegree.L90);
      case VendorNoteStatus.APPLIED:
      case VendorNoteStatus.SETTLED:
        return Color.GREEN;
      case VendorNoteStatus.WAITING_FOR_CLARIFICATION:
        return Color.YELLOW;
      case VendorNoteStatus.DECLINED:
        return tint(Color.RED, ColorDegree.L80);
      default:
        return tint(Color.GREY, ColorDegree.L90);
    }
  }

  getNoteTagTextColor(status: VendorNoteStatus) {
    switch (status) {
      case VendorNoteStatus.DRAFT:
      case VendorNoteStatus.APPLIED:
      case VendorNoteStatus.SETTLED:
        return Color.WHITE;
      default:
        return Color.GREY;
    }
  }
}
