<div
  [popover]="productInfo"
  containerClass="m-popover fnc-product-popup"
  [placement]="placement"
  class="fnc-product-popup"
  [delay]="popoverDelay"
  triggers="mouseenter:mouseleave"
  (onShown)="loadData()"
  [adaptivePosition]="false"
>
  <ng-content></ng-content>
</div>

<ng-template #productInfo>
  <mm-loader *ngIf="isLoading$ | async as isLoading" [loading]="isLoading" [ngClass]="{ 'is-loading': isLoading }"></mm-loader>
  <div *ngIf="productData$ | async as productData" class="fnc-product-popup__content">
    <div class="fnc-product-popup__image-container">
      <img class="fnc-product-popup__image" [alt]="productData.name" [src]="imageUrl$ | async" (load)="completeLoading()" />
    </div>
    <div class="fnc-product-popup__title-container">
      <p class="fnc-product-popup__title" *ngIf="productData.name">{{ productData.name }}</p>
      <p class="fnc-product-popup__subtitle">
        <span *ngIf="productData.manufacturer">{{ productData.manufacturer }}</span>
        <span *ngIf="productData.manufacturer && productData.countryOfManufacture">/</span>
        <span *ngIf="productData.countryOfManufacture">{{ productData.countryOfManufacture }}</span>
      </p>
    </div>
  </div>
  <mm-no-data-content
    *ngIf="(isLoading$ | async) === false && (productData$ | async) === null"
    message="COMMON.NO_DATA"
  ></mm-no-data-content>
</ng-template>
