export enum AuditLogEntity {
  VENDOR_INVOICE = 'vendor_invoice',
  CREDIT_NOTE = 'credit_note',
  DEBIT_NOTE = 'debit_note',
  SKONTO_NOTE = 'skonto_note',
  BUYER_INVOICE = 'buyer_invoice',
  BUYER_NOTE = 'buyer_note'
}

export enum AuditLogApp {
  FINANCE = 'finance'
}

export enum AuditLogStatus {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED'
}

export enum AuditLogChangeType {
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  OBJECT = 'OBJECT',
  PRIMITIVE = 'PRIMITIVE'
}
