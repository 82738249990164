import { FilterTypes } from './filter-types.constant';
import { FilterNames } from '@fnc-shared/models/filter-names';

export const dateRangeFiltersMap = {
  [FilterNames.BOOK]: FilterTypes.DATE_RANGE,
  [FilterNames.CREATED]: FilterTypes.DATE_RANGE,
  [FilterNames.DATE]: FilterTypes.DATE_RANGE,
  [FilterNames.DUE_DATE]: FilterTypes.DATE_RANGE,
  [FilterNames.ETA]: FilterTypes.DATE_RANGE,
  [FilterNames.PAID_DATE]: FilterTypes.DATE_RANGE,
  [FilterNames.SHIPPING_DATE]: FilterTypes.DATE_RANGE,
  [FilterNames.BOOKED_AT]: FilterTypes.DATE_RANGE,
  [FilterNames.CREATED_AT]: FilterTypes.DATE_RANGE
};
