import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PermissionsService } from '@mm-ui/core';
import { ActionsMenuElement, ActionsStyle } from '@fnc-shared/components/action-menu/action-menu';

@Component({
  selector: 'fnc-action-menu',
  templateUrl: './action-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionMenuComponent {
  @Input() style = ActionsStyle.LINK;
  @Input() placement = 'bottomRight';
  @Input() cmdList: RecordOf<(params?: RecordNested, title?: string, event?: Event) => void> = {};
  kebabButtons: ActionsMenuElement[][];
  buttons: ActionsMenuElement[];

  get row() {
    return this.rowData;
  }

  @Input() set row(val: RecordNested) {
    this.rowData = val;
  }

  get actions(): ActionsMenuElement[] {
    return this.actionsData;
  }

  @Input() set actions(val: ActionsMenuElement[]) {
    this.buildMenu(val);
  }

  private rowData: RecordNested;
  private actionsData: ActionsMenuElement[];

  constructor(private readonly permissions: PermissionsService) {}

  isKebabButtonVisible() {
    return this.kebabButtons.some(actions => !!actions.length);
  }

  isSeparatorVisible(actions: ActionsMenuElement[][], index: number) {
    return !!(actions[index]?.length && actions[index + 1]?.length);
  }

  isLinkStyle() {
    return this.style === ActionsStyle.LINK;
  }

  isButtonStyle() {
    return this.style === ActionsStyle.BUTTON;
  }

  private buildMenu(val: ActionsMenuElement[]) {
    this.actionsData = val.filter((item: ActionsMenuElement) => this.permissions.isPermitted(item.rights));
    this.buttons = this.actionsData.filter((item: ActionsMenuElement) => this.isButtonStyle() && this.isButtonElement(item));

    const kebabActions = this.actionsData.filter((item: ActionsMenuElement) => !this.isButtonStyle() || !this.isButtonElement(item));
    const lastSectionNumber = this.getLastSectionNumber(this.actionsData);
    const sections: ActionsMenuElement[][] = [];

    for (let i = 0; i <= lastSectionNumber; i++) {
      const sectionActions = kebabActions.filter(action => i === (action.sectionNumber ?? 0));
      sections.push(sectionActions);
    }

    this.kebabButtons = sections.filter(section => section.length);
  }

  private isButtonElement(item: ActionsMenuElement) {
    return item.buttonType !== 'hidden';
  }

  private getLastSectionNumber(actions: ActionsMenuElement[]) {
    const sectionNumbers = actions.map(action => action.sectionNumber ?? 0);

    return Math.max(...sectionNumbers);
  }
}
