import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AppService } from '@fnc-core/services/app/app.service';
import { UserService } from '@fnc-core/services/user/user.service';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class InitGuard implements CanActivate {
  constructor(private readonly appService: AppService) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const translationModules = route.data?.i18n ?? [];

    return UserService.$initialized.pipe(
      switchMap(() => this.appService.loadTranslations('', translationModules).pipe(map(() => true)))
    );
  }
}
