import { Component } from '@angular/core';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';
import { TOOLTIP_DELAY } from '@fnc-shared/constants/delay.constant';

@Component({
  template: ` <ng-template [fncTemplateRequestor]="component" let-value="value">
    <div [tooltip]="value" class="text-overflow" [delay]="delay" placement="right">
      {{ value }}
    </div>
  </ng-template>`
})
export class TooltipCellComponent extends CellComponent {
  delay = TOOLTIP_DELAY;
}
