import { Directive, Input, OnInit, TemplateRef } from '@angular/core';
import { Cell } from '@fnc-shared/components/cells/cell';

@Directive({
  selector: '[fncTemplateRequestor]'
})
export class TemplateRequestorDirective implements OnInit {
  @Input() fncTemplateRequestor: Cell;

  constructor(private readonly templateRef: TemplateRef<ImplicitAny>) {}

  ngOnInit() {
    this.fncTemplateRequestor.templateRef = this.templateRef;
  }
}
