import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { StatusTransitionChip } from '@fnc-shared/interfaces/status-transition.interface';

@Component({
  selector: 'fnc-status-transition',
  templateUrl: './status-transition.component.html',
  styleUrls: ['./status-transition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusTransitionComponent {
  @Input() oldStatus: StatusTransitionChip;
  @Input() oldStatusTemplate: TemplateRef<ImplicitAny>;
  @Input() newStatus: StatusTransitionChip;
  @Input() newStatusTemplate: TemplateRef<ImplicitAny>;

  get isTransition() {
    return (!!this.oldStatus || !!this.oldStatusTemplate) && (!!this.newStatus || !!this.newStatusTemplate);
  }
}
