import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@fnc-core/services/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class CustomValidatorsService {
  constructor(private readonly translate: TranslateService, private readonly settings: SettingsService) {}

  multiFieldRequired(validationRuleFn: (form: FormGroup) => ValidationRule[]): ValidatorFn {
    return (form: FormGroup) => {
      let invalidFieldsTranslated: string[] = [];
      const invalidFields: string[] = [];

      const validationRule: ValidationRule[] = validationRuleFn(form);
      if (validationRule.some(item => item.condition)) {
        validationRule.forEach(item => {
          if (item.condition) {
            form.get(item.field).setErrors({ invalid: true });
            invalidFields.push(item.message);
          } else {
            form.get(item.field).setErrors(null);
          }
        });

        this.translate
          .get(invalidFields, { currency: this.settings.getCurrencySymbol() })
          .subscribe(lang => (invalidFieldsTranslated = invalidFields.map(item => lang[item])));

        if (invalidFields.length > 1) {
          return { required_multiple: { names: invalidFieldsTranslated.join(', ') } };
        }

        return { required: { name: invalidFieldsTranslated[0] } };
      }

      validationRule.forEach(item => form.get(item.field).setErrors(null));

      return null;
    };
  }
}

export interface ValidationRule {
  field: string;
  message: string;
  condition: boolean;
}
