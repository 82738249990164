import { ElementRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  scrollableContainerEvent = new Subject<Event>();
  private scrollContainers: HTMLElement[] = [];

  addScrollContainer(elRef: ElementRef) {
    if (!this.scrollContainers.includes(elRef.nativeElement)) {
      this.scrollContainers.push(elRef.nativeElement);
    }
  }

  removeScrollContainer(elRef: ElementRef) {
    this.scrollContainers = this.scrollContainers.filter(container => container !== elRef.nativeElement);
  }

  scrollToStart() {
    Object.entries(this.scrollContainers).forEach(([, el]) => {
      if (typeof el.scrollTo === 'function') {
        el.scrollTo(0, 0);

        return;
      }

      el.scrollTop = 0;
    });
  }
}
