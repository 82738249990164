import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { UiLoaderState, UiLoaderType } from '@fnc-core/services/ui/state/ui.model';

const initialState = (): UiLoaderState => ({
  [UiLoaderType.GLOBAL]: 0,
  [UiLoaderType.MAIN_PAGE]: 0,
  [UiLoaderType.MAIN_LIST]: 0,
  timestamp: Date.now()
});

@StoreConfig({ name: 'ui-loader' })
@Injectable({ providedIn: 'root' })
export class UiLoaderStore extends Store<UiLoaderState> {
  constructor() {
    super(initialState());
  }

  akitaPreUpdate(prevState: UiLoaderState, nextState: UiLoaderState) {
    return {
      ...prevState,
      ...nextState,
      [UiLoaderType.GLOBAL]: this.checkLoaderBound(nextState[UiLoaderType.GLOBAL] ?? 0),
      [UiLoaderType.MAIN_PAGE]: this.checkLoaderBound(nextState[UiLoaderType.MAIN_PAGE] ?? 0),
      [UiLoaderType.MAIN_LIST]: this.checkLoaderBound(nextState[UiLoaderType.MAIN_LIST] ?? 0),
      timestamp: Date.now()
    };
  }

  protected checkLoaderBound(value: number) {
    return value < 0 ? 0 : value;
  }
}
