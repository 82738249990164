export interface UploadedFile {
  id?: string;
  name: string;
  size?: number;
  type: string;
  uploadedDate?: Date;
}

export const allowedFileTypes = '.pdf, .jpeg, .jpg, .png, .tiff, .tif, .docx, .doc';

export const maxFileSizeBytes = 100000000;

export enum FileActions {
  UPLOAD = 'UPLOAD',
  DELETE = 'DELETE',
  REPLACE = 'REPLACE',
  DOWNLOAD = 'DOWNLOAD',
  OPEN = 'OPEN'
}
