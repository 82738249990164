import { Injectable } from '@angular/core';
import { UserService } from '@fnc-app/modules/core/services/user/user.service';
import { ITEMS_PER_PAGE_STORAGE_KEY, PagerStateKey } from '@fnc-shared/constants/page-keys.constant';
import { SimpleCacheService } from '@mm-ui/core';

interface UserPagerState {
  [key: number]: {
    [key: string]: number;
  };
}

@Injectable()
export class PageLimitStateService extends SimpleCacheService {
  storage = localStorage;

  private readonly pagerStateKey = ITEMS_PER_PAGE_STORAGE_KEY;

  constructor(private readonly userService: UserService) {
    super();
  }

  setPagesLimit(pageKey: PagerStateKey, pagesLimit: number) {
    if (!pageKey) {
      return;
    }

    let pagerState = (this.getItem(this.pagerStateKey) ?? {}) as UserPagerState;
    const userId = this.userService.profile.id;

    pagerState = {
      ...pagerState,
      [userId]: {
        ...pagerState[userId],
        [pageKey]: pagesLimit
      }
    };

    this.setItem(this.pagerStateKey, pagerState);
  }

  getPagesLimit(pageKey: PagerStateKey) {
    const pagerState = this.getItem(this.pagerStateKey) as UserPagerState;
    const userId = this.userService.profile.id;

    return pagerState?.[userId]?.[pageKey];
  }
}
