import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MmAlertService, MmModalService } from '@mm-ui/components';
import { PermissionsService } from '@mm-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ActionsMenuElement } from '@fnc-shared/components/action-menu/action-menu';
import { FileActionItems, FileTypes, NoFileMessages } from '@fnc-shared/components/file-tile/file-tile.constant';
import { FileActions, UploadedFile } from '@fnc-shared/components/modal-file-manager-window/file-manager.constant';
import { FileManageService } from '@fnc-shared/components/modal-file-manager-window/file-manager.service';
import { ModalFileManagerWindowComponent } from '@fnc-shared/components/modal-file-manager-window/modal-file-manager-window.component';
import { ALERT_GLOBAL } from '@fnc-shared/constants/alert-config.constant';
import { COMMON_HIDE_TIME } from '@fnc-shared/constants/alert-hide-time.constant';

@Component({
  selector: 'fnc-file-tile',
  styleUrls: ['./file-tile.component.scss'],
  templateUrl: './file-tile.component.html'
})
export class FileTileComponent implements OnInit {
  @Input() droppedFiles$: Subject<FileList>;
  @Input() documentId: number;
  @Input() fileType: FileTypes;
  modalRef: BsModalRef;
  cmdList = {
    uploadFile: (params: RecordNested, title: string) => this.openFileManagerWindow(params, title),
    replaceFile: (params: RecordNested, title: string) => this.openFileManagerWindow(params, title),
    openFile: () => this.openFile(),
    downloadFile: () => this.loadFile(),
    deleteFile: () => this.deleteFile()
  };
  actions: ActionsMenuElement[] = [];
  canShowMenu = false;
  noFileMessage: string;

  get fileDocument() {
    return this.file;
  }

  @Input() set fileDocument(file: UploadedFile) {
    this.file = file;
    this.buildMenu(this.fileType);
  }

  private file: UploadedFile;

  constructor(
    private readonly permissions: PermissionsService,
    private readonly fileService: FileManageService,
    private readonly modalService: MmModalService,
    private readonly elem: ElementRef,
    private readonly translateService: TranslateService,
    private readonly alertService: MmAlertService
  ) {}

  ngOnInit() {
    this.buildMenu(this.fileType);

    if (this.droppedFiles$) {
      this.droppedFiles$.subscribe(files => this.openFilesDrop(files));
    }
  }

  onTileClick() {
    if (this.fileDocument && this.actions.length) {
      this.openFile();
    }
  }

  buildMenu(fileType: FileTypes) {
    if (!Array.isArray(FileActionItems[fileType])) {
      this.canShowMenu = false;

      return;
    }

    this.actions = FileActionItems[fileType].filter(
      (item: ActionsMenuElement) =>
        this.permissions.isPermitted(item.rights) &&
        ((!this.fileDocument && item.cmd === 'uploadFile') || (this.fileDocument && item.cmd !== 'uploadFile'))
    );
    this.canShowMenu = !!this.actions.length;
    if (!this.fileDocument) {
      this.noFileMessage = NoFileMessages[fileType as keyof Record<string, string>];
    }
  }

  getActionCmd(action: ActionsMenuElement) {
    return this.cmdList[action.cmd as keyof typeof this.cmdList];
  }

  private openFileManagerWindow(params: RecordNested, title: string) {
    const initialState = {
      files: this.fileDocument ? [this.fileDocument] : [],
      title,
      label: params.inputLabel,
      documentId: this.documentId,
      fileType: this.fileType
    };

    this.modalRef = this.modalService.show(ModalFileManagerWindowComponent, { initialState } as ModalOptions);
  }

  private openFilesDrop(files: FileList) {
    const cmd = this.fileDocument ? 'replaceFile' : 'uploadFile';
    const action: ActionsMenuElement = this.fileDocument
      ? FileActionItems[this.fileType].find(el => el.cmd === cmd)
      : this.actions.find(el => el.cmd === cmd);

    if (!action) {
      this.showError();

      return;
    }

    const initialState = {
      files: this.fileDocument ? [this.fileDocument] : [],
      filesDroppedExt: files,
      title: action.title,
      label: action.params.inputLabel,
      documentId: this.documentId,
      fileType: this.fileType
    };

    this.modalRef = this.modalService.show(ModalFileManagerWindowComponent, { initialState } as ModalOptions);
  }

  private showError() {
    const errorPrefix = 'COMMON.FILE_TILE_ERROR.';
    const operation = this.file ? 'REPLACE' : 'UPLOAD';
    const errorMessage = this.translateService.instant(`${errorPrefix}${operation}.${this.fileType}`) as string;
    this.alertService.push(ALERT_GLOBAL, {
      autoHideTime: COMMON_HIDE_TIME,
      type: 'error',
      alertDescription: errorMessage,
      alertTitle: ' '
    });
  }

  private loadFile() {
    const link = this.elem.nativeElement.getElementsByClassName('document-link')[0];
    link.href = this.getFileDownloadLink();
    link.click();
  }

  private openFile() {
    window.open(this.getFileOpenLink(), '_blank');
  }

  private deleteFile() {
    this.fileService
      .deleteFile(this.documentId, this.fileType, this.fileDocument)
      .subscribe(() => this.fileService.fileManagerSuccess.next(true));
  }

  private getFileDownloadLink() {
    return this.fileService.getFileUrl(this.documentId, this.fileDocument, this.fileType);
  }

  private getFileOpenLink() {
    return this.fileService.getFileUrl(this.documentId, this.fileDocument, this.fileType, FileActions.OPEN);
  }
}
