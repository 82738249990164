import { Route } from '@angular/router';
import { PermissionsGuard } from '@mm-ui/core';
import { TranslationInstallationServicesModules } from '@fnc-app/routes/translation-modules';
import { InstallationServicesPageComponent } from '@fnc-core/components/installation-services-page/installation-service-page.component';
import { InitGuard } from '@fnc-core/guards/init.guard';
import { Path } from '@fnc-shared/constants/path.constant';

export const InstallationServiceRoute: Route = {
  path: Path.installationServices,
  canActivate: [InitGuard],
  component: InstallationServicesPageComponent,
  data: {
    breadcrumb: 'NAVIGATION.MAIN.INSTALLATION_SERVICES',
    i18n: TranslationInstallationServicesModules
  },
  children: [
    {
      path: '',
      redirectTo: Path.services,
      pathMatch: 'full'
    },
    {
      path: Path.services,
      loadChildren: () =>
        import('@fnc-modules/installation-services/pages/installation-services/installation-services.module').then(
          m => m.InstallationServicesModule
        ),
      canActivate: [PermissionsGuard],
      data: {
        breadcrumb: 'NAVIGATION.INSTALLATION_SERVICES.SERVICES',
        title: 'NAVIGATION.INSTALLATION_SERVICES.SERVICES',
        permissions: {
          rule: 'ROLE_CAN_MANAGE_INVENTORIES',
          navigateTo: ['/', Path.forbidden]
        }
      }
    },
    {
      path: Path.partners,
      canActivate: [PermissionsGuard],
      loadChildren: () =>
        import('@fnc-modules/installation-services/pages/installation-partners/installation-partners.module').then(
          m => m.InstallationPartnersModule
        ),
      data: {
        breadcrumb: 'NAVIGATION.INSTALLATION_SERVICES.PARTNERS',
        title: 'NAVIGATION.INSTALLATION_SERVICES.PARTNERS',
        permissions: {
          rule: 'ROLE_CAN_MANAGE_INVENTORIES',
          navigateTo: ['/', Path.forbidden]
        }
      }
    }
  ]
};
