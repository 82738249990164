export const Path = {
  accountReports: 'account-reports',
  accountingPeriods: 'accounting-periods',
  accountsPayable: 'accounts-payable',
  accountsReceivable: 'accounts-receivable',
  attributes: 'attributes',
  admin: 'admin-portal',
  buyerInvoices: 'buyer-invoices',
  buyerNotes: 'buyer-notes',
  clearingOffice: 'clearing-office',
  deliveries: 'deliveries',
  demandPlanning: 'demand-planning',
  failedMessages: 'failed-messages',
  financeOffice: 'finance-office',
  financePortal: 'finance-portal',
  financeReports: 'finance',
  forbidden: 'forbidden',
  fulfilment: 'fulfilment',
  fulfilmentOrders: 'fulfilment-orders',
  goodReceiptsOld: 'goods-receipts-old',
  goodsReceipts: 'goods-receipts',
  home: 'home',
  intrastatProducts: 'products',
  intrastatReports: 'intrastat',
  saleUnits: 'sale-units',
  iwtInvoices: 'iwt-invoices',
  installationServices: 'installation-services',
  modernMatching: 'modern-matching',
  mcomOfferAssignmentPage: 'offer-assignment',
  offerManagement: 'offer-management',
  paymentReminders: 'payment-reminders',
  payments: 'payments',
  productData: 'product-data',
  replenishment: 'replenishment',
  replenishmentV2: 'replenishment-v2',
  reports: 'reports',
  returns: 'returns',
  saleOrders: 'sale-orders',
  salesForecasting: 'sales-forecasting',
  settings: 'settings',
  settle: 'settle',
  signin: 'signin',
  supplyChain: 'supply-chain',
  supplyOrderDrafts: 'supply-order-drafts',
  supplyOrders: 'supply-orders',
  vendorBalances: 'vendor-balances',
  vendorInvoices: 'vendor-invoices',
  vendorNotes: 'vendor-notes',
  vendorsControlPanel: 'vendors-control-panel',
  partners: 'partners',
  services: 'services'
};
