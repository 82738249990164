import { Component } from '@angular/core';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';

@Component({
  template: `
    <ng-template [fncTemplateRequestor]="component" let-rowIndex="rowIndex">
      {{ rowIndex + 1 }}
    </ng-template>
  `
})
export class IndexCellComponent extends CellComponent {}
