import { Route } from '@angular/router';
import { TranslationSupplyChainModules } from '@fnc-app/routes/translation-modules';
import { SupplyChainPageComponent } from '@fnc-core/components/supply-chain-page/supply-chain-page.component';
import { InitGuard } from '@fnc-core/guards/init.guard';
import { environment } from '@fnc-env/environment';
import { Dictionary } from '@fnc-shared/constants/dictionary.constant';
import { Path } from '@fnc-shared/constants/path.constant';
import { DictionaryResolverService, PermissionsGuard } from '@mm-ui/core';

export const SupplyChainRoute: Route = {
  path: Path.supplyChain,
  canActivate: [InitGuard],
  component: SupplyChainPageComponent,
  data: {
    breadcrumb: 'NAVIGATION.MAIN.SUPPLY_CHAIN',
    i18n: TranslationSupplyChainModules
  },
  children: [
    {
      path: '',
      redirectTo: Path.replenishment,
      pathMatch: 'full'
    },
    {
      path: Path.demandPlanning,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/demand-planning/demand-planning.module').then(m => m.DemandPlanningModule),
      data: {
        breadcrumb: 'NAVIGATION.SUPPLY_CHAIN.DEMAND_PLANNING',
        title: 'NAVIGATION.SUPPLY_CHAIN.DEMAND_PLANNING',
        permissions: {
          rule: { $nand: ['TRUE', 'ROLE_VENDOR'] },
          navigateTo: ['/', Path.forbidden]
        }
      }
    },
    {
      path: Path.salesForecasting,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/sales-forecasting/sales-forecasting.module').then(m => m.SalesForecastingModule),
      data: {
        breadcrumb: 'NAVIGATION.SUPPLY_CHAIN.SALES_FORECASTING',
        title: 'NAVIGATION.SUPPLY_CHAIN.SALES_FORECASTING',
        permissions: {
          rule: { $nand: ['TRUE', 'ROLE_VENDOR'] },
          navigateTo: ['/', Path.forbidden]
        }
      }
    },
    {
      path: Path.replenishmentV2,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/replenishment-v2/replenishment.module').then(m => m.ReplenishmentModuleV2),
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.SUPPLY_CHAIN.REPLENISHMENT_V2',
        title: 'NAVIGATION.SUPPLY_CHAIN.REPLENISHMENT_V2',
        permissions: {
          rule: { $nand: ['TRUE', 'ROLE_VENDOR'] },
          navigateTo: ['/', Path.forbidden]
        },
        dictionaries: Dictionary.DESTINATIONS,
        dictionariesBasePath: `${environment.production ? environment.smsAppPath : ''}demand-planning/client-api/v1/`,
        dictionariesPath: 'filter-inventories?type=warehouse'
      }
    },
    {
      path: Path.replenishment,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/replenishment/replenishment.module').then(m => m.ReplenishmentModule),
      data: {
        breadcrumb: 'NAVIGATION.SUPPLY_CHAIN.REPLENISHMENT',
        title: 'NAVIGATION.SUPPLY_CHAIN.REPLENISHMENT',
        permissions: {
          rule: { $nand: ['TRUE', 'ROLE_VENDOR'] },
          navigateTo: ['/', Path.forbidden]
        }
      }
    },
    {
      path: Path.supplyOrderDrafts,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/supply-order-drafts/supply-order-drafts.module').then(m => m.SupplyOrderDraftsModule),
      data: {
        breadcrumb: 'NAVIGATION.SUPPLY_CHAIN.SUPPLY_ORDER_DRAFTS',
        title: 'NAVIGATION.SUPPLY_CHAIN.SUPPLY_ORDER_DRAFTS',
        permissions: {
          rule: { $nand: ['TRUE', 'ROLE_VENDOR'] },
          navigateTo: ['/', Path.forbidden]
        }
      }
    },
    {
      path: Path.supplyOrders,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/supply-orders/supply-orders.module').then(m => m.SupplyOrdersModule),
      data: {
        breadcrumb: 'NAVIGATION.SUPPLY_CHAIN.SUPPLY_ORDERS',
        title: 'NAVIGATION.SUPPLY_CHAIN.SUPPLY_ORDERS',
        permissions: {
          rule: { $nand: ['TRUE', 'ROLE_VENDOR'] },
          navigateTo: ['/', Path.forbidden]
        }
      }
    }
  ]
};
