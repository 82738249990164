<div
  class="m-row"
  [ngClass]="{
    'fnc-vendor-properties': hasContent(),
    'with-shipping-info': hasMultipleBlocks(),
  }"
>
  <div *ngIf="billingInfo" [ngClass]="getAddressColumnNgClass()">
    <fnc-address-properties
      [title]="withTitle ? secondAddressTitle : ''"
      [addressInfo]="billingInfo"
      [type]="secondAddressType"
      [isLast]="!hasBillingOrVendorInfo()"
      (dataUpdate)="onDataUpdate($event)"
      [isEditableAddress]="isEditableBillingAddress"
    ></fnc-address-properties>
  </div>
  <div *ngIf="shippingInfo" [ngClass]="getAddressColumnNgClass()">
    <fnc-address-properties
      [title]="withTitle ? firstAddressTitle : ''"
      [addressInfo]="shippingInfo"
      [type]="firstAddressType"
      [isLast]="!hasVendorInfo()"
      (dataUpdate)="onDataUpdate($event)"
      [isEditableAddress]="isEditableShippingAddress"
    ></fnc-address-properties>
  </div>

  <div *ngIf="hasVendorId()" [ngClass]="getVendorColumnNgClass()">
    <fnc-vendor-properties [title]="withTitle ? 'VENDORS.PROPERTIES.PAY_TITLE' : ''" [vendorData]="this.vendorData"></fnc-vendor-properties>
  </div>
</div>
