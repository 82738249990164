import { Injectable } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { TableCellConfig } from '@fnc-shared/constants/data-table-config.constant';
import { AuditLogConfig, AuditLogTableItem } from '@fnc-shared/models/audit-log';
import { TemplateRegistryService } from '@fnc-shared/services/template-registry.service';
import { TreeStatus } from '@mm-ui/components';
import { ColumnConfig, TableConfig } from '@mm-ui/components/lib/components/datatable/models/datatable.model';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AuditLogUserCellComponent } from '../cells/audit-log-user-cell.component';
import { AuditValueCellComponent } from '../cells/audit-value-cell.component';
import { DateTimeCellComponent } from '../cells/date-time-cell.component';
import { KeysCellComponent } from '../cells/keys-cell.component';
import { PrefixedCellComponent } from '../cells/prefixed-cell.component';


@Injectable()
export class AuditLogTableService {
  constructor(
    private readonly templateRegistryService: TemplateRegistryService
  ) {}

  getTableConfig(): Partial<TableConfig> {
    return {
      columnMode: ColumnMode.force,
      reorderable: false,
      headerHeight: 'auto',
      treeFromRelation: 'parentId',
      treeToRelation: 'rowId',
      rowClass: (row: AuditLogTableItem) => this.getRowClass(row)
    };
  }

  getColumnsConfig(config: AuditLogConfig): ColumnConfig[] {
    return [
      {
        prop: 'user',
        name: _('COMMON.AUDIT_LOG.COLUMN.USER'),
        width: 240,
        isTreeColumn: true,
        treeLevelIndent: 40,
        cellTemplate: this.templateRegistryService.getTemplate(AuditLogUserCellComponent),
        ...TableCellConfig.fixedField
      },
      {
        prop: 'event',
        name: _('COMMON.AUDIT_LOG.COLUMN.EVENT'),
        width: 90,
        cellTemplate: this.templateRegistryService.getTemplate(
          PrefixedCellComponent,
          component => component.prefix = 'COMMON.AUDIT_LOG.EVENT.'
        ),
        ...TableCellConfig.fixedField
      },
      {
        prop: 'fields',
        name: _('COMMON.AUDIT_LOG.COLUMN.FIELDS'),
        width: 140,
        cellTemplate: this.templateRegistryService.getTemplate(
          KeysCellComponent,
          component => component.translationParams = config.translationParams
        ),
        ...TableCellConfig.fixedField
      },
      {
        prop: 'previousValue',
        name: _('COMMON.AUDIT_LOG.COLUMN.PREVIOUS_VALUE'),
        width: 150,
        cellTemplate: this.templateRegistryService.getTemplate(AuditValueCellComponent),
        ...TableCellConfig.staticField
      },
      {
        prop: 'newValue',
        name: _('COMMON.AUDIT_LOG.COLUMN.NEW_VALUE'),
        width: 150,
        cellTemplate: this.templateRegistryService.getTemplate(AuditValueCellComponent),
        ...TableCellConfig.staticField
      },
      {
        prop: 'dateTime',
        name: _('COMMON.AUDIT_LOG.COLUMN.DATE_TIME'),
        width: 120,
        ...TableCellConfig.staticField,
        ...TableCellConfig.alignRight,
        cellTemplate: this.templateRegistryService.getTemplate(DateTimeCellComponent)
      }
    ];
  }

  private getRowClass(row: AuditLogTableItem) {
    if (row.treeStatus === TreeStatus.DISABLED && !row.parentId) {
      return 'fnc-row-tree-disabled';
    }

    if (!!row.parentId) {
      return 'bg-grey-tint-96';
    }

    return '';
  }
}
