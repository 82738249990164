export enum Dictionary {
  SALES_CHANNELS = 'sales-channels',
  SALES_PLATFORMS = 'sales-platforms',
  SALES_COUNTRIES = 'sales-countries',
  KEY_ACCOUNTS_SALES_CHANNELS = 'key-accounts-sales-channels',
  VAT_CODES = 'vat-codes',
  VENDOR_TYPES = 'vendor-types',
  FULFILLMENT_ORDER_TYPES = 'fulfillment-order-types',
  FULFILLMENT_SOURCE_COUNTRIES = 'fulfillment-source-countries',
  DELIVERY_STATUSES = 'delivery-statuses',
  DELIVERY_TYPES = 'delivery-types',
  IWT_INVOICE_STATUSES = 'iwt-invoice-statuses',
  WAREHOUSE_INVENTORIES = 'warehouse-inventories',
  BUYER_TYPES = 'buyer-types',
  BUYER_INVOICE_STATUSES = 'buyer-invoice-statuses',
  COUNTRIES = 'countries',
  INTRASTAT_STATUSES = 'intrastat-statuses',
  INTRASTAT_PRODUCT_STATUSES = 'intrastat-product-statuses',
  INTRASTAT_UNITS_OF_MEASUREMENT = 'intrastat-product-units',
  PAYMENT_SYSTEM = 'payment-system',
  DESTINATIONS = 'inventories'
}
