import { Injectable } from '@angular/core';
import { NavigationStart } from '@angular/router';
import { RefreshStore } from './refresh.store';
import { UiService } from '@fnc-core/services/ui/state/ui.service';
import { ScrollService } from '@fnc-shared/services/scroll.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  private currentUrl: string;
  private previousUrl: string;
  private currentFullUrl: string;
  private previousFullUrl: string;

  constructor(
    private readonly refreshStore: RefreshStore,
    private readonly uiService: UiService,
    private readonly scrollService: ScrollService
  ) {}

  refreshPage(event: NavigationStart) {
    const urlParts = event.url.split('?');
    const [newUrl, queryParams] = urlParts;

    this.previousFullUrl = this.currentFullUrl;
    this.currentFullUrl = event.url;

    if (newUrl !== this.currentUrl) {
      this.previousUrl = this.currentUrl;
      this.currentUrl = newUrl;
      this.uiService.loadingPage = true;

      if (event.id === 1) {
        this.uiService.loading = true;
      }

      return;
    }

    if (queryParams) {
      return;
    }

    this.scrollService.scrollToStart();
    this.updateRefreshState(true);
  }

  finishPageRefresh() {
    this.updateRefreshState(false);
    this.uiService.loadingPage = false;
    this.uiService.loading = false;
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  getPreviousFullUrl() {
    return this.previousFullUrl;
  }

  updateRefreshState(state: boolean) {
    this.refreshStore.update({
      page: {
        refresh: state
      }
    });
  }
}
