import { Directive, HostListener, Input, OnChanges, Output } from '@angular/core';
import { WindowEvents } from '@fnc-shared/services/window/events';
import { WindowService } from '@fnc-shared/services/window/window.service';
import { Subject } from 'rxjs';

@Directive({
  selector: '[fncWindowResizeTrigger]'
})
export class WindowResizeTriggerDirective implements OnChanges {
  @Input() condition: boolean;

  @Output() windowResize$ = new Subject();

  resizeHappened = false;

  constructor(private readonly windowsService: WindowService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeHappened = true;
    this.windowResize$.next(event);
  }

  ngOnChanges() {
    // It's a fix of the issue where the chart is not resizing when parent container dimension changed
    // There's a opened bug in ngx-charts https://github.com/swimlane/ngx-charts/issues/650
    if (this.condition && this.resizeHappened) {
      this.windowsService.dispatchUIEvent(WindowEvents.RESIZE, true, false);
    }

    this.resizeHappened = false;
  }
}
