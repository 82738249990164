import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { InstallationServiceSideMenuItems } from './installation-service-side-menu-items.constant';
import { AccountingPageComponent } from '@fnc-core/components/accounting-page/accounting-page.component';

@UntilDestroy()
@Component({
  templateUrl: './installation-service-page.component.html'
})
export class InstallationServicesPageComponent extends AccountingPageComponent {
  sideMenu = InstallationServiceSideMenuItems;
}
