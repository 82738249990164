import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';
import { AuditLogValueType } from '@fnc-shared/models/audit-log';

@Component({
  template: `
    <ng-template [fncTemplateRequestor]="component" let-value="value" let-row="row">
      <div class="text-overflow">
        <ng-container *ngIf="row.cellTemplate">
          <ng-container
            *ngTemplateOutlet="row.cellTemplate; context: { row: row, value: value }"
          ></ng-container>
        </ng-container>
        <ng-container *ngIf="!row.cellTemplate">
          <fnc-secondary-txt *ngIf="isMixedCell(value); else valueTpl">
            {{ 'COMMON.AUDIT_LOG.MIXED_LABEL' | translate }}
          </fnc-secondary-txt>
          <ng-template #valueTpl>
            {{ getCellValue(value) | mmAddDash }}
          </ng-template>
        </ng-container>
      </div>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuditValueCellComponent extends CellComponent {
  isMixedCell(value: AuditLogValueType | AuditLogValueType[]) {
    return Array.isArray(value) && value.length > 1;
  }

  getCellValue(value: AuditLogValueType[] | RecordExtPrimitive | ExtPrimitive) {
    return typeof value === 'object' ? null : value;
  }
}
