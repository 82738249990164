<ng-template #modalTemplate>
  <mm-modal class="fnc-modal-right-sidebar">
    <mm-modal-content [isPadded]="false">
      <mm-link-icon *ngIf="showCloseIcon" kind="close" (click)="modalRef.hide()"></mm-link-icon>
      <h3 class="fnc-modal-right-sidebar__heading">{{ heading | translate }}</h3>
      <ng-content> </ng-content>
      <div class="fnc-modal-right-sidebar__footer-text" *ngIf="footerText">
        <p>
          <mm-icon size="0.7" kind="info" color="grey-tint-60" class="fnc-modal-right-sidebar__footer-text__icon"></mm-icon>
          {{ footerText }}
        </p>
      </div>
    </mm-modal-content>
    <mm-modal-footer class="fnc-modal-right-sidebar__footer">
      <button [kind]="confirmButtonKind" mmButton size="small" type="submit" (click)="onSubmit()" [disabled]="disabled$ | async">
        {{ confirmButtonText | translate }}
      </button>
      &nbsp;
      <button mmButton size="small" kind="primaryRaised" (click)="modalRef.hide()">
        {{ "COMMON.DOCUMENT_FORM.BUTTONS.CANCEL" | translate }}
      </button>
    </mm-modal-footer>
  </mm-modal>
</ng-template>
