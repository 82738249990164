import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { NotificationQuery } from '@fnc-core/services/notification-events/state/notification.query';

export const NOTIFICATION_PAGINATOR = new InjectionToken('NOTIFICATION_PAGINATOR', {
  providedIn: 'root',
  factory: () => {
    const notificationQuery = inject(NotificationQuery);

    return new PaginatorPlugin(notificationQuery).withControls().withRange();
  }
});
