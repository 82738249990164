<mm-file-tile
  [file]="fileDocument"
  [noDataTemplate]="noDataTemplate"
  [actionsTemplate]="canShowMenu ? actionsMenu : null"
  (click)="onTileClick()"
></mm-file-tile>

<ng-template #actionsMenu>
  <mm-action-table-menu-item
    *ngFor="let action of actions"
    value="action"
    [ngClass]="{ 'mm-action-table-menu-item-disabled': action.disabled }"
    (click)="getActionCmd(action)(action.params, action.title)"
  >
    <span>{{ action.label | translate }}</span>
  </mm-action-table-menu-item>
</ng-template>

<ng-template #noDataTemplate>
  <div class="fnc-file-tile__no-file">
    <mm-icon kind="lightning-filled" color="red"></mm-icon>
    <span>{{ noFileMessage | translate }}</span>
  </div>
</ng-template>

<a *ngIf="fileDocument" class="fnc-hidden document-link" [download]="fileDocument.name"></a>
