import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(data: unknown[], key: string, value: string, exclude = false): unknown[] {
    if (!data || data.length === 0) {
      return [];
    }

    return (data as Record<string, string>[]).filter(item => (exclude ? item[key] !== value : item[key] === value));
  }
}
