<div class="fnc-comment-box">
  <mm-info-box [title]="title" [contentTemplate]="commentTpl" bgColor="blue-tint-95"> </mm-info-box>
  <ng-template #commentTpl>
    <div class="fnc-comment-box__comment-wrap" (click)="onEditComment()">
      <span class="fnc-comment-box__comment-text" *ngIf="comment; else noComment">
        {{ comment }}
      </span>
      <span class="fnc-comment-box__edit-icon">
        <mm-icon kind="edit-small" [size]="0.5" color="blue"></mm-icon>
      </span>
    </div>
  </ng-template>
  <ng-template #noComment>
    <em class="fnc-comment-box__comment-btn">
      {{ placeholder }}
    </em>
  </ng-template>
</div>
