import { ChangeDetectionStrategy, Component, HostListener, Input, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MmIconModule, MmModalService } from '@mm-ui/components';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ButtonKind } from '@mm-ui/components/lib/components/button/mm-button.directive';
import { BehaviorSubject, Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'fnc-modal-right-sidebar',
  templateUrl: './modal-right-sidebar.component.html',
  styleUrls: ['./modal-right-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ModalRightSidebarComponent {
  @ViewChild('modalTemplate', { static: true }) modalTemplateRef: TemplateRef<ImplicitAny>;

  @Output() submit$ = new Subject<void>();

  @Input() confirmButtonText = 'COMMON.CONFIRM_WINDOW.CONFIRM';
  @Input() confirmButtonKind: ButtonKind = 'primaryRaised';
  @Input() heading = '';
  @Input() showCloseIcon = false;
  @Input() disabled$ = new BehaviorSubject<boolean>(false);
  @Input() footerText = '';

  modalRef: BsModalRef;

  private readonly modalOptions: ModalOptions = { ignoreBackdropClick: true };

  constructor(private readonly modalService: MmModalService) {}

  @HostListener('document:keydown.escape', ['$event']) onEscKeyPress() {
    this.closeModal();
  }

  closeModal() {
    this.modalRef?.hide();
  }

  open() {
    this.modalRef = this.modalService.show(this.modalTemplateRef, this.modalOptions);
  }

  onSubmit() {
    this.submit$.next();
  }
}
