<div *ngIf="vendorData" class="fnc-vendor-properties-row">
  <div class="fnc-action-title" *ngIf="title">
    {{ title | translate }}
  </div>
  <div class="m-col-xs12of12 fnc-vendor-properties-name">
    <h4 class="metro-blue">{{ vendorData.name }}</h4>
    <mm-icon class="fnc-vendor-properties-icon-check" color="green" size="0.67" kind="check-small-filled"></mm-icon>
  </div>
</div>
<div *ngIf="vendorData" class="m-row">
  <div class="m-col-xsTight6of12">
    <div class="fnc-vendor-properties-row">
      <h6>{{ "VENDORS.PROPERTIES.GENERAL_INFORMATION" | translate }}</h6>
    </div>
    <div class="fnc-vendor-properties-row" *ngIf="vendorData.gln">
      <mm-label class="mm-label" for="">{{ "VENDORS.PROPERTIES.GLN" | translate }}</mm-label>
      <span>{{ vendorData.gln }}</span>
    </div>
    <div class="fnc-vendor-properties-row" *ngIf="vendorData.taxNumbers?.length">
      <mm-label class="mm-label">{{ "VENDORS.PROPERTIES.TAX_NUMBER" | translate }}</mm-label>
      <span>{{ vendorData.taxNumbers.join(", ") }}</span>
    </div>
    <div class="fnc-vendor-properties-row" *ngIf="vendorData.vatIds?.length">
      <mm-label class="mm-label">{{ "VENDORS.PROPERTIES.VAT_ID" | translate }}</mm-label>
      <span>{{ vendorData.vatIds.join(", ") }}</span>
    </div>
    <div class="fnc-vendor-properties-row" *ngIf="vendorData.administrativeOrigin">
      <mm-label class="mm-label">{{ "VENDORS.PROPERTIES.ADMINISTRATIVE_ORIGIN_LABEL" | translate }}</mm-label>
      <span>{{ vendorData.administrativeOrigin }}</span>
    </div>
    <div class="fnc-vendor-properties-row">
      <mm-label class="mm-label">{{ "VENDORS.PROPERTIES.PAYMENT_TERMS" | translate }}</mm-label>
      <span>{{ currentPaymentTermsConditionLabel }}</span>
    </div>
    <div class="fnc-vendor-properties-address" *ngIf="addressBlock?.info.length">
      <div>
        <div class="fnc-vendor-properties-row">
          <h6>{{ "VENDORS.PROPERTIES.ADDRESS" | translate }}</h6>
        </div>
        <div>
          <mm-label class="mm-label" for="address">{{ addressBlock.label | translate }}</mm-label>
          <span *ngFor="let item of addressBlock.info; let last = last"> {{ item }}<ng-container *ngIf="!last">, </ng-container> </span>
        </div>
      </div>
    </div>
  </div>
  <div class="m-col-xsTight6of12" *ngIf="vendorData.bankAccounts.length">
    <div class="fnc-vendor-properties-row">
      <h6>{{ "VENDORS.PROPERTIES.BANK_INFORMATION" | translate }}</h6>
    </div>
    <div class="fnc-vendor-properties-row">
      <mm-label class="mm-label" for="">{{ "VENDORS.PROPERTIES.BANK_INSTITUTE" | translate }}</mm-label>
      <span>{{ vendorData.bankAccounts ? vendorData.bankAccounts[0].bankInstitute : "" }}</span>
    </div>
    <div class="fnc-vendor-properties-row">
      <mm-label class="mm-label" for="">{{ "VENDORS.PROPERTIES.IBAN" | translate }}</mm-label>
      <span>{{ vendorData.bankAccounts ? vendorData.bankAccounts[0].iban : "" }}</span>
    </div>
    <div class="fnc-vendor-properties-row">
      <mm-label class="mm-label" for="">{{ "VENDORS.PROPERTIES.BIC" | translate }}</mm-label>
      <span>{{ vendorData.bankAccounts ? vendorData.bankAccounts[0].bic : "" }}</span>
    </div>
    <div class="fnc-vendor-properties-row">
      <mm-label class="mm-label" for="">{{ "VENDORS.PROPERTIES.ACCOUNT_HOLDER" | translate }}</mm-label>
      <span>{{ vendorData.bankAccounts ? vendorData.bankAccounts[0].accountHolder : "" }}</span>
    </div>
  </div>
</div>
<div class="m-row fnc-vendor-properties__vendor-managers" *ngIf="showVendorManagers">
  <ng-container [ngTemplateOutlet]="vendorManagersTemplate"> </ng-container>
</div>

<ng-template #vendorManagersTemplate>
  <div class="fnc-vendor-properties__vendor-managers__title">
    <div class="fnc-vendor-properties-row">
      <h6>{{ "VENDORS.PROPERTIES.CONTACT_INFORMATION" | translate }}</h6>
    </div>
  </div>
  <div class="m-col-xsTight6of12">
    <mm-label class="mm-label">{{ "VENDORS.PROPERTIES.VENDOR_MANAGER_CONTACT" | translate }}</mm-label>
    <span>{{ vendorData.vendorManager?.name | mmAddDash }}</span>
  </div>
  <div class="m-col-xsTight6of12">
    <mm-label class="mm-label">{{ "VENDORS.PROPERTIES.VENDOR_SUPPORT_CONTACT" | translate }}</mm-label>
    <span>{{ vendorData.vendorIntegrationManager?.name | mmAddDash }}</span>
  </div>
</ng-template>
