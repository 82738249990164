import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Vendor, VendorsQuery } from '@fnc-core/services/vendors/state';
import { ShippingInformation } from '@fnc-modules/fulfilment-orders/models/orders.interface';
import { AddressPropertiesService } from '@fnc-shared/components/entity-properties/address-properties/address-properties.service';
import { AddressType, AddressUpdateEvent } from '@fnc-shared/components/entity-properties/address-properties/interfaces/address-properties.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import isUndefined from 'lodash/isUndefined';

@UntilDestroy()
@Component({
  selector: 'fnc-entity-properties',
  templateUrl: './entity-properties.component.html',
  styleUrls: ['./entity-properties.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntityPropertiesComponent implements OnInit {
  @Input() shippingInfo: ShippingInformation;
  @Input() billingInfo: ShippingInformation;
  @Input() vendorId: number;
  @Input() withTitle = false;
  @Input() firstAddressTitle = 'VENDORS.PROPERTIES.SHIP_TITLE';
  @Input() secondAddressTitle = 'VENDORS.PROPERTIES.BILL_TITLE';
  @Input() firstAddressType = AddressType.SHIPPING;
  @Input() secondAddressType = AddressType.BILLING;
  @Input() isEditableBillingAddress = false;
  @Input() isEditableShippingAddress = false;
  @Output() dataUpdate = new EventEmitter<AddressUpdateEvent>();

  addressTypes = AddressType;
  vendorData: Vendor;
  vendorLoaded = false;
  hasShipping = false;
  hasBilling = false;

  @Input('vendorId') get vendor() {
    return this.vendorId;
  }

  set vendor(id: number) {
    this.vendorId = id;
    this.getVendorData();
  }

  constructor(
    private readonly addressService: AddressPropertiesService,
    private readonly vendorQuery: VendorsQuery,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.hasShipping = !!this.addressService.getFormattedAddress(this.addressTypes.SHIPPING, this.shippingInfo);
    this.hasBilling = !!this.addressService.getFormattedAddress(this.addressTypes.BILLING, this.billingInfo);
  }

  hasBillingOrVendorInfo() {
    return this.hasBillingInfo() || this.hasVendorInfo();
  }

  hasVendorInfo() {
    return this.vendorLoaded === true;
  }

  hasContent() {
    return this.vendorLoaded || this.hasShipping || this.hasBilling;
  }

  hasMultipleBlocks() {
    const arr = [this.vendorLoaded, this.hasBilling, this.hasShipping];

    return arr.filter((el: boolean) => el === true).length > 1;
  }

  hasVendorId() {
    return !isUndefined(this.vendorId) && !!this.vendorId;
  }

  getAddressColumnNgClass() {
    let column: string;
    if ((this.hasShippingInfo() || this.hasBillingInfo()) && this.hasVendorInfo()) {
      column = 'm-col-xs4of12';
    } else if (this.hasShippingInfo() && this.hasBillingInfo()
      && !this.hasVendorInfo()) {
      column = 'm-col-xs6of12';
    } else {
      column = 'm-col-xs12of12';
    }

    return `${column} fnc-shipping-info-main`;
  }

  getVendorColumnNgClass() {
    let column: string;
    if (this.hasShippingInfo() && this.hasBillingInfo()) {
      column = 'm-col-xs4of12';
    } else if (this.hasShippingInfo() || this.hasBillingInfo()) {
      column = 'm-col-xs8of12';
    } else {
      column = 'm-col-xs12of12';
    }

    return column;
  }

  onDataUpdate(update: AddressUpdateEvent) {
    this.dataUpdate.emit(update);
  }

  private hasShippingInfo() {
    return this.hasShipping === true;
  }

  private hasBillingInfo() {
    return this.hasBilling === true;
  }

  private getVendorData() {
    this.vendorQuery.selectEntity(this.vendorId)
      .pipe(untilDestroyed(this))
      .subscribe((vendor: Vendor) => {
        this.vendorData = vendor;
        this.vendorLoaded = !!vendor;
        this.cdr.detectChanges();
      });
  }
}
