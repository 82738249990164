import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { SideMenuProtected } from '@fnc-shared/interfaces/side-menu.interface';
import { Path } from '@fnc-shared/constants/path.constant';
import { FilterNames } from '@fnc-shared/models/filter-names';
import { PaymentSystem } from '@fnc-app/modules/vendor-balances/pages/vendor-balances-list/payment-system.constant';
import { IsActiveMatchOptions } from '@angular/router';

const defaultActiveLinkOptions: IsActiveMatchOptions = {
  queryParams: 'ignored',
  matrixParams: 'exact',
  paths: 'exact',
  fragment: 'exact'
};

export const AccountingSideMenuItems: SideMenuProtected[] = [
  {
    title: _('NAVIGATION.GROUP.ACCOUNTING'),
    items: [
      {
        items: [
          {
            routerLink: ['/', Path.financePortal, Path.clearingOffice],
            title: _('NAVIGATION.ACCOUNTING.CLEARING_OFFICE'),
            rights: ['ROLE_CAN_VIEW_VENDOR_INVOICES']
          },
          {
            routerLink: ['/', Path.financePortal, Path.financeOffice],
            title: _('NAVIGATION.ACCOUNTING.FINANCE_OFFICE'),
            rights: ['ROLE_CAN_EXPORT_PAYMENTS']
          },
          {
            routerLink: ['/', Path.financePortal, Path.vendorBalances],
            title: _('NAVIGATION.ACCOUNTING.VENDOR_BALANCES'),
            rights: ['ROLE_CAN_VIEW_VENDOR_BALANCES'],
            queryParams: {
              [`_filters.${FilterNames.PAYMENT_SYSTEM}`]: PaymentSystem.SEPA
            },
            routerLinkActiveOptions: defaultActiveLinkOptions
          },
          {
            routerLink: ['/', Path.financePortal, Path.accountsReceivable],
            title: _('NAVIGATION.ACCOUNTING.ACCOUNTS_RECEIVABLE'),
            // TODO: add new permission
            rights: ['ROLE_CAN_VIEW_ACCOUNTS_PAYABLE']
          },
          {
            routerLink: ['/', Path.financePortal, Path.accountReports],
            title: _('NAVIGATION.ACCOUNTING.ACCOUNT_REPORTS'),
            rights: ['ROLE_CAN_VIEW_ACCOUNTS_PAYABLE']
          }
        ]
      }
    ]
  },
  {
    title: _('NAVIGATION.GROUP.DOCUMENTS'),
    items: [
      {
        title: _('NAVIGATION.GROUP.VENDOR'),
        items: [
          {
            routerLink: ['/', Path.financePortal, Path.vendorInvoices],
            title: _('NAVIGATION.ACCOUNTING.VENDOR_INVOICES'),
            rights: ['ROLE_CAN_VIEW_VENDOR_INVOICES']
          },
          {
            routerLink: ['/', Path.financePortal, Path.vendorNotes],
            title: _('NAVIGATION.ACCOUNTING.NOTES'),
            rights: ['ROLE_CAN_VIEW_VENDOR_INVOICES']
          },
          {
            routerLink: ['/', Path.financePortal, Path.paymentReminders],
            title: _('NAVIGATION.ACCOUNTING.PAYMENT_REMINDERS'),
            rights: ['ROLE_CAN_VIEW_PAYMENTS', 'FF_FP_PAYMENT_REMINDERS']
          },
          {
            routerLink: ['/', Path.financePortal, Path.payments],
            title: _('NAVIGATION.ACCOUNTING.PAYMENTS'),
            rights: ['ROLE_CAN_VIEW_VENDOR_INVOICES'],
            queryParams: {
              [`_filters.${FilterNames.PAYMENT_SYSTEM}`]: PaymentSystem.SEPA
            },
            routerLinkActiveOptions: defaultActiveLinkOptions
          }
        ]
      },
      {
        title: _('NAVIGATION.GROUP.BUYER'),
        items: [
          {
            routerLink: ['/', Path.financePortal, Path.buyerInvoices],
            title: _('NAVIGATION.ACCOUNTING.BUYER_INVOICES'),
            rights: ['ROLE_CAN_VIEW_BUYER_INVOICES']
          },
          {
            routerLink: ['/', Path.financePortal, Path.buyerNotes],
            title: _('NAVIGATION.ACCOUNTING.BUYER_NOTES'),
            rights: ['ROLE_CAN_VIEW_BUYER_NOTES']
          },
          {
            routerLink: ['/', Path.financePortal, Path.saleOrders],
            title: _('NAVIGATION.ACCOUNTING.SALE_ORDERS'),
            rights: ['ROLE_CAN_VIEW_SALE_ORDERS']
          }
        ]
      },
      {
        title: _('NAVIGATION.GROUP.OTHER'),
        items: [
          {
            routerLink: ['/', Path.financePortal, Path.deliveries],
            title: _('NAVIGATION.ACCOUNTING.DELIVERIES'),
            rights: ['ROLE_CAN_VIEW_GR_HEADERS']
          },
          {
            routerLink: ['/', Path.financePortal, Path.goodsReceipts],
            title: _('NAVIGATION.ACCOUNTING.GOOD_RECEIPTS'),
            rights: ['ROLE_CAN_VIEW_GR_HEADERS']
          },
          {
            routerLink: ['/', Path.financePortal, Path.fulfilmentOrders],
            title: _('NAVIGATION.ACCOUNTING.FULFILMENT_ORDERS'),
            rights: ['ROLE_CAN_VIEW_FORDERS']
          },
          {
            routerLink: ['/', Path.financePortal, Path.iwtInvoices],
            title: _('NAVIGATION.ACCOUNTING.IWT_INVOICES'),
            rights: ['ROLE_CAN_VIEW_IWT_INVOICES']
          }
        ]
      }
    ]
  }
];
