import { Route } from '@angular/router';
import { TranslationCoreModules } from '@fnc-app/routes/translation-modules';
import { InitGuard } from '@fnc-core/guards/init.guard';
import { Path } from '@fnc-shared/constants/path.constant';

export const HomeRoute: Route = {
  path: Path.home,
  canActivate: [InitGuard],
  loadChildren: () => import('../modules/home/home.module').then(m => m.HomeModule),
  data: {
    breadcrumb: 'NAVIGATION.MAIN.HOME',
    i18n: TranslationCoreModules
  }
};
