import { Inject, Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { WindowEvents } from './events';
import { WINDOW } from './window.provider';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  constructor(@Inject(WINDOW) public window: Window, private readonly router: Router) { }

  dispatchUIEvent(event: WindowEvents, bubbles = false, cancellable = false) {
    const uiEvent = this.window.document.createEvent('UIEvents');
    uiEvent.initEvent(event, bubbles, cancellable);
    this.window.dispatchEvent(uiEvent);
  }

  redirectTo(path: (string | number)[], navigationExtras?: NavigationExtras) {
    const pageUrl = this.router.createUrlTree(path, navigationExtras).toString();
    pageUrl && this.window.open(pageUrl);
  }
}
