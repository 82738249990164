import { FilterTypes } from './filter-types.constant';
import { FilterNames } from '@fnc-shared/models/filter-names';

export const regularFiltersMap = {
  [FilterNames.CLASSIFICATION]: FilterTypes.REGULAR,
  [FilterNames.COMMITMENT]: FilterTypes.REGULAR,
  [FilterNames.CREATED_BY]: FilterTypes.REGULAR,
  [FilterNames.DATE_VALUE]: FilterTypes.REGULAR,
  [FilterNames.DELIVERY_INVENTORY]: FilterTypes.REGULAR,
  [FilterNames.DOCUMENT_ID]: FilterTypes.REGULAR,
  [FilterNames.INVENTORY]: FilterTypes.REGULAR,
  [FilterNames.PLANNED_AT]: FilterTypes.REGULAR,
  [FilterNames.PROMOTION]: FilterTypes.REGULAR,
  [FilterNames.PROMOTION_STATUS]: FilterTypes.REGULAR,
  [FilterNames.SELL_OUT_IN]: FilterTypes.REGULAR,
  [FilterNames.VENDOR]: FilterTypes.REGULAR,
  [FilterNames.WAREHOUSE]: FilterTypes.REGULAR,
  [FilterNames.WAREHOUSE_ID_STOCK]: FilterTypes.REGULAR,
  [FilterNames.REPLACEMENT]: FilterTypes.REGULAR
};
