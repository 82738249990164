import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import dayjs from 'dayjs';
import { VatItem } from '@fnc-shared-fnc/constants/vendor-document-form.constant';

export function checkIfEmpty(control: AbstractControl) {
  if (!control.value.replace(/\s/g, '').length) {
    return { required: true };
  }

  return null;
}

export function isSameOrBeforeValidator(invoiceDateControl: AbstractControl, errorKey = 'wrong_invoice_delivery_date') {
  return (control: AbstractControl) => (invoiceDateControl.value < control.value ? { [errorKey]: true } : null);
}

export function isSameOrAfterValidator(invoiceDateControl: AbstractControl, errorKey = 'wrong_invoice_delivery_date') {
  return (control: AbstractControl) => (invoiceDateControl.value > control.value ? { [errorKey]: true } : null);
}

export function validateAllFormFields(formEl: FormGroup | FormArray) {
  Object.keys(formEl.controls).forEach(field => {
    const control = formEl.get(field);
    if (control instanceof FormControl) {
      control.markAsDirty({ onlySelf: true });
    }
    if (control instanceof FormGroup || control instanceof FormArray) {
      validateAllFormFields(control);
    }
    formEl.markAsDirty();
  });
}

export function validateDateFormat(control: AbstractControl) {
  if (!dayjs(control.value).isValid()) {
    return { invalid_date_format: true };
  }

  return null;
}

export function validateDateFormatAllowEmpty(control: AbstractControl) {
  if (control.value && !dayjs(control.value).isValid()) {
    return { invalid_date_format: true };
  }

  return null;
}

export function uniqVat(control: AbstractControl) {
  const value: VatItem[] = control.value;
  const vats = value.map(vatRow => vatRow.vat);
  const uniqVats = [...new Set(vats)];

  if (vats.length !== uniqVats.length) {
    return { uniq_vat: true };
  }

  return null;
}

export function validateNonEmptyArray(control: AbstractControl) {
  const value: unknown[] = control.value;

  if (value && Array.isArray(value) && value.length === 0) {
    return { non_empty_array: true };
  }

  return null;
}
