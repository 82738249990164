import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SingleFieldDefaultValue, SingleFieldType } from '@fnc-shared/components/single-field-form/single-field-form.constant';
import { Right } from '@mm-ui/core';

@Component({
  selector: 'fnc-single-field-form',
  templateUrl: 'single-field-form.component.html',
  styleUrls: ['single-field-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleFieldFormComponent implements OnInit {
  @Input() value: ExtPrimitive;
  @Input() field = 'value';
  @Input() label = '';
  @Input() mode: FormMode = 'view';
  @Input() validators = [Validators.required];
  @Input() validatorsData = {};
  @Input() readOnly = false;
  @Input() fieldType = SingleFieldType.NUMBER;
  @Input() tooltipText: string;
  @Input() permissions: Right | string = { $any: [''] };
  @Output() update = new EventEmitter<ExtPrimitive>();

  form: FormGroup;
  errorData = {};
  defaultValue: ExtPrimitive;
  readonly singleFieldType = SingleFieldType;

  constructor(private readonly formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.defaultValue = SingleFieldDefaultValue[this.fieldType];
    this.errorData = { name: this.label, ...this.validatorsData };
    const defaultValue = this.value ?? this.defaultValue;
    this.form = this.formBuilder.group({
      [this.field]: [defaultValue, { validators: this.validators }]
    });
  }

  changeMode(mode: FormMode = 'edit') {
    this.mode = mode;
    this.resetFieldValue();
  }

  onApply() {
    const value = this.form.value[this.field];
    this.update.emit(value || this.defaultValue);
    this.mode = 'view';
  }

  private resetFieldValue() {
    if (this.mode === 'view') {
      this.form.setValue({ [this.field]: this.value ?? this.defaultValue });
    }
  }
}

type FormMode = 'edit' | 'view';
