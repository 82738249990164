export const environment = {
  authBasePath: 'MM_AUTH_API_BASE_URL',
  baseApiPath: 'MM_APP_API_BASE_URL',
  buyerReturnsBaseBath: 'MM_APP_BUYER_RETURNS_API_BASE_URL',
  cloudEnv: 'CLOUD_ENV',
  configurationBasePath: 'MM_CONFIGURATION_API_BASE_URL',
  dbVersion: 1,
  deliveryServiceBasePath: 'MM_DELIVERY_SERVICE_API_BASE_URL',
  demandPlanningApiPath: 'MM_APP_DEMAND_PLANNING_API_BASE_URL',
  offerReactorApiPath: 'MM_OFFER_REACTOR_API_BASE_URL',
  offerExportApiPath: 'MM_OFFER_EXPORT_API_BASE_URL',
  offerFunnelApiPath: 'MM_OFFER_FUNNEL_API_BASE_URL',
  offerXKApiPath: 'MM_OFFER_XK_API_BASE_URL',
  gaTrackingId: 'GA_TRACKING_ID',
  googleTagManagerId: 'GOOGLE_TAG_MANAGER_ID',
  googleTagManagerUserId: 'GOOGLE_TAG_MANAGER_USER_ID',
  helpCenterPath: 'APP_URL_HELPCENTER_UI/',
  hotjarSiteId: 'HOTJAR_SITE_ID',
  inventoryServiceBasePath: 'MM_INVENTORY_SERVICE_API_BASE_URL',
  inventoryUnitsBasePath: 'MM_SMS_APP_URL',
  inventoryUnitsBasePathV3: 'MM_SMS_APP_URL',
  production: true,
  sentryDsn: 'SENTRY_DSN',
  shiptrackBasePath: 'MM_SHIPTRACK_API_BASE_URL',
  smsAppPath: 'MM_SMS_APP_URL',
  vendorBasePath: 'MM_SMS_APP_URL',
  vendorPortalPath: 'APP_URL_VENDOR_PORTAL_UI/',
  version: 'MM_VERSION',
  productDataEnhancementBaseBath: 'MM_PDE_API_BASE_URL',
  translationServiceBaseBath: 'MM_TRANSLATION_SERVICE_API_BASE_URL',
  pimUploadServiceBaseBath: 'MM_PIM_UPLOAD_SERVICE_API_BASE_URL'
};
