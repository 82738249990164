<button
  mmButton
  class="fnc-export-button"
  size="small"
  icon="upload"
  kind="secondary"
  [label]="label"
  (click)="onExport()"
  [tooltip]="'COMMON.FILTERS.EXPORT_TOOLTIP' | translate"
  container="body"
  [containerClass]="tooltipClass"
  placement="bottom"
  triggers="mouseenter:mouseleave"
  (onShown)="onShown()"
  [waiting]="isLoading"
></button>
