import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UiLoaderStore } from '@fnc-core/services/ui/state/ui-loader.store';
import { UiLoaderState, UiLoaderType } from '@fnc-core/services/ui/state/ui.model';

@Injectable({ providedIn: 'root' })
export class UiLoaderQuery extends Query<UiLoaderState> {
  global$: Observable<boolean>;
  mainPage$: Observable<boolean>;
  mainList$: Observable<boolean>;

  constructor(protected store: UiLoaderStore) {
    super(store);
    this.global$ = this.select(UiLoaderType.GLOBAL).pipe(map(response => !!response));
    this.mainPage$ = this.select(UiLoaderType.MAIN_PAGE).pipe(map(response => !!response));
    this.mainList$ = this.select(UiLoaderType.MAIN_LIST).pipe(map(response => !!response));
  }
}
