import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isNull } from 'lodash';
import { environment } from '@fnc-env/environment';
import { WindowWithDataLayer } from '@fnc-core/services/google-analytics/google-analytics.interfaces';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AnalyticsSettingsService {
  targetAttr = 'src';
  fileName = 'gtm.js';

  loadGtmByDefault() {
    if (!environment.googleTagManagerId) {
      return;
    }

    this.loadGtm(window as WindowWithDataLayer, document, 'script', 'dataLayer', environment.googleTagManagerId);
  }

  loadGtm(window: WindowWithDataLayer, document: Document, s: string, l: string, i: string) {
    const scriptsLoaded = this.getScriptsLoaded();
    const gtmScriptPresent = this.getGtmScriptPresent(scriptsLoaded);

    if (gtmScriptPresent) {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = document.getElementsByTagName(s)[0];
    const j = document.createElement(s) as HTMLElement & { async: boolean; src: string };
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode?.insertBefore(j, f);
  }

  private getGtmScript(scriptsLoaded: HTMLScriptElement[]) {
    return scriptsLoaded.filter(
      item => this.getTargetAttribute(item, this.targetAttr) && this.isFileNameInTargetAttribute(item, this.targetAttr, this.fileName)
    );
  }

  private isFileNameInTargetAttribute(item: HTMLScriptElement, targetAttr: string, fileName: string) {
    const attribute = item.getAttribute(targetAttr);

    return attribute ? attribute.includes(fileName) : false;
  }

  private getTargetAttribute(item: HTMLScriptElement, targetAttr: string) {
    const attribute = item.getAttribute(targetAttr);

    return !isNull(attribute);
  }

  private getScriptsLoaded() {
    return Array.from(document.getElementsByTagName('script'));
  }

  private getGtmScriptPresent(scriptsLoaded: HTMLScriptElement[]) {
    return this.getGtmScript(scriptsLoaded).length > 0;
  }
}
