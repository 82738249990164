import { Component } from '@angular/core';
import { AccountingPageComponent } from '@fnc-core/components/accounting-page/accounting-page.component';
import { SettingsSideMenuItems } from '@fnc-core/components/settings-page/settings.constant';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  templateUrl: './settings-page.component.html'
})

export class SettingsPageComponent extends AccountingPageComponent {
  sideMenu = SettingsSideMenuItems;
}
