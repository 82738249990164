import { Route } from '@angular/router';
import { TranslationForbiddenModules } from '@fnc-app/routes/translation-modules';
import { NoPermissionsPageComponent } from '@fnc-core/components/no-permissions-page/no-permissions-page.component';
import { InitGuard } from '@fnc-core/guards/init.guard';
import { Path } from '@fnc-shared/constants/path.constant';

export const ForbiddenRoute: Route = {
  path: Path.forbidden,
  canActivate: [InitGuard],
  component: NoPermissionsPageComponent,
  data: { i18n: TranslationForbiddenModules }
};
