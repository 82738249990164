import { Color, ColorDegree, shade, tint } from '@fnc-shared/constants/color';

export const numberValueClass = {
  positive: shade(Color.GREEN, ColorDegree.L20),
  negative: shade(Color.RED, ColorDegree.L20)
};

export const colors = {
  dueDateClose: shade(Color.RED, ColorDegree.L20),
  paid: shade(Color.GREEN, ColorDegree.L20),
  invalidTotal: shade(Color.RED, ColorDegree.L20),
  unavailableTotal: tint(Color.GREY, ColorDegree.L60),
  forClarificationInvoice: Color.RED,
  invoiceLabel: tint(Color.PURPLE, ColorDegree.L90),
  goodsReceiptLabel: tint(Color.GREEN, ColorDegree.L90),
  fulfilmentOrderLabel: tint(Color.BLUE, ColorDegree.L90),
  reasonAlertDefault: tint(Color.GREEN, ColorDegree.L80),
  reasonAlertDeclined: tint(Color.RED, ColorDegree.L80),
  discountsLabel: tint(Color.GREEN, ColorDegree.L90),
  alertMessage: tint(Color.RED, ColorDegree.L90)
};
