import { Validators } from '@angular/forms';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { ShippingInformation } from '@fnc-app/modules/fulfilment-orders/models/orders.interface';

export interface AddressBlock {
  label?: string;
  info?: string[];
}

export interface AddressControlConfig {
  controlName: string;
  label: string;
  initialValue?: string;
  validators?: Validators[];
  filterFn?: (addressType: AddressType, info: ShippingInformation) => boolean;
  valueGetter: (info: ShippingInformation) => string;
}

export interface AddressUpdateEvent {
  type: AddressType;
  data: RecordString;
}

export enum AddressType {
  SHIPPING = 'SHIPPING',
  BILLING = 'BILLING'
}

export const AddressLabels = {
  SHIPPING: _('VENDORS.PROPERTIES.SHIPPING_ADDRESS'),
  BILLING: _('VENDORS.PROPERTIES.BILLING_ADDRESS')
};
