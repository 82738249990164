import { ChangeDetectorRef, Component, TemplateRef } from '@angular/core';
import { Cell } from '@fnc-shared/components/cells/cell';

@Component({
  template: ` <ng-template [fncTemplateRequestor]="component" let-value="value">
    <div *ngIf="showDash">{{ value | mmAddDash }}</div>
    <div *ngIf="!showDash">{{ value }}</div>
  </ng-template>`
})
export class CellComponent implements Cell {
  templateRef: TemplateRef<ImplicitAny>;
  component = this;
  showDash = true;

  constructor(private readonly cdr?: ChangeDetectorRef) {}

  detectChanges() {
    this.cdr?.detectChanges();
  }
}
