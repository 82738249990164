<ng-template #infoModal>
  <mm-modal compact>
    <mm-modal-header>
      <mm-bar>
        <mm-bar-item>
          {{ title | translate }}
        </mm-bar-item>
        <mm-bar-item>
          <mm-link-icon kind="close" color="blue" (click)="modalRef.hide()"></mm-link-icon>
        </mm-bar-item>
      </mm-bar>
    </mm-modal-header>
    <mm-modal-content [isPadded]="false">
      <ng-content> </ng-content>
    </mm-modal-content>
    <mm-modal-footer>
      <button type="button" mmButton size="small" kind="primaryRaised" (click)="hide()" *ngIf="!footerTpl">
        {{ 'COMMON.INFO_WINDOW.OK' | translate }}
      </button>
      <ng-container [ngTemplateOutlet]="footerTpl"></ng-container>
    </mm-modal-footer>
  </mm-modal>
</ng-template>
