import { MessageRenderer } from './migrations/failed-message-renderer.interface';

export interface DbStoreDescription {
  name: string;
  keyPath: string | string[];
}

export enum DbStore {
  FAILED_MESSAGES_RENDERERS = 'failedMessageRenderers',
  FAILED_MESSAGES_RENDERER_APPLIED = 'failedMessageRendererApplied'
}

export const storeDescriptions: DbStoreDescription[] = [
  {
    name: DbStore.FAILED_MESSAGES_RENDERERS,
    keyPath: ['sourceId', 'functionName']
  },
  {
    name: DbStore.FAILED_MESSAGES_RENDERER_APPLIED,
    keyPath: ['sourceId', 'functionName']
  }
];

export type DbMigration = Record<DbStore, MessageRenderer[]>;
