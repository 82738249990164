import { Injectable } from '@angular/core';
import { AlertType, FiltersService, MmAlertService } from '@mm-ui/components';
import { FilterChange } from '@mm-ui/components/lib/components/filters/types/filters.interface';
import { ApiService, Notification, NotificationClientService, NotificationSeverity, QueryParamsService } from '@mm-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { NotificationChannel, NotificationChannelName } from '@fnc-core/services/notification-events/notification-events.constant';
import { url } from '@fnc-core/services/notification-events/notification-events.url';
import { NotificationStore } from '@fnc-core/services/notification-events/state';
import { UiService } from '@fnc-core/services/ui/state/ui.service';
import { ALERT_GLOBAL } from '@fnc-shared/constants/alert-config.constant';
import { COMMON_HIDE_TIME } from '@fnc-shared/constants/alert-hide-time.constant';
import { PageConfig } from '@fnc-shared/models/page-config';
import { BaseListService } from '@fnc-shared/services/base-list.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationEventsService extends BaseListService<Notification<NotificationChannel>> {
  unread$ = new BehaviorSubject(0);
  private readonly channels = [NotificationChannelName.FINANCE_REPORTS, NotificationChannelName.SYSTEM, NotificationChannelName.PERSONAL];

  constructor(
    private readonly notificationClientService: NotificationClientService<NotificationChannel>,
    private readonly mmAlertService: MmAlertService,
    private readonly translateService: TranslateService,
    private readonly apiService: ApiService,
    private readonly userService: UserService,
    protected notificationStore: NotificationStore,
    protected uiService: UiService,
    protected queryParamsService: QueryParamsService,
    protected filtersService: FiltersService
  ) {
    super(apiService, notificationStore, uiService, queryParamsService, filtersService);
    this.urlList = url.notifications;
    this.urlExport = url.notifications;
    this.urlImportance = url.setImportance;
  }

  get(pageConfig: PageConfig, filters: FilterChange[] = [], options = {}, extData: RecordNested = {}) {
    return super.get(pageConfig, filters, options, { ...extData, channel: this.channels.join(',') }).pipe(
      tap(response => {
        if (response?.extra?.unread) {
          this.unread$.next(response?.extra?.unread as number);
        }
      })
    );
  }

  setAsRead(id: string) {
    this.apiService.put(url.setState, { id }, { read: true }).subscribe();
  }

  subscribe() {
    this.notificationClientService.pollingOptions.interval = 10000;

    this.userService.isLoggedIn$
      .pipe(
        filter(isLoggedIn => isLoggedIn),
        switchMap(() => this.get({ page: 1, limit: 1 })),
        switchMap(() => this.notificationClientService.listen(url.channels, { ignoreBasePath: false }))
      )
      .pipe(
        tap(notifications =>
          notifications.forEach(
            notification => this.channels.includes(notification.channel as NotificationChannelName) && this.showNotification(notification)
          )
        )
      )
      .subscribe();
  }

  showNotification(notification: Notification<NotificationChannel>) {
    if (notification.message) {
      this.notificationAlertMessage(notification.message, notification.parameters, this.severityToType(notification.severity));
      this.updateRow(notification.id, { ...notification, isRead: true });
      this.setAsRead(notification.id);
    }
  }

  severityToType(severity: NotificationSeverity) {
    switch (severity) {
      case NotificationSeverity.CRITICAL:
      case NotificationSeverity.MAJOR:
        return 'error';
      case NotificationSeverity.MINOR:
      case NotificationSeverity.LOW:
        return 'warning';
      default:
        return 'success';
    }
  }

  private notificationAlertMessage(
    messageKey: string,
    data: RecordOf<ExtPrimitive>,
    type: AlertType = 'success',
    autoHideTime = COMMON_HIDE_TIME
  ) {
    this.translateService.get(messageKey, data).subscribe((translate: string) => {
      this.mmAlertService.push(ALERT_GLOBAL, {
        autoHideTime,
        type,
        alertDescription: translate,
        alertTitle: ' '
      });
    });
  }
}
