import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccountingPageComponent } from './components/accounting-page/accounting-page.component';
import { DashboardAccountingComponent } from './components/dashboard-accounting/dashboard-accounting.component';
import { OfferPageComponent } from './components/offer-page/offer-page.component';
import { DashboardOfferComponent } from './components/dashboard-offer/dashboard-offer.component';
import { AdminPageComponent } from '@fnc-core/components/admin-page/admin-page.component';
import { DashboardAdminComponent } from '@fnc-core/components/dashboard-admin/dashboard-admin.component';
import { DashboardFulfilmentComponent } from '@fnc-core/components/dashboard-fulfilment/dashboard-fulfilment.component';
import { DashboardReportsComponent } from '@fnc-core/components/dashboard-reports/dashboard-reports.component';
import { DashboardSettingsComponent } from '@fnc-core/components/dashboard-settings/dashboard-settings.component';
import { FulfilmentPageComponent } from '@fnc-core/components/fulfilment-page/fulfilment-page.component';
import { NoPermissionsPageComponent } from '@fnc-core/components/no-permissions-page/no-permissions-page.component';
import { ReportsPageComponent } from '@fnc-core/components/reports-page/reports-page.component';
import { SettingsPageComponent } from '@fnc-core/components/settings-page/settings-page.component';
import { SigninPageComponent } from '@fnc-core/components/signin-page/signin-page.component';
import { SupplyChainPageComponent } from '@fnc-core/components/supply-chain-page/supply-chain-page.component';
import { InitGuard } from '@fnc-core/guards/init.guard';
import { CustomInterceptor } from '@fnc-core/services/custom-interceptor/custom-interceptor.service';
import { SharedModule } from '@fnc-shared/shared.module';
import { DashboardProductDataComponent } from '@fnc-core/components/dashboard-product-data/dashboard-product-data.component';
import { ProductDataPageComponent } from '@fnc-core/components/product-data-page/product-data-page.component';
import { InstallationServicesPageComponent } from '@fnc-core/components/installation-services-page/installation-service-page.component';

@NgModule({
  imports: [RouterModule, SharedModule],
  declarations: [
    AccountingPageComponent,
    DashboardAccountingComponent,
    NoPermissionsPageComponent,
    SigninPageComponent,
    SupplyChainPageComponent,
    ReportsPageComponent,
    DashboardReportsComponent,
    SettingsPageComponent,
    DashboardSettingsComponent,
    FulfilmentPageComponent,
    InstallationServicesPageComponent,
    DashboardFulfilmentComponent,
    AdminPageComponent,
    DashboardAdminComponent,
    OfferPageComponent,
    DashboardOfferComponent,
    DashboardProductDataComponent,
    ProductDataPageComponent
  ],
  providers: [
    InitGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {}
