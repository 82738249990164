import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { SideMenuProtected } from '@fnc-shared/interfaces/side-menu.interface';
import { Path } from '@fnc-shared/constants/path.constant';

export const ProductDataSideMenuItems: SideMenuProtected[] = [
  {
    title: _('NAVIGATION.MAIN.PRODUCT_DATA'),
    items: [
      {
        items: [
          {
            routerLink: ['/', Path.productData, Path.attributes],
            title: _('NAVIGATION.PRODUCT_DATA.ATTRIBUTES_MANAGEMENT'),
            rights: { $or: ['ROLE_ADMIN', 'ROLE_CAN_MANAGE_ERP_ATTRIBUTES'] }
          }
        ]
      }
    ]
  }
];
