import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@fnc-env/environment';
import { PermissionsService } from '@mm-ui/core';

@Injectable({ providedIn: 'root' })
export class HotjarService {
  private isAdded = false;

  constructor(
    private readonly permissionsService: PermissionsService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  addScript() {
    const isHotjarEnabled = this.permissionsService.isPermitted('FF_FP_HOTJAR_ENABLED');
    if (!environment.hotjarSiteId || !isHotjarEnabled || this.isAdded) {
      return;
    }

    const hotjarScript = this.document.createElement('script');
    hotjarScript.append(`
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${environment.hotjarSiteId},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `);
    this.document.head.prepend(hotjarScript);
    this.isAdded = true;
  }
}
