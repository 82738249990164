import { IconType } from '@mm-ui/components/lib/components/icon/icon.type';
import { Right } from '@mm-ui/core';
import { PredefinedReasonConfig } from '@fnc-shared-fnc/components/modal-conflicts-confirm/predefined-reasons-config.model';

export enum ActionsStyle {
  LINK = 'link',
  BUTTON = 'button'
}

export enum ActionType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary'
}

export interface ActionsMenuElement {
  cmd: string;
  params: ActionsParameters;
  forStatus?: string[];
  label: string;
  title?: string;
  disabled?: boolean;
  rights?: string | string[] | Right;
  sectionNumber?: number;
  buttonLabel?: string;
  buttonType?: string;
  buttonIcon?: IconType;
  buttonIconOrder?: 'left' | 'right';
  class?: string;
}

export type ActionsParameters = RecordOf<RecordNested | ExtPrimitive | ExtPrimitive[] | PredefinedReasonConfig[]>;

export interface KebabMenuActionGroups {
  primary: ActionsMenuElement[];
  secondary: ActionsMenuElement[];
  tertiary: ActionsMenuElement[];
}
