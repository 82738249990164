import { Component } from '@angular/core';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';

@Component({
  template: ` <ng-template [fncTemplateRequestor]="component" let-value="value">
    {{ value | fncFormatNumber | mmAddDash }}
  </ng-template>`
})
export class NumberDashedCellComponent extends CellComponent {
  constructor() {
    super();
  }
}
