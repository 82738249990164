import { Component } from '@angular/core';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';

@Component({
  template: ` <ng-template [fncTemplateRequestor]="component" let-value="value" let-row="row">
    <fnc-product-popup *ngIf="value; else noGtin" [gtin]="row.gtin" [sku]="row.sku">
      <span class="fnc-product-popup-title--dotted">
        {{ value | mmAddDash }}
      </span>
    </fnc-product-popup>

    <ng-template #noGtin>
      {{ value | mmAddDash }}
    </ng-template>
  </ng-template>`
})
export class GtinCellComponent extends CellComponent {}
