import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { SideMenuProtected } from '@fnc-shared/interfaces/side-menu.interface';
import { Path } from '@fnc-shared/constants/path.constant';

export const InstallationServiceSideMenuItems: SideMenuProtected[] = [
  {
    title: _('NAVIGATION.MAIN.INSTALLATION_SERVICES'),
    items: [
      {
        items: [
          {
            routerLink: ['/', Path.installationServices, Path.services],
            title: _('NAVIGATION.INSTALLATION_SERVICES.SERVICES'),
            rights: 'ROLE_CAN_MANAGE_INVENTORIES'
          },
          {
            routerLink: ['/', Path.installationServices, Path.partners],
            title: _('NAVIGATION.INSTALLATION_SERVICES.PARTNERS'),
            rights: 'ROLE_CAN_MANAGE_INVENTORIES'
          }
        ]
      }
    ]
  }
];
