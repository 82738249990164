import { Directive, HostBinding, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { numberValueClass } from '@fnc-shared/constants/style-classes.constant';
import { pushUnique } from '@mm-ui/components';
import isNil from 'lodash/isNil';

@Directive({
  selector: '[fncNumberValueClass]'
})
export class NumberValueClassDirective implements OnInit, OnChanges {
  @Input() value: WeakNumber;
  @Input() option: numberValueOptions;
  @Input() cssClasses = numberValueClass;

  @HostBinding('class')
  @Input('class')
  get elementClass(): string {
    return this.elementClassValue.join(' ');
  }

  set elementClass(val: string) {
    this.elementClassValue = val.split(' ');
    this.elementClassValueBase = this.elementClassValue;
  }

  private elementClassValue: string[] = [];
  private elementClassValueBase: string[] = [];

  ngOnInit() {
    this.getClass(this.value, this.option);
  }

  ngOnChanges(changes: RecordOf<SimpleChange>) {
    const { value, option, elementClass } = changes;

    if ([value, option, elementClass].some(item => item?.previousValue !== item?.currentValue)) {
      this.getClass(this.value, this.option);
    }
  }

  getClass(value: WeakNumber, option?: string) {
    if (isNil(value) || value === '') {
      return;
    }

    const numberValue = parseFloat(value.toString());
    this.elementClassValue = this.elementClassValueBase;

    if (option === 'positive') {
      this.elementClassValue = pushUnique(this.elementClassValue, this.cssClasses.positive);

      return;
    }

    if (option === 'negative') {
      this.elementClassValue = pushUnique(this.elementClassValue, this.cssClasses.negative);

      return;
    }

    if (numberValue === 0 || numberValue > 0) {
      this.elementClassValue = pushUnique(this.elementClassValue, this.cssClasses.positive);

      return;
    }

    this.elementClassValue = pushUnique(this.elementClassValue, this.cssClasses.negative);
  }
}

export type numberValueOptions = 'positive' | 'negative';
