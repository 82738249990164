import { Injectable } from '@angular/core';
import { environment } from '@fnc-env/environment';
import { FileTypes } from '@fnc-shared/components/file-tile/file-tile.constant';
import { FileActions, UploadedFile } from '@fnc-shared/components/modal-file-manager-window/file-manager.constant';
import { URLS } from '@fnc-shared/components/modal-file-manager-window/file-manager.url';
import { HttpResponseHandlerService } from '@fnc-shared/services/http-response-handler.service';
import { ApiService } from '@mm-ui/core';
import { ErrorResponseItem } from '@mm-ui/core/lib/services/api/error-response.interface';
import { HttpErrorExtResponse } from '@mm-ui/core/lib/services/api/http-error-ext-response.interface';
import { Subject } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileManageService {
  fileManagerSuccess: Subject<boolean> = new Subject();
  private readonly defaultPreviewSuffix = '/view';

  constructor(
    private readonly api: ApiService,
    private readonly responseHandler: HttpResponseHandlerService
  ) { }

  replaceFile(documentId: number, filesToAdd: UploadedFile[], filesToRemove: UploadedFile[], fileType: FileTypes) {
    return this.deleteFile(documentId, fileType, filesToRemove[0], FileActions.REPLACE).pipe(
      switchMap(() => this.uploadFiles(documentId, filesToAdd, fileType, FileActions.REPLACE)),
      tap(() => this.responseHandler.successAlert('COMMON.FILE_UPLOADER.FILE_UPLOAD_SUCESS', filesToAdd[0]))
    );
  }

  uploadFiles(documentId: number, files: UploadedFile[], fileType: FileTypes, actionType: FileActions = FileActions.UPLOAD) {
    return this.api
      .post(URLS[`${FileActions.UPLOAD}_${fileType}`], { documentId }, { file: files[0] }, null, { isFormData: true }).pipe(
        catchError((error: HttpErrorExtResponse) => {
          const errorResponse = { ...error, fileAction: FileActions.UPLOAD };

          return this.responseHandler.catchError(errorResponse, files[0]);
        }),
        tap(() => {
          if (actionType === FileActions.UPLOAD) {
            this.responseHandler.successAlert('COMMON.FILE_UPLOADER.FILE_UPLOAD_SUCESS', files[0]);
          }
        })
      );
  }

  deleteFile(documentId: number, fileType: FileTypes, file: UploadedFile, actionType: FileActions = FileActions.DELETE) {
    return this.api
      .delete(URLS[`${FileActions.DELETE}_${fileType}`], { documentId, fileId: file.id }, null).pipe(
        catchError((error: HttpErrorExtResponse) => {
          const errorResponse = { ...error, fileAction: FileActions.DELETE };

          return this.responseHandler.catchError(errorResponse, file);
        }),
        tap(() => {
          if (actionType === FileActions.DELETE) {
            this.responseHandler.successAlert('COMMON.FILE_UPLOADER.FILE_DELETE_SUCESS', file);
          }
        })
      );
  }

  getFileUrl(
    documentId: number | string,
    file: Partial<UploadedFile>,
    fileType: FileTypes,
    fileAction: FileActions = FileActions.DOWNLOAD
  ) {
    const urlData = URLS[`${FileActions.DOWNLOAD}_${fileType}`];
    const basePath = urlData?.parameters?.baseApiPath as string ?? environment.baseApiPath;
    const baseUrl = ApiService.applyURLParameters(urlData.url, { documentId }, true);
    const url = [`${basePath}${baseUrl}`];
    const fileId = urlData?.parameters?.useFileName ? file.name : file.id;

    if (fileId) {
      url.push(`/${fileId}`);
    }

    if (fileAction === FileActions.OPEN) {
      const mode = urlData?.parameters?.previewSuffix ?? this.defaultPreviewSuffix;
      url.push(mode as string);
    }

    return url.join('');
  }

  getFileLoadingErrorKey({ error }: ErrorResponseItem) {
    let errorKey: string;
    switch (error) {
      case 'type':
        errorKey = 'INVALID_MIME_TYPE_ERROR';
        break;
      case 'size':
        errorKey = 'TOO_LARGE_ERROR';
        break;
      default:
        errorKey = 'COMMON_READING_FILE_ERROR';
    }

    return errorKey;
  }
}
