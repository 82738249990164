import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { akitaDevtools } from '@datorama/akita';
import { MmDatepickerService, MmModalService } from '@mm-ui/components';
import {
  DictionaryService,
  FeatureFlagsService,
  getUrlParameter,
  LocalizationResponse,
  LocalizationService,
  PermissionsService,
  QueryParamsConfig,
  SentryService
} from '@mm-ui/core';
import { Subject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { VendorsService } from '../vendors/state';
import { HotjarService } from '@fnc-core/services/hotjar/hotjar.service';
import { Angulartics2Metrics } from '@fnc-core/services/metrics/metrics.service';
import { NotificationEventsService } from '@fnc-core/services/notification-events/notification-events.service';
import { RefreshService } from '@fnc-core/services/refresh/state/refresh.service';
import { SettingsService } from '@fnc-core/services/settings/settings.service';
import { UrlQueryService } from '@fnc-core/services/url-query/url-query.service';
import { UserService } from '@fnc-core/services/user/user.service';
import { environment } from '@fnc-env/environment';
import { DictionaryUrl } from '@fnc-shared/constants/dictionary-url.constant';
import { DisabledFeaturesService } from '@fnc-shared/services/disabled-features/disabled-features.service';

@Injectable({ providedIn: 'root' })
export class AppService {
  get popstateTrigger$() {
    return this.popstateTrigger.asObservable();
  }

  private locale: string | undefined;
  private readonly popstateTrigger = new Subject();
  private readonly appStartTimestamp = Date.now();
  private readonly baseLanguageModules = ['common'];

  constructor(
    private readonly ngZone: NgZone,
    private readonly localization: LocalizationService,
    private readonly user: UserService,
    private readonly permissions: PermissionsService,
    private readonly settings: SettingsService,
    private readonly featureFlags: FeatureFlagsService,
    private readonly dp: MmDatepickerService,
    private readonly refreshService: RefreshService,
    private readonly urlQuery: UrlQueryService,
    private readonly modalService: MmModalService,
    private readonly metrics: Angulartics2Metrics,
    private readonly notificationEventsService: NotificationEventsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly disabledFeaturesService: DisabledFeaturesService,
    private readonly dictionaryService: DictionaryService,
    private readonly vendorsService: VendorsService,
    private readonly hotjar: HotjarService,
    private readonly sentryService: SentryService
  ) {
    this.initializeApplication();
  }

  routerInterceptor(event: Event) {
    if (this.isPageLoadingStarted(event)) {
      this.triggerPopstate(event);
      this.refreshService.refreshPage(event);
      const timeStamp = { t_: this.appStartTimestamp };
      this.user
        .getProfile(timeStamp)
        .pipe(
          mergeMap(response => {
            this.locale = getUrlParameter('locale') || response.locale || 'en';
            this.sentryService.setTag('locale', this.locale);
            this.dp.setDatePickerLocale(this.locale);

            return this.settings.getFeatureFlags(timeStamp);
          })
        )
        .subscribe(() => {
          this.settingPermissions();
          this.hotjar.addScript(); // TODO: when hotjar feature flag is removed it can be moved to app.component
          this.permissions.isPermitted('ROLE_CAN_VIEW_VENDORS') && this.vendorsService.subscribeToVendorsList();
        });
    }

    if (this.isPageLoadingEnded(event)) {
      this.refreshService.finishPageRefresh();
    }
  }

  loadTranslations(locale: string, modules: string[] = []) {
    const timeStamp = { t_: this.appStartTimestamp };
    const currentLocale = locale ? locale : this.locale ?? this.localization.currentLanguage;

    return this.localization
      .loadLanguage(currentLocale, timeStamp, [...modules.map(module => module.toLowerCase()), ...this.baseLanguageModules])
      .pipe(tap(i18n => this.settingLocalization(i18n)));
  }

  getQueryMapParams() {
    const root: ActivatedRoute = this.activatedRoute.root;
    const children: ActivatedRoute[] = root.children;
    const childWithQueryConfig = this.getLastParent(children).find((child: ActivatedRoute) => child.snapshot.data.queryMapConfig);

    return childWithQueryConfig ? (childWithQueryConfig?.snapshot.data.queryMapConfig as QueryParamsConfig) : undefined;
  }

  private getLastParent(children: ActivatedRoute[]): ActivatedRoute[] {
    for (const child of children) {
      return child.children.length ? this.getLastParent(child.children) : children;
    }

    return [];
  }

  private triggerPopstate(event: NavigationStart) {
    if (event.navigationTrigger === 'popstate') {
      this.popstateTrigger.next();
    }
  }

  private settingLocalization(i18n: LocalizationResponse) {
    this.settings.locale = { ...i18n.localeData };
    SettingsService.initialized();
    LocalizationService.initialized.next(true);
    this.disabledFeaturesService.showAlerts();
  }

  private settingDictionary() {
    this.dictionaryService.baseApiPath = environment.baseApiPath;
    this.dictionaryService.url = DictionaryUrl;
  }

  private settingPermissions() {
    const disabledFeatures = this.disabledFeaturesService.flags;
    this.user.profile?.roles != null && UserService.initialized();
    this.permissions.rightsList = this.user.profile?.roles ?? [];
    this.featureFlags.setFlags({ ...this.settings.featureFlags, ...disabledFeatures });
  }

  private initializeApplication() {
    if (!environment.production) {
      akitaDevtools(this.ngZone);
    }

    this.metrics.startTracking();
    this.modalService.config.animated = false;
    LocalizationService.supportedLanguages = ['en', 'de'];
    this.settingDictionary();

    registerLocaleData(localeDe, 'de');
    registerLocaleData(localeEn, 'en');

    this.urlQuery.subscribe();

    this.notificationEventsService.subscribe();
  }

  private isPageLoadingStarted(event: Event): event is NavigationStart {
    return event instanceof NavigationStart;
  }

  private isPageLoadingEnded(event: Event): boolean {
    return event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError;
  }
}
