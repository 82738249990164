<form [formGroup]="form" (ngSubmit)="onSubmit(form)" class="fnc-modal-confirm-window">
  <div mmDropZone (filesDropped$)="onFilesDropped($event)">
    <mm-modal compact>
      <mm-modal-header>
        <mm-bar>
          <mm-bar-item>
            {{ title | translate }}
          </mm-bar-item>
          <mm-bar-item>
            <mm-link-icon kind="close" color="blue" (click)="hide()"></mm-link-icon>
          </mm-bar-item>
        </mm-bar>
      </mm-modal-header>
      <mm-modal-content>
        <mm-form-field [control]="form.get('files')" errorMessagePrefix="COMMON.FILE_UPLOADER">
          <mm-label class="mm-label" for="files"> {{ label | translate }} </mm-label>

          <mm-uploader
            formControlName="files"
            [files]="filesDropped"
            [defaultView]="true"
            placeholder="{{ 'COMMON.FILE_UPLOADER.PLACEHOLDER' | translate }}"
            [multiple]="false"
            [accept]="allowedFileTypes"
            (filesUploaded$)="onFilesUploaded()"
            (filesSelected$)="onFilesSelected()"
            (errorOccured$)="errorOccurred($event)"
            [maxFileSize]="maxFileSize"
          >
          </mm-uploader>
        </mm-form-field>
        <mm-file-preview
          [classList]="'fnc-file-preview'"
          [fileList]="this.form.get('files').value"
          (removeFile$)="onFileRemove($event)"
        ></mm-file-preview>
      </mm-modal-content>
      <mm-modal-footer>
        <button type="submit" mmButton size="small" kind="primaryRaised" [disabled]="!canFormBeSubmitted()">
          {{ 'COMMON.CONFIRM_WINDOW.CONFIRM' | translate }}
        </button>
        &nbsp;
        <button type="button" mmButton size="small" kind="secondary" (click)="hide()">
          {{ 'COMMON.CONFIRM_WINDOW.CANCEL' | translate }}
        </button>
      </mm-modal-footer>
    </mm-modal>
  </div>
</form>
