export enum PagerStateKey {
  INVOICES = 'INVOICES',
  AMAZON_TRANSACTIONS = 'AMAZON_TRANSACTIONS',
  VENDOR_NOTES = 'VENDOR_NOTES',
  PAYMENT_DOCUMENTS = 'PAYMENT_DOCUMENTS',
  BUYER_INVOICES = 'BUYER_INVOICES',
  BUYER_NOTES = 'BUYER_NOTES',
  SALE_ORDERS = 'SALE_ORDERS',
  DELIVERIES = 'DELIVERIES',
  GOODS_RECEIPTS = 'GOODS_RECEIPTS',
  FULFILMENT_ORDERS = 'FULFILMENT_ORDERS',
  IWT_INVOICES = 'IWT_INVOICES',
  FAILED_MESSAGES = 'FAILED_MESSAGES',
  FINANCE_OFFICE = 'FINANCE_OFFICE',
  MCOM_OFFER_MANAGEMENTS = 'MCOM_OFFER_MANAGEMENTS'
}

export const ITEMS_PER_PAGE_STORAGE_KEY = 'ITEMS_PER_PAGE';
