import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Path } from '@fnc-shared/constants/path.constant';
import { SideMenuProtected } from '@fnc-shared/interfaces/side-menu.interface';

export const SupplyChainSideMenuItems: SideMenuProtected[] = [
  {
    title: _('NAVIGATION.MAIN.SUPPLY_CHAIN'),
    items: [
      {
        items: [
          {
            routerLink: ['/', Path.supplyChain, Path.demandPlanning],
            title: _('NAVIGATION.SUPPLY_CHAIN.DEMAND_PLANNING'),
            rights: { $nand: ['TRUE', 'ROLE_VENDOR'], $and: ['TRUE', 'FF_FP_SUPPLY_CHAIN_DEMAND_PLANNING'] }
          },
          {
            routerLink: ['/', Path.supplyChain, Path.salesForecasting],
            title: _('NAVIGATION.SUPPLY_CHAIN.SALES_FORECASTING'),
            rights: { $nand: ['TRUE', 'ROLE_VENDOR'] }
          },
          {
            routerLink: ['/', Path.supplyChain, Path.replenishmentV2],
            title: _('NAVIGATION.SUPPLY_CHAIN.REPLENISHMENT_V2'),
            rights: { $nand: ['TRUE', 'ROLE_VENDOR'], $and: ['TRUE', 'FF_FP_SUPPLY_CHAIN_REPLENISHMENT_V2'] }
          },
          {
            routerLink: ['/', Path.supplyChain, Path.replenishment],
            title: _('NAVIGATION.SUPPLY_CHAIN.REPLENISHMENT'),
            rights: { $nand: ['TRUE', 'ROLE_VENDOR'] }
          },
          {
            routerLink: ['/', Path.supplyChain, Path.supplyOrderDrafts],
            title: _('NAVIGATION.SUPPLY_CHAIN.SUPPLY_ORDER_DRAFTS'),
            rights: { $nand: ['TRUE', 'ROLE_VENDOR'] }
          },
          {
            routerLink: ['/', Path.supplyChain, Path.supplyOrders],
            title: _('NAVIGATION.SUPPLY_CHAIN.SUPPLY_ORDERS'),
            rights: { $nand: ['TRUE', 'ROLE_VENDOR'] }
          }
        ]
      }
    ]
  }
];
