import { Injectable } from '@angular/core';
import { ShippingInformation } from '@fnc-modules/fulfilment-orders/models/orders.interface';
import {
  AddressBlock,
  AddressControlConfig,
  AddressLabels,
  AddressType
} from '@fnc-shared/components/entity-properties/address-properties/interfaces/address-properties.interface';
import { isEmptyString } from '@fnc-shared/helpers/utils/value-checker.helpers';
import { addressControls } from './address-properties.constant';

@Injectable({ providedIn: 'root' })
export class AddressPropertiesService {
  getFormattedAddress(type: AddressType, addressInfo: ShippingInformation) {
    if (addressInfo) {
      const addressBlock: Partial<AddressBlock> = {};
      addressBlock.label = type === AddressType.SHIPPING ?
        AddressLabels.SHIPPING : AddressLabels.BILLING;
      addressBlock.info = [
        this.getAddressLine(addressInfo),
        addressInfo.addressLine2,
        [addressInfo.zip, addressInfo.city]
          .filter(n => !isEmptyString(n))
          .join(' '),
        addressInfo.phone,
        addressInfo.country
      ].filter(n => !isEmptyString(n));

      return addressBlock;
    }

    return null;
  }

  getControlsConfig(addressType: AddressType, addressInfo: ShippingInformation): AddressControlConfig[] {
    return addressControls
      .filter(
        controlItem => controlItem.filterFn
          ? controlItem.filterFn(addressType, addressInfo)
          : true
      )
      .map(
        controlItem => ({
          ...controlItem,
          initialValue: controlItem.valueGetter(addressInfo)
        })
      );
  }

  getFormBuilderConfig(controls: AddressControlConfig[]) {
    return controls.reduce(
      (formBuilderConfig, controlItem) => ({
        ...formBuilderConfig,
        [controlItem.controlName]: [
          controlItem.initialValue ?? '',
          controlItem.validators?.length
            ? controlItem.validators
            : []
        ]
      }),
      {}
    );
  }

  private getAddressLine(addressInfo: ShippingInformation) {
    if (!isEmptyString(addressInfo.addressLine1)) {
      return addressInfo.addressLine1;
    }

    if (!isEmptyString(addressInfo.street) && !isEmptyString(addressInfo.houseNumber)) {
      return [addressInfo.street, addressInfo.houseNumber].join(' ');
    }

    return '';
  }
}
