import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { VendorNotePositionType, VendorNoteReasonType, VendorNoteStatus } from '@fnc-modules/vendor-notes/models/notes';

export const VendorNoteStatusLabels = {
  [VendorNoteStatus.NEW]: _('VENDOR_NOTES.STATUSES.NEW'),
  [VendorNoteStatus.DRAFT]: _('VENDOR_NOTES.STATUSES.DRAFT'),
  [VendorNoteStatus.WAITING_FOR_CLARIFICATION]: _('VENDOR_NOTES.STATUSES.WAITING_FOR_CLARIFICATION'),
  [VendorNoteStatus.WAITING_FOR_CLEARING]: _('VENDOR_NOTES.STATUSES.WAITING_FOR_CLEARING'),
  [VendorNoteStatus.APPROVED]: _('VENDOR_NOTES.STATUSES.APPROVED'),
  [VendorNoteStatus.SUBMITTED_FOR_PAYMENT]: _('VENDOR_NOTES.STATUSES.FOR_PAYMENT'),
  [VendorNoteStatus.SETTLED]: _('VENDOR_NOTES.STATUSES.SETTLED'),
  [VendorNoteStatus.DECLINED]: _('VENDOR_NOTES.STATUSES.DECLINED'),
  [VendorNoteStatus.APPLIED]: _('VENDOR_NOTES.STATUSES.APPLIED')
};

export const VendorNotePositionTypeLabels = {
  [VendorNotePositionType.PRODUCT]: _('VENDOR_NOTES.UNIT_TYPE.PRODUCT'),
  [VendorNotePositionType.SERVICE]: _('VENDOR_NOTES.UNIT_TYPE.SERVICE')
};

export const VendorNoteReasonsLabels = {
  [VendorNoteReasonType.CORRECTION]: _('VENDOR_NOTES.REASON_TYPE.CORRECTION'),
  [VendorNoteReasonType.RETURN]: _('VENDOR_NOTES.REASON_TYPE.RETURN')
};
