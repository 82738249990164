import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AccountingPageComponent } from '@fnc-core/components/accounting-page/accounting-page.component';
import { ProductDataSideMenuItems } from '@fnc-core/components/product-data-page/product-data-side-menu-items.constant';

@UntilDestroy()
@Component({
  templateUrl: './product-data-page.component.html'
})
export class ProductDataPageComponent extends AccountingPageComponent {
  sideMenu = ProductDataSideMenuItems;
}
