<div *ngIf="addressInfo" class="m-col-xs12of12 fnc-shipping-info" [class.fnc-shipping-info-last]="isLast">
  <div class="fnc-top-info">
    <div class="fnc-action-title" *ngIf="title">
      {{ title | translate }}
      <ng-container *mmPermissions="'ROLE_CAN_MANAGE_CUSTOMER_REQUESTS'">
        <span *ngIf="isEditableAddress" class="fnc-address-info__edit-btn" (click)="onEditAddress()">
          <mm-icon kind="edit-small" color="blue" [size]="0.5"></mm-icon>
        </span>
      </ng-container>
    </div>
    <div class="m-row fnc-vendor-properties-title">
      <h5>
        <mm-icon kind="company-profile" size="0.7" class="fnc-vendor-properties-icon-title" color="grey-tint-40"></mm-icon
        >{{ addressInfo.companyName | mmAddDash }}
      </h5>
    </div>
    <div class="m-row fnc-vendor-properties-title">
      <h5>
        <mm-icon kind="user-profile" size="0.7" class="fnc-vendor-properties-icon-title" color="grey-tint-40"></mm-icon
        >{{ addressInfo.name | mmAddDash }}
      </h5>
    </div>
    <ng-container *ngIf="addressInfo.gln">
      <div class="m-row fnc-vendor-properties-row">
        <h6>{{ 'VENDORS.PROPERTIES.GENERAL_INFORMATION' | translate }}</h6>
      </div>
      <div class="m-row fnc-vendor-properties-row">
        <mm-label class="mm-label" for="">{{ 'VENDORS.PROPERTIES.GLN' | translate }}</mm-label>
        <div>
          <span>{{ addressInfo.gln }}</span>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="fnc-vendor-properties-address" *ngIf="addressBlock?.info.length">
    <div>
      <mm-label class="mm-label" for="address">{{ addressBlock.label | translate }}</mm-label>
      <span *ngFor="let item of addressBlock.info; let last = last"> {{ item }} <ng-container *ngIf="!last">, </ng-container> </span>
    </div>
  </div>
</div>

<ng-template #editModal>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <mm-modal compact>
      <mm-modal-header>
        <mm-bar>
          <mm-bar-item>
            {{ 'VENDORS.PROPERTIES.MODAL_TITLES.' + type | translate }}
          </mm-bar-item>
        </mm-bar>
      </mm-modal-header>
      <mm-modal-content [isPadded]="false">
        <mm-form-field
          *ngFor="let control of controlsConfig"
          [control]="form.get(control.controlName)"
          [errorData]="{ name: control.label | translate }"
        >
          <mm-label class="mm-label" [for]="control.controlName">{{ control.label | translate }}</mm-label>
          <input [id]="control.controlName" class="m-input-element" [formControlName]="control.controlName" />
        </mm-form-field>
      </mm-modal-content>
      <mm-modal-footer>
        <button kind="primaryRaised" mmButton size="small" type="submit">
          {{ 'VENDORS.PROPERTIES.MODAL_CONFIRM' | translate }}
        </button>
        &nbsp;
        <button (click)="modalRef.hide()" kind="secondary" mmButton size="small" type="button">
          {{ 'COMMON.CONFIRM_WINDOW.CANCEL' | translate }}
        </button>
      </mm-modal-footer>
    </mm-modal>
  </form>
</ng-template>
