import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { MetricsEventType } from '@fnc-core/services/metrics/metrics-event-name.constant';
import { MetricsGuid } from '@fnc-core/services/metrics/metrics.helper';
import { MetricsModel } from '@fnc-core/services/metrics/metrics.model';
import { ApiService, deeply } from '@mm-ui/core';
import { Angulartics2 } from 'angulartics2';
import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';
import { filter } from 'rxjs/operators';
import { url } from './metrics.url';

@Injectable({ providedIn: 'root' })
export class Angulartics2Metrics {
  loadStartTime: number = null;
  loadTime: number = null;
  metrics: { [name: string]: number } = null;
  dimensions: { [name: string]: string } = null;
  measurements: { [name: string]: number } = null;
  sessionId = MetricsGuid();
  userId: string;

  constructor(
    private readonly angulartics2: Angulartics2,
    private readonly title: Title,
    private readonly router: Router,
    private readonly api: ApiService
  ) {
    this.angulartics2.setUsername
      .subscribe((x: string) => this.setUsername(x));
  }

  startTracking(): void {
    this.angulartics2.pageTrack
      .pipe(this.angulartics2.filterDeveloperMode())
      .subscribe(x => this.pageTrack(x.path));
    this.angulartics2.eventTrack
      .pipe(this.angulartics2.filterDeveloperMode())
      .subscribe((x: { action: MetricsEventType, properties: MetricsModel }) => this.eventTrack(x.action, x.properties));
    this.angulartics2.exceptionTrack
      .pipe(this.angulartics2.filterDeveloperMode())
      .subscribe(x => this.exceptionTrack(x));
    this.router.events
      .pipe(
        this.angulartics2.filterDeveloperMode(),
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(_event => this.startTimer());

    this.router.events
      .pipe(filter(event => event instanceof NavigationError || event instanceof NavigationEnd))
      .subscribe(_error => this.stopTimer());
  }

  startTimer() {
    this.loadStartTime = Date.now();
    this.loadTime = null;
  }

  stopTimer() {
    this.loadTime = Date.now() - this.loadStartTime;
    this.loadStartTime = null;
  }

  pageTrack(path: string) {
    this.sendEvent({
      eventType: MetricsEventType.VISIT_PAGE,
      payload: {
        title: this.title.getTitle(),
        path,
        dimensions: this.dimensions,
        metrics: this.metrics,
        loadTime: this.loadTime
      }
    });
  }

  eventTrack(eventType: string, properties: MetricsModel) {
    this.sendEvent({
      eventType,
      entityType: properties.entityType,
      entityId: properties.entityType && properties.payload && properties.payload.id ? properties.payload.id : undefined,
      eventGuid: properties.eventGuid,
      payload: {
        ...properties.payload,
        measurements: this.measurements,
        dimensions: this.dimensions
      }
    });
  }

  exceptionTrack(properties: RecordNested) {
    const description = properties.event || properties.description || properties;

    this.sendEvent({
      eventType: MetricsEventType.EXCEPTION,
      payload: {
        description
      }
    });
  }

  setUsername(userId: string) {
    if (!this.sessionId) {
      this.initSession();
    }
    this.dimensions = { ...this.dimensions, userId };
  }

  initSession() {
    this.sessionId = MetricsGuid();
  }

  private sendEvent(data: RecordNested) {
    const beaconUrl = ApiService.applyURLParameters(url.metricsEvent, {}, false);
    const preparedData = {
      eventGuid: data.eventGuid || MetricsGuid(),
      sessionId: data.sessionId || this.sessionId,
      date: new Date(),
      ...data
    };

    if (navigator.sendBeacon) {
      const mappedData = deeply(mapKeys)(preparedData, (value: RecordNested, key: string) => snakeCase(key));
      navigator.sendBeacon(beaconUrl, JSON.stringify(mappedData));
    } else {
      this.api.post(url.metricsEvent, null, preparedData).subscribe();
    }
  }
}
