import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationEventsService } from '@fnc-core/services/notification-events/notification-events.service';
import { UserService } from '@fnc-core/services/user/user.service';
import { environment } from '@fnc-env/environment';
import { MainMenu } from '@fnc-shared/components/main-menu/main-menu';
import { Path } from '@fnc-shared/constants/path.constant';

@Component({
  selector: 'fnc-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuComponent implements OnInit {
  @ViewChild('profileMenu', { static: true }) profileMenu: TemplateRef<ImplicitAny>;
  @ViewChild('notificationsMenu', { static: true }) notificationsMenu: TemplateRef<ImplicitAny>;
  @Input() canExpand = false;

  path = Path;
  smsAppPath = environment.smsAppPath;
  vendorPortalPath = environment.vendorPortalPath;
  helpCenterPath = environment.helpCenterPath;
  mainMenu: MainMenu[] = [];
  bottomMenu: MainMenu[] = [];
  profileSettingsHref = '';
  logoutHref = `${this.smsAppPath}logout`;

  constructor(
    private readonly translate: TranslateService,
    private readonly notificationEventsService: NotificationEventsService,
    user: UserService
  ) {
    this.profileSettingsHref = `${this.smsAppPath}profiles/${user.profile.id}`;
  }

  ngOnInit() {
    this.translate
      .get([
        'NAVIGATION.MAIN.SMS',
        'NAVIGATION.MAIN.OFFER_MANAGEMENT',
        'NAVIGATION.MAIN.FINANCE_PORTAL',
        'NAVIGATION.BOTTOM.PROFILE',
        'NAVIGATION.BOTTOM.LOGOUT',
        'NAVIGATION.MAIN.ADMIN_PORTAL',
        'NAVIGATION.MAIN.HELP_CENTER',
        'NAVIGATION.MAIN.SUPPLY_CHAIN',
        'NAVIGATION.MAIN.REPORTS',
        'NAVIGATION.BOTTOM.SETTINGS',
        'NAVIGATION.BOTTOM.NOTIFICATIONS',
        'NAVIGATION.MAIN.FULFILMENT',
        'NAVIGATION.MAIN.INSTALLATION_SERVICES',
        'NAVIGATION.MAIN.PRODUCT_DATA'
      ])
      .subscribe(lang => {
        // please, use alphabetic order in items
        const internalMenu = [
          {
            icon: 'inventories',
            title: lang['NAVIGATION.MAIN.OFFER_MANAGEMENT'],
            routerObj: { link: `/${Path.offerManagement}` },
            rights: 'ROLE_CAN_VIEW_INVUNITS'
          },
          {
            icon: 'payments',
            title: lang['NAVIGATION.MAIN.FINANCE_PORTAL'],
            routerObj: { link: `/${Path.financePortal}` },
            rights: {
              $or: [
                'ROLE_CAN_VIEW_VENDOR_INVOICES',
                'ROLE_CAN_EXPORT_PAYMENTS',
                'ROLE_CAN_VIEW_VENDOR_BALANCES',
                'ROLE_CAN_VIEW_PAYMENTS',
                'ROLE_CAN_VIEW_GR_HEADERS',
                'ROLE_CAN_VIEW_FORDERS'
              ]
            }
          },
          {
            icon: 'fulfilment',
            title: lang['NAVIGATION.MAIN.FULFILMENT'],
            routerObj: { link: `/${Path.fulfilment}` },
            rights: [
              {
                $or: ['ROLE_CAN_VIEW_SALE_ORDERS', 'ROLE_CAN_VIEW_RETURNS_LIST']
              }
            ]
          },
          {
            icon: 'installation-service',
            title: lang['NAVIGATION.MAIN.INSTALLATION_SERVICES'],
            routerObj: { link: `/${Path.installationServices}` },
            rights: 'ROLE_CAN_MANAGE_INVENTORIES'
          },
          {
            icon: 'supply-chain',
            title: lang['NAVIGATION.MAIN.SUPPLY_CHAIN'],
            routerObj: { link: `/${Path.supplyChain}` },
            rights: { $nand: ['TRUE', 'ROLE_VENDOR'] }
          },
          {
            icon: 'reports',
            title: lang['NAVIGATION.MAIN.REPORTS'],
            routerObj: { link: `/${Path.reports}` },
            rights: {
              $or: ['ROLE_CAN_MANAGE_FINANCE_REPORTS']
            }
          },
          {
            icon: 'request',
            title: lang['NAVIGATION.MAIN.PRODUCT_DATA'],
            routerObj: { link: `/${Path.productData}` },
            rights: { $or: ['ROLE_ADMIN', 'ROLE_CAN_MANAGE_ERP_ATTRIBUTES'] }
          },
          {
            icon: 'admin-screen',
            title: lang['NAVIGATION.MAIN.ADMIN_PORTAL'],
            routerObj: { link: `/${Path.admin}` },
            rights: {
              $or: ['ROLE_CAN_VIEW_FAILED_MESSAGES']
            }
          }
        ];
        const externalMenu = [
          {
            icon: 'apps',
            title: lang['NAVIGATION.MAIN.SMS'],
            href: `${this.smsAppPath}dashboard`,
            rights: { $any: [''] },
            extraLink: {
              href: `${this.smsAppPath}dashboard`,
              title: 'EXT'
            }
          }
        ];

        this.mainMenu = [...this.sortMenuItem(internalMenu), ...this.sortMenuItem(externalMenu)];

        this.bottomMenu = [
          {
            id: MainMenuId.NOTIFICATION,
            icon: 'notification-filled',
            title: lang['NAVIGATION.BOTTOM.NOTIFICATIONS'],
            menuTemplate: this.notificationsMenu,
            menuClass: 'fnc-main-menu__notification-menu',
            bullet: false,
            rights: { $any: [''] }
          },
          {
            icon: 'help-filled',
            title: lang['NAVIGATION.MAIN.HELP_CENTER'],
            href: `${this.helpCenterPath}home`,
            rights: { $any: [''] }
          },
          {
            icon: 'settings-filled',
            title: lang['NAVIGATION.BOTTOM.SETTINGS'],
            rights: [
              {
                $or: ['ROLE_CAN_MANAGE_ACCOUNTING_PERIOD']
              }
            ],
            routerObj: { link: `/${Path.settings}` }
          },
          {
            icon: 'profile-filled',
            title: lang['NAVIGATION.BOTTOM.PROFILE'],
            menuTemplate: this.profileMenu,
            menuClass: 'fnc-main-menu__profile-menu',
            rights: { $any: [''] }
          }
        ];
      });
    this.subscribeOnNotifications();
  }

  private subscribeOnNotifications() {
    this.notificationEventsService.unread$.subscribe(unread => {
      this.bottomMenu = this.bottomMenu.map(item => {
        if (item.id === MainMenuId.NOTIFICATION) {
          item.bullet = unread > 0;
        }

        return item;
      });
    });
  }

  private sortMenuItem(menu: MainMenu[]) {
    return menu.sort((itemA, itemB) => itemA?.title.localeCompare(itemB?.title));
  }
}

export enum MainMenuId {
  NOTIFICATION = 'notification'
}
