import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

export const InvoiceStatusText: Record<string, string> = {
  ALL: _('INVOICES.STATUS.ALL'),
  WAITING_FOR_CLEARING: _('INVOICES.STATUS.WAITING_FOR_CLEARING'),
  WAITING_FOR_APPROVAL: _('INVOICES.STATUS.WAITING_FOR_APPROVAL'),
  APPROVED: _('INVOICES.STATUS.APPROVED'),
  SUBMITTED_FOR_PAYMENT: _('INVOICES.STATUS.SUBMITTED_FOR_PAYMENT'),
  SETTLED: _('INVOICES.STATUS.SETTLED'),
  DECLINED: _('INVOICES.STATUS.DECLINED'),
  WAITING_FOR_CORRECTION: _('INVOICES.STATUS.WAITING_FOR_CORRECTION'),
  SUSPENDED: _('INVOICES.STATUS.SUSPENDED'),
  DRAFT: _('INVOICES.STATUS.DRAFT'),
  REVISED: _('INVOICES.STATUS.REVISED'),
  NEW: _('INVOICES.STATUS.NEW')
};

export const InvoicePaymentBlockedText: Record<string, string> = {
  PAYMENTS_BLOCKED: _('INVOICES.LIST.PAYMENTS_BLOCKED'),
  RETURN_NOTE_REQUIRED: _('INVOICES.LIST.RETURN_NOTE_REQUIRED')
};
