import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { NotificationChannel } from '@fnc-core/services/notification-events/notification-events.constant';
import { NotificationState, NotificationStore } from '@fnc-core/services/notification-events/state/notification.store';
import { Notification } from '@mm-ui/core';

@Injectable({ providedIn: 'root' })
export class NotificationQuery extends QueryEntity<NotificationState, Notification<NotificationChannel>> {
  constructor(protected store: NotificationStore) {
    super(store);
  }
}
