export enum UiLoaderType {
  GLOBAL = 'global',
  MAIN_PAGE = 'mainPage',
  MAIN_LIST = 'mainList'
}

export interface UiLoaderState {
  [UiLoaderType.GLOBAL]?: number;
  [UiLoaderType.MAIN_PAGE]?: number;
  [UiLoaderType.MAIN_LIST]?: number;
  timestamp: number;
}
