<mm-label class="mm-label" for="date">
  {{ label }}
  <mm-icon
    *ngIf="tooltipText"
    [size]="0.5"
    [tooltip]="tooltipText"
    class="fnc-single-field-form__icon-tooltip"
    kind="help"
    placement="right"
  ></mm-icon>
</mm-label>
<div *ngIf="mode === 'view' || readOnly; else singleFieldForm">
  <span>{{ value | mmAddDash }}</span>
  <ng-container *mmPermissions="permissions">
    <span *ngIf="!readOnly" class="fnc-single-field-form__icon-wrap">
      <mm-icon (click)="changeMode()" class="fnc-single-field-form__icon" color="blue" kind="edit-small" size="0.5"></mm-icon>
    </span>
  </ng-container>
</div>
<ng-template #singleFieldForm>
  <form [formGroup]="form" class="fnc-single-field-form">
    <mm-form-field [control]="form.get(field)" [errorData]="errorData" class="fnc-single-field-form__field">
      <ng-container [ngSwitch]="fieldType">
        <textarea *ngSwitchCase="singleFieldType.TEXT" [formControlName]="field" class="m-input-element"></textarea>
        <input *ngSwitchDefault [formControlName]="field" class="m-input-element" />
      </ng-container>
      <button
        (click)="onApply()"
        [disabled]="!form.valid"
        class="fnc-single-field-form__button fnc-single-field-form__button-accept"
        type="button"
      >
        <mm-icon color="white" kind="check" size="0.75"></mm-icon>
      </button>
      <button (click)="changeMode('view')" class="fnc-single-field-form__button fnc-single-field-form__button-cancel" type="button">
        <mm-icon color="blue" kind="close" size="0.75"></mm-icon>
      </button>
    </mm-form-field>
  </form>
</ng-template>
