import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { skip } from 'rxjs/operators';
import { Vendor } from './vendor';
import { VendorsState, VendorsStore } from './vendors.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'name',
  sortByOrder: Order.ASC
})
export class VendorsQuery extends QueryEntity<VendorsState, Vendor> {
  isLoaded = false;

  constructor(protected store: VendorsStore) {
    super(store);
  }

  getVendorsQueryList() {
    const query = super.selectAll();

    return this.isLoaded ? query : query.pipe(skip(1));
  }
}
