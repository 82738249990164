<fnc-main-menu-horizontal *ngIf="isHorizontalHeader; else verticalHeader"></fnc-main-menu-horizontal>

<ng-template #verticalHeader>
  <fnc-main-menu></fnc-main-menu>
</ng-template>

<div [ngClass]="{ 'fnc-side-grid--is-horizontal-header': isHorizontalHeader }" class="fnc-page">
  <mm-page lightBlue [sideBar]="!isHorizontalHeader">
    <mm-page-content>
      <mm-side-menu [items]="items">
        <div class="m-sideGrid-sideContent">
          <div #pageScrollContainer class="m-panel m-panel-main-content">
            <router-outlet></router-outlet>
          </div>
        </div>
      </mm-side-menu>
    </mm-page-content>
  </mm-page>
</div>
