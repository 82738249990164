import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';

@Component({
  template: `
    <ng-template [fncTemplateRequestor]="component" let-value="value">
      {{ value | translate: translationParams }}
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuditLogUserCellComponent extends CellComponent {
  translationParams: RecordString;
}
