import { Component } from '@angular/core';
import { AccountingPageComponent } from '@fnc-core/components/accounting-page/accounting-page.component';
import { ReportsSideMenuItems } from '@fnc-core/components/reports-page/reports-side-menu-items.constant';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  templateUrl: './reports-page.component.html'
})
export class ReportsPageComponent extends AccountingPageComponent {
  sideMenu = ReportsSideMenuItems;
}
