import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SelectOption } from '@mm-ui/components/lib/components/editable-table/models/table-select.model';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';

@Component({
  templateUrl: './select-cell.component.html',
  styleUrls: ['./select-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectCellComponent extends CellComponent {
  selectOptions: SelectOption[] = [];
  isDisabled = false;

  changeSettings = (_newValue: string, _id: number, _value: string): void => void {};
}
