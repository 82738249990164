export const isEmptyString = (value?: string) => !value?.trim();

export const isEqualToZero = (value: WeakNumber) => {
  if (typeof value === 'string') {
    return parseInt(value, 10) === 0;
  }

  return value === 0;
};

export const isInvalidDate = (value: string) => value && isNaN(new Date(value).getTime());

export const isValueEmpty = (value: ExtPrimitive | [] | null | undefined) => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string') {
    return isEmptyString(value);
  }

  return Array.isArray(value) && value.length === 0;
};

export const trimString = (value: string) => value.replace(/\s/g, '');

export const isSameString = (value: string, valueToCompare: string) =>
  value && valueToCompare && trimString(value).toUpperCase() === trimString(valueToCompare).toUpperCase();

export const isStringMatchedFromStart = (stringValue: string, matcher: string | number) => new RegExp(`^${matcher}`, 'i').test(stringValue);
