import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { ColorType } from '@mm-ui/components/lib/components/icon/color.type';
import { VendorNoteStatus } from '@fnc-app/modules/vendor-notes/models/notes';
import { VendorNoteStatusLabels } from '@fnc-app/modules/vendor-notes/models/notes.constant';
import { InvoiceStatusText } from '@fnc-shared-fnc/constants/invoice-status-text.constant';
import { InvoiceStatus } from '@fnc-shared-fnc/constants/invoice-status.constant';
import { NotesHelpersService } from '@fnc-shared-fnc/services/notes-helpers/notes-helpers.service';
import { VendorInvoiceHelpersService } from '@fnc-shared/services/vendor-invoice-helpers/vendor-invoice-helpers.service';
import { StatusTransitionChip } from '@fnc-shared/interfaces/status-transition.interface';

@Component({
  selector: 'fnc-document-status-transition',
  templateUrl: './document-status-transition.component.html',
  styleUrls: ['./document-status-transition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentStatusTransitionComponent implements OnInit, OnChanges {
  @Input() oldStatus: InvoiceStatus | VendorNoteStatus;
  @Input() newStatus: InvoiceStatus | VendorNoteStatus;
  @Input() isNote = false;

  oldStatusChip: StatusTransitionChip;
  newStatusChip: StatusTransitionChip;

  get oldStatusIsNew() {
    return this.oldStatus === InvoiceStatus.NEW;
  }

  get oldStatusText() {
    if (this.isNote) {
      return VendorNoteStatusLabels[this.oldStatus as VendorNoteStatus];
    }

    return InvoiceStatusText[this.oldStatus as InvoiceStatus];
  }

  get newStatusText() {
    if (this.isNote) {
      return VendorNoteStatusLabels[this.newStatus as VendorNoteStatus];
    }

    return InvoiceStatusText[this.newStatus as InvoiceStatus];
  }

  constructor(
    private readonly vendorInvoiceHelpersService: VendorInvoiceHelpersService,
    private readonly notesHelpersService: NotesHelpersService
  ) {}

  ngOnInit() {
    this.initStatusChips();
  }

  ngOnChanges() {
    this.initStatusChips();
  }

  private getTagColor(invoiceStatus: InvoiceStatus | VendorNoteStatus): ColorType {
    if (this.isNote) {
      return this.notesHelpersService.getNoteTagColor(invoiceStatus as VendorNoteStatus);
    }

    return this.vendorInvoiceHelpersService.getInvoiceTagColor(invoiceStatus as InvoiceStatus);
  }

  private getTagTextColor(invoiceStatus: InvoiceStatus | VendorNoteStatus): ColorType {
    if (this.isNote) {
      return this.notesHelpersService.getNoteTagTextColor(invoiceStatus as VendorNoteStatus);
    }

    return this.vendorInvoiceHelpersService.getInvoiceTagTextColor(invoiceStatus as InvoiceStatus);
  }

  private initStatusChips() {
    this.oldStatusChip = {
      text: this.oldStatusText,
      color: this.getTagColor(this.oldStatus),
      textColor: this.getTagTextColor(this.oldStatus)
    };

    this.newStatusChip = {
      text: this.newStatusText,
      color: this.getTagColor(this.newStatus),
      textColor: this.getTagTextColor(this.newStatus)
    };
  }
}
