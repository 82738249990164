import { ColorType } from '@mm-ui/components/lib/components/icon/color.type';

export enum Color {
  RED = 'red',
  GREEN = 'green',
  GREY = 'grey',
  ORANGE = 'orange',
  WHITE = 'white',
  PURPLE = 'purple',
  BLUE = 'blue',
  YELLOW = 'metro-yellow',
  BLACK = 'black'
}

export enum ColorDegree {
  L20 = 20,
  L40 = 40,
  L60 = 60,
  L80 = 80,
  L90 = 90,
  L95 = 95
}

export const shade = (color: Color, value: ColorDegree) => `${color}-shade-${value}` as ColorType;
export const tint = (color: Color, value: ColorDegree) => `${color}-tint-${value}` as ColorType;
