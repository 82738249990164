import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SortDirection } from '@swimlane/ngx-datatable';
import { Vendor } from './vendor';

export interface VendorsUIState {
  prop: string;
  dir: SortDirection;
  filters: RecordOf<string | number>;
}

export interface VendorsState extends EntityState<Vendor> {
  ui: VendorsUIState;
}

const initialState = {
  ui: {
    prop: 'name',
    dir: SortDirection.asc,
    filters: {}
  }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'vendors' })
export class VendorsStore extends EntityStore<VendorsState, Vendor> {
  constructor() {
    super(initialState);
  }

  updateSorting(prop: string, dir: SortDirection, filters: RecordOf<string | number> = {}) {
    this.update({ ui: { prop, dir, filters } });
  }
}
