import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { SideMenuProtected } from '@fnc-shared/interfaces/side-menu.interface';
import { Path } from '@fnc-shared/constants/path.constant';
import { DisabledFeature } from '@fnc-shared/services/disabled-features/disabled-features.constant';

export const AdminSideMenuItems: SideMenuProtected[] = [
  {
    title: _('NAVIGATION.ADMIN.GROUP.LOGS'),
    items: [
      {
        items: [
          {
            routerLink: ['/', Path.admin, Path.failedMessages],
            title: _('NAVIGATION.ADMIN.FAILED_MESSAGES'),
            rights: [
              'ROLE_CAN_VIEW_FAILED_MESSAGES',
              { $nor: ['FALSE', DisabledFeature.INDEXEDDB] }
            ]
          }
        ]
      }
    ]
  }
];
