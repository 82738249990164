import { Injectable } from '@angular/core';
import { ApiService } from '@mm-ui/core';
import { Observable } from 'rxjs';
import { URLS } from './product-popup.url';
import { ProductData } from '@fnc-shared-fnc/components/product-pop-up/product-popup.interface';

@Injectable()
export class ProductPopupService {
  constructor(private readonly apiService: ApiService) {}

  getProductInfo({ gtin, sku }: { gtin: string; sku: string }) {
    const url = sku ? URLS.productSku.url : URLS.productGtin.url;
    const data = sku ? { sku } : { gtin };

    return this.apiService.get(url, data, { isCacheEnabled: true }) as Observable<ProductData>;
  }
}
