import { Injectable } from '@angular/core';
import { ApiService, HttpStatus, I18nLocaleData } from '@mm-ui/core';
import { HttpErrorExtResponse } from '@mm-ui/core/lib/services/api/http-error-ext-response.interface';
import { AsyncSubject, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { FEATURE_FLAGS_LOADING_FAILED } from '../../constants/data-loading-error.constant';
import { url } from './settings.url';
import { HttpResponseHandlerService } from '@fnc-shared/services/http-response-handler.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  static $initialized: Subject<boolean | undefined> = new AsyncSubject();

  static initialized() {
    SettingsService.$initialized.next(true);
    SettingsService.$initialized.complete();
  }

  config: ApplicationConfig = {
    invoiceDueDateWarningDays: 3
  };

  userSettings: UserSettings = {
    amountThreshold: 2000
  };

  locale: I18nLocaleData = {
    id: 'en',
    shortMonthYear: 'MMM YYYY',
    shortDateMonth: 'DD MMM',
    shortDateDotMonth: 'DD. MMM',
    shortDate: 'DD MMM YYYY',
    shortDateTime: 'DD MMM YYYY HH:mm:ss',
    shortDateFullTime: 'DD MMM YYYY HH:mm:ss.SSS',
    currencySymbol: '€',
    currency: 'EUR'
  };

  featureFlags: RecordBoolean = {};

  specialVendors: RecordWeakNumber = {
    metro: 4
  };

  constructor(
    private readonly api: ApiService,
    private readonly responseHandler: HttpResponseHandlerService
  ) {}

  getCurrencySymbol() {
    return this.locale.currencySymbol || this.locale.currency;
  }

  getCurrencyCode() {
    return this.locale.currency;
  }

  getFeatureFlags(options: RecordExtPrimitive | undefined = undefined) {
    return this.api.get(url.featureFlags, undefined, options, { isCacheEnabled: true, ignoreBasePath: true }).pipe(
      catchError((error: HttpErrorExtResponse) => {
        if ((error.status as HttpStatus) !== HttpStatus.CODE401) {
          this.responseHandler.errorAlert(FEATURE_FLAGS_LOADING_FAILED);
        }

        return throwError(error);
      }),
      tap((response: RecordBoolean) => (this.featureFlags = response))
    );
  }
}

export interface ApplicationConfig {
  invoiceDueDateWarningDays: number;
}

export interface LocaleConfig {
  id: string;
  shortDate: string;
  date: string;
}

export interface UserSettings {
  amountThreshold: number;
}

export enum SupportedLanguagesCodes {
  EN = 'en',
  DE = 'de',
  ES = 'es'
}
