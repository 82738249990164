import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { WindowEvents } from '../../services/window/events';
import { WindowService } from '../../services/window/window.service';

@Component({
  selector: 'fnc-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ExportButtonComponent {
  @Input() label = ' ';
  @Input() isLoading = false;
  @Output() export: EventEmitter<string> = new EventEmitter();
  tooltipClass = 'fnc-export-tooltip';

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    private readonly windowService: WindowService
  ) { }

  onShown() {
    setTimeout(() => {
      this.windowService.dispatchUIEvent(WindowEvents.RESIZE);
      const tooltip = this.document.querySelector(`.${this.tooltipClass}`);

      if (tooltip) {
        this.renderer.removeClass(tooltip, this.tooltipClass);
      }
    });
  }

  onExport() {
    this.export.emit();
  }
}
