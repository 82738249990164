import { NotificationDefaultChannel } from '@mm-ui/core';

export type NotificationChannel = NotificationDefaultChannel
  | NotificationChannelName.FINANCE_REPORTS
  | NotificationChannelName.FINANCE_ENTITY_UPDATE;

export enum NotificationChannelName {
  'FINANCE_ENTITY_UPDATE' = 'FINANCE_ENTITY_UPDATE',
  'FINANCE_REPORTS' = 'FINANCE_REPORTS',
  'PERSONAL' = 'PERSONAL',
  'SYSTEM' = 'SYSTEM'
}
