import { Injectable } from '@angular/core';
import { IDBPDatabase, openDB } from 'idb';
import { environment } from '@fnc-env/environment';
import { FailedMessageRenderer } from '@fnc-modules/failed-messages/models/failed-message.interface';
import { applyMigrationActions } from '@fnc-shared/services/db/migrations/db-migration-actions';
import { dbMigrations } from '@fnc-shared/services/db/migrations/db-migrations';
import { DisabledFeature } from '@fnc-shared/services/disabled-features/disabled-features.constant';
import { DisabledFeaturesService } from '@fnc-shared/services/disabled-features/disabled-features.service';

@Injectable({
  providedIn: 'root'
})
export class DbService {
  db: IDBPDatabase<FailedMessageRenderer>;
  private readonly dbName = 'adminportaldb';

  constructor(private readonly disabledFeaturesService: DisabledFeaturesService) {}

  async connectToDb() {
    try {
      this.db = await openDB(this.dbName, environment.dbVersion, {
        upgrade: (database, _oldVersion, _newVersion, transaction) => {
          applyMigrationActions(database, Object.keys(dbMigrations), transaction);
        }
      });
    } catch (e) {
      this.disabledFeaturesService.list.add(DisabledFeature.INDEXEDDB);
    }

    return this.db;
  }
}
