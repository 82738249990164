import { Component } from '@angular/core';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';

@Component({
  template: ` <ng-template [fncTemplateRequestor]="component" let-value="value" let-row="row">
    <span [innerHTML]="this.renderer(value, row)"></span>
  </ng-template>`
})
export class CustomFormatCellComponent extends CellComponent {
  constructor() {
    super();
  }

  renderer: (value: ExtPrimitive, row?: RecordExtPrimitive) => string = value => value.toString();
}
