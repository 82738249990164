import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { DisabledFeatureMessages } from './disabled-features.model';

export enum DisabledFeature {
  INDEXEDDB = 'FF_FP_DISABLED_DB'
}

export const DisabledFeatureMessage: DisabledFeatureMessages = {
  [DisabledFeature.INDEXEDDB]: {
    alert: _('COMMON.DISABLED_FEATURES.UNAVAILABLE_INDEXEDDB'),
    type: 'warning'
  }
};
