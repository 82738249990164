export const TableCellStyles = {
  textBold: 'text-bold',
  textRight: 'text-right',
  textLeft: 'text-left',
  textCenter: 'text-center',
  textOverflow: 'text-overflow',
  textRightOverflow: 'text-right text-overflow',
  textInitial: 'text-initial',
  checkbox: 'fnc-table__column-checkbox',
  smallPaddingLeft: 'fnc-table__column-side__sibling-small-right',
  smallPaddingRight: 'fnc-table__column-side__sibling-small-left',
  noPaddingLeft: 'fnc-table__column-side__sibling-right',
  noPaddingRight: 'fnc-table__column-side__sibling-left',
  smallPadding: 'fnc-table__column-side',
  editableComment: 'fnc-table__comment--editable',
  alignBottom: 'align-bottom'
};
