import { Component } from '@angular/core';
import { AccountingPageComponent } from '@fnc-core/components/accounting-page/accounting-page.component';
import { AdminSideMenuItems } from '@fnc-core/components/admin-page/admin-side-menu-items.constant';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  templateUrl: './admin-page.component.html'
})
export class AdminPageComponent extends AccountingPageComponent {
  sideMenu = AdminSideMenuItems;
}
