export enum SingleFieldType {
  NUMBER,
  STRING,
  TEXT
}

export const SingleFieldDefaultValue = {
  [SingleFieldType.NUMBER]: 0,
  [SingleFieldType.STRING]: '',
  [SingleFieldType.TEXT]: ''
};
