import { Route } from '@angular/router';
import { TranslationAdminPortalModules } from '@fnc-app/routes/translation-modules';
import { AdminPageComponent } from '@fnc-core/components/admin-page/admin-page.component';
import { DashboardAdminComponent } from '@fnc-core/components/dashboard-admin/dashboard-admin.component';
import { InitGuard } from '@fnc-core/guards/init.guard';
import { Path } from '@fnc-shared/constants/path.constant';
import { PermissionsGuard } from '@mm-ui/core';

export const AdminPortalRoute: Route = {
  path: Path.admin,
  canActivate: [InitGuard],
  component: AdminPageComponent,
  data: {
    breadcrumb: 'NAVIGATION.MAIN.ADMIN_PORTAL',
    i18n: TranslationAdminPortalModules
  },
  children: [
    {
      path: '',
      canActivate: [PermissionsGuard],
      component: DashboardAdminComponent,
      data: {
        title: 'NAVIGATION.MAIN.ADMIN',
        permissions: {
          rule: {
            $or: ['ROLE_CAN_VIEW_FAILED_MESSAGES']
          },
          navigateTo: ['/', Path.forbidden]
        }
      }
    },
    {
      path: Path.failedMessages,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/failed-messages/failed-messages.module').then(m => m.FailedMessagesModule),
      data: {
        breadcrumb: 'NAVIGATION.ADMIN.FAILED_MESSAGES',
        title: 'NAVIGATION.ADMIN.FAILED_MESSAGES',
        permissions: {
          rule: 'ROLE_CAN_VIEW_FAILED_MESSAGES',
          navigateTo: ['/', Path.forbidden]
        }
      }
    }
  ]
};
