import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpStatus } from '@mm-ui/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Path } from '@fnc-shared/constants/path.constant';
import { environment } from '@fnc-env/environment';

@Injectable({ providedIn: 'root' })
export class CustomInterceptor implements HttpInterceptor {
  private lastErrorResponse: HttpErrorResponse;

  constructor(private readonly router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError(resp => {
        if (resp instanceof HttpErrorResponse && this.lastErrorResponse?.status !== resp.status) {
          this.lastErrorResponse = resp;

          switch (resp.status) {
            case HttpStatus.CODE401:
              if (environment.production) {
                const { href } = window.location;
                window.location.href = `${environment.smsAppPath}?referrer=${href}`;
              } else {
                void this.router.navigate([Path.signin]);
              }
              break;
            case HttpStatus.CODE403:
              void this.router.navigate([Path.forbidden]);
              break;
            default:
          }
        }

        return throwError(resp);
      })
    );
  }
}
