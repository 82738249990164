import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccountingSideMenuItems } from '@fnc-core/components/accounting-page/accounting-side-menu-items.constant';
import { DefaultNavigateComponent } from '@fnc-core/components/default-navigate/default-navigate.component';
import { PermissionsService } from '@mm-ui/core';

@Component({
  templateUrl: './dashboard-accounting.component.html'
})
export class DashboardAccountingComponent extends DefaultNavigateComponent {
  protected sideMenuItems = AccountingSideMenuItems;

  constructor(router: Router, permissions: PermissionsService) {
    super(router, permissions);
  }
}
