import { Injectable } from '@angular/core';
import { ApiService, NotificationService } from '@mm-ui/core';
import { HttpErrorExtResponse } from '@mm-ui/core/lib/services/api/http-error-ext-response.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { isUndefined } from 'lodash';
import { NotificationChannel, NotificationChannelName } from '../../notification-events/notification-events.constant';
import { PaymentTermCondition, Vendor, VendorAddress, VendorAddressBlock } from './vendor';
import { VendorsStore } from './vendors.store';
import { url } from './vendors.url';
import { VendorsQuery } from '@fnc-core/services/vendors/state/vendors.query';
import { isDateSameOrAfter } from '@fnc-shared/helpers/dates/date.helpers';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class VendorsService {
  addressBlock: VendorAddressBlock = {
    label: '',
    info: []
  };

  constructor(
    private readonly vendorsStore: VendorsStore,
    private readonly api: ApiService,
    private readonly vendorQuery: VendorsQuery,
    private readonly notificationsService: NotificationService<NotificationChannel>
  ) {}

  getListVendors() {
    return this.api.get(url.vendors, undefined, undefined, { isCacheEnabled: true }).pipe(
      tap(data => {
        this.vendorQuery.isLoaded = true;
        this.vendorsStore.set(Object.values(data));
      }),
      catchError((error: HttpErrorExtResponse) => {
        this.vendorsStore.set([]);

        return throwError(error);
      })
    );
  }

  getVendorIdByName(name: string) {
    const chosenVendor = this.vendorQuery.getAll().find(item => name && item.name === name);

    return chosenVendor?.id;
  }

  getVendorNamesList() {
    return this.vendorQuery.getAll().map((item: Vendor) => item.name);
  }

  getVendorAddressInfo(addresses: VendorAddress[], types: string[] = ['billing', 'main']) {
    let address: VendorAddress | undefined;

    for (const type of types) {
      if (!address) {
        address = addresses.find((item: VendorAddress) => item.type?.toLowerCase() === type);
      }
    }

    return this.getFormattedAddress(address);
  }

  getActivePaymentTermCondition(vendor: Vendor) {
    const conditions = vendor?.paymentTermConditions;
    const now = new Date();

    if (isUndefined(conditions)) {
      return null;
    }

    const filtered = conditions.filter(
      (condition: PaymentTermCondition) => isDateSameOrAfter(condition.validFrom, now) && isDateSameOrAfter(now, condition.validTo)
    );

    return (
      (filtered.length &&
        filtered.reduce((prev: PaymentTermCondition, curr: PaymentTermCondition) => (prev.value < curr.value ? prev : curr))) ||
      null
    );
  }

  subscribeToVendorsList() {
    if (this.vendorQuery.isLoaded) {
      return;
    }

    this.getListVendors().subscribe();
    this.subscribeToVendorsUpdates();
  }

  subscribeToVendorsUpdates() {
    this.notificationsService
      .filteredChannels([NotificationChannelName.SYSTEM])
      .pipe(
        untilDestroyed(this),
        filter(messages => {
          const unifiedMessages = Array.isArray(messages) ? messages : [messages];

          return !!unifiedMessages.filter(message => {
            const params = message?.parameters;

            return params?.type === 'OUTDATED' && params?.entity === 'ENTITY_VENDORS';
          }).length;
        })
      )
      .subscribe(() => {
        this.getListVendors().subscribe();
      });
  }

  private getFormattedAddress(address: VendorAddress | undefined): Partial<VendorAddressBlock> {
    const addressLabels = {
      billing: 'VENDORS.PROPERTIES.BILLING_ADDRESS',
      main: 'VENDORS.PROPERTIES.MAIN_ADDRESS'
    };

    if (address) {
      const addressBlock: Partial<VendorAddressBlock> = {};
      addressBlock.label = addressLabels[address.type] || 'VENDORS.PROPERTIES.BILLING_ADDRESS';
      addressBlock.info = [address.line1, address.line2, [address.zip, address.city].filter(n => n).join(' '), address.country].filter(
        n => n
      );

      return addressBlock;
    }

    return this.addressBlock;
  }
}
