<fnc-main-menu-horizontal *ngIf="isHorizontalHeader; else verticalHeader"></fnc-main-menu-horizontal>

<ng-template #verticalHeader>
  <fnc-main-menu></fnc-main-menu>
</ng-template>

<div [ngClass]="{ 'fnc-side-grid--is-horizontal-header': isHorizontalHeader }" class="fnc-page">
  <mm-page [sideBar]="!isHorizontalHeader" lightBlue>
    <mm-page-content>
      <mm-side-menu [items]="items">
        <div #pageSideContent class="m-sideGrid-sideContent">
          <div
            #pageScrollContainer
            [ngClass]="{ 'm-panel--is-main': twoColContentGrid, 'm-panel--has-inner-panel': hasInnerPanel }"
            class="m-panel m-panel-main-content"
          >
            <router-outlet></router-outlet>
          </div>
          <div *ngIf="twoColContentGrid" class="m-panel m-panel--is-side">
            <router-outlet name="sidebar"></router-outlet>
          </div>
        </div>
      </mm-side-menu>
    </mm-page-content>
  </mm-page>
</div>
