import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fnc-footer-sticky',
  templateUrl: './footer-sticky.component.html',
  styleUrls: ['./footer-sticky.component.scss']
})
export class FooterStickyComponent {
  @Input() primaryButtonText = 'DOCUMENT_FORM.BUTTONS.SAVE';
  @Input() secondaryButtonText = 'DOCUMENT_FORM.BUTTONS.CANCEL';
  @Output() primaryButtonEvent = new EventEmitter();
  @Output() secondaryButtonEvent = new EventEmitter();

  onSubmit() {
    this.primaryButtonEvent.emit();
  }
  onCancel() {
    this.secondaryButtonEvent.emit();
  }
}
