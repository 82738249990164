<mm-horizontal-header>
  <ng-container *ngFor="let item of mainMenu">
    <mm-hh-nav-item
      *mmPermissions="item.rights; operator: '$or'"
      [extraLink]="item.extraLink"
      [href]="item.href"
      [icon]="item.icon"
      [routeObj]="item.routerObj"
      [title]="item.title"
    ></mm-hh-nav-item>
  </ng-container>

  <ng-container *ngFor="let item of rightMenu" right>
    <mm-hh-nav-item
      *mmPermissions="item.rights; operator: '$or'"
      [bullet]="item.bullet"
      [extraLink]="item.extraLink"
      [href]="item.href"
      [icon]="item.icon"
      [menuClass]="item.menuClass"
      [menuTemplate]="item.menuTemplate"
      [routeObj]="item.routerObj"
      [title]="item.title"
    ></mm-hh-nav-item>
  </ng-container>
</mm-horizontal-header>

<ng-template #notificationsMenu>
  <mm-sidebar>
    <fnc-notifications-list></fnc-notifications-list>
  </mm-sidebar>
</ng-template>

<ng-template #profileMenu>
  <mm-sidebar>
    <div mmSidebarTitle>{{ 'NAVIGATION.BOTTOM.PROFILE' | translate }}</div>
    <a [href]="profileSettingsHref" [icon]="'settings'" mmSidebarLink>{{ 'NAVIGATION.BOTTOM.SETTINGS' | translate }}</a>
    <a [href]="logoutHref" [icon]="'logout'" mmSidebarLink>{{ 'NAVIGATION.BOTTOM.LOGOUT' | translate }}</a>
  </mm-sidebar>
</ng-template>
