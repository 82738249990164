import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  constructor(private readonly translateService: TranslateService, private readonly titleService: Title) {}

  setDetailsTitle(detailsId: WeakNumber, detailsTitle?: string, interpolated?: RecordString) {
    if (!detailsTitle) {
      this.titleService.setTitle(detailsId.toString());

      return;
    }

    this.translateService.get([detailsTitle], interpolated).subscribe((translates: RecordString) => {
      this.titleService.setTitle(`${translates[detailsTitle]}${detailsId}`);
    });
  }

  setTitle(title: string) {
    this.translateService.get([title]).subscribe((translates: RecordString) => {
      this.titleService.setTitle(`${translates[title]}`);
    });
  }
}
