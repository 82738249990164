import { MessageRenderer } from './failed-message-renderer.interface';
import { FailedMessage } from '@fnc-app/modules/failed-messages/models/failed-message.interface';
import { DbMigrationCmd } from '@fnc-shared/services/db/db-migrationCmd.constant';

const renderer1 = (_value: unknown, row: FailedMessage) => `<b>Exception:</b> ${row.exception ?? ''}`;
const renderer2 = (_value: unknown, row: FailedMessage) => row?.exception.split('\\').slice(-1)[0];

export const migrationsFailedMessageRenderers: MessageRenderer[] = [
  {
    cmd: DbMigrationCmd.RESET,
    keyPath: ['sourceId', 'functionName']
  },
  {
    cmd: DbMigrationCmd.PUT,
    data: {
      sourceId: '',
      functionName: '',
      sourceCode: renderer1.toString(),
      isDefault: true,
      isReadOnly: true
    }
  },
  {
    cmd: DbMigrationCmd.PUT,
    data: {
      sourceId: 'financeService',
      functionName: 'Exception Message',
      sourceCode: renderer2.toString(),
      isDefault: false,
      isReadOnly: false
    }
  }
];
