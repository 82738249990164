import { ColumnMode } from '@swimlane/ngx-datatable';
import { DefaultRow } from '@mm-ui/components/lib/components/editable-table/models/editable-table-config.model';
import { TableCellStyles } from './data-table-styles.constant';

export const TableCellConfig = {
  alignRight: {
    headerClass: TableCellStyles.textRight,
    cellClass: TableCellStyles.textRight
  },
  alignLeft: {
    headerClass: TableCellStyles.textLeft,
    cellClass: TableCellStyles.textLeft
  },
  alignRightOverflow: {
    headerClass: TableCellStyles.textRight,
    cellClass: TableCellStyles.textRightOverflow
  },
  alignLeftOverflow: {
    headerClass: TableCellStyles.textLeft,
    cellClass: TableCellStyles.textOverflow
  },
  alignCenter: {
    headerClass: TableCellStyles.textCenter,
    cellClass: TableCellStyles.textCenter
  },
  textOverflow: {
    cellClass: TableCellStyles.textOverflow
  },
  sortableField: {
    sortable: true,
    resizeable: false,
    draggable: false
  },
  sortableFixedField: {
    resizeable: false,
    draggable: false,
    canAutoResize: false,
    sortable: true
  },
  staticField: {
    sortable: false,
    resizeable: false,
    draggable: false
  },
  fixedField: {
    sortable: false,
    resizeable: false,
    draggable: false,
    canAutoResize: false
  },
  frozenField: {
    resizeable: false,
    draggable: false,
    canAutoResize: false,
    sortable: false
  }
};

export const PredefinedTableConfigs = {
  defaultState: {
    columnMode: ColumnMode.force,
    reorderable: false,
    swapColumns: false
  },
  defaultEditableState: {
    editable: true,
    removable: true,
    isAddingPossible: true,
    actionSize: 80,
    rowHeight: (row: DefaultRow) => row.height as number,
    footerHeight: 0
  },
  withSummary: {
    summaryRow: true,
    summaryPosition: 'bottom' as const,
    summaryHeight: 'auto' as const
  }
};
