import { Component, Input, TemplateRef } from '@angular/core';
import { DependencyConfig } from './combined-key-value-cell.interface';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';

@Component({
  template: `
    <ng-template [fncTemplateRequestor]="component" let-row="row" let-rowIndex="rowIndex" let-value="value">
      <ul
        class="fnc-key-value-cell-list"
        [class]="cellListClass"
        [class.fnc-key-value-cell-list__item--with-vertical-space-between]="isVerticalSpaceBetween"
      >
        <ng-container *ngFor="let key of keys">
          <ng-container *ngIf="isHiddenByOption(row, key, hideNullValues); else defaultKeyValueCheckDependsOnValue"> </ng-container>
          <ng-template #defaultKeyValueCheckDependsOnValue>
            <ng-container *ngIf="hasOption(hasDependencyOnField, key); else defaultKeyValue">
              <li
                class="fnc-key-value-cell-list__item"
                [class]="cellItemClassByKey ? cellItemClassByKey[key] : ''"
                [class.fnc-key-value-cell-list__item--highlighted]="isHighlighted && isHighlighted[key] && !!row[key]"
                [class.fnc-key-value-cell-list__item--with-space-between]="isWithSpaceBetween"
                [class.fnc-key-value-cell-list__item--with-errors]="isWithErrorsNextToValues"
                *ngIf="
                  hasDependencyOnField[key].shouldBeNull
                    ? isValueNull(row, hasDependencyOnField[key].field)
                    : !isValueNull(row, hasDependencyOnField[key].field)
                "
              >
                <ng-container *ngTemplateOutlet="defaultKeyValueContent"></ng-container>
              </li>
            </ng-container>
          </ng-template>
          <ng-template #defaultKeyValue>
            <li
              class="fnc-key-value-cell-list__item"
              [class]="cellItemClassByKey ? cellItemClassByKey[key] : ''"
              [class.fnc-key-value-cell-list__item--highlighted]="isHighlighted && isHighlighted[key] && !!row[key]"
              [class.fnc-key-value-cell-list__item--with-space-between]="isWithSpaceBetween"
              [class.fnc-key-value-cell-list__item--with-errors]="isWithErrorsNextToValues"
            >
              <ng-container *ngTemplateOutlet="defaultKeyValueContent"></ng-container>
            </li>
          </ng-template>
          <ng-template #defaultKeyValueContent>
            <span class="fnc-key-value-cell-list__item-key" *ngIf="!hideLabels || !hideLabels[key]">
              {{ translationKeys[key] | translate }}:
            </span>
            <span class="fnc-key-value-cell-list__item-value">
              <ng-container *ngIf="templateRefs && templateRefs[key]; else defaultValue">
                <ng-template
                  [ngTemplateOutlet]="templateRefs[key]"
                  [ngTemplateOutletContext]="{ value: value, row: row, rowIndex: rowIndex, key: key }"
                ></ng-template>
              </ng-container>
              <ng-template #defaultValue>
                {{ row[key] | mmAddDash }}
                <mm-icon
                  *ngIf="isHighlighted && isHighlighted[key] && !!row[key]"
                  class="highlighted-cell__icon"
                  color="green"
                  size="0.5"
                  kind="check-small-filled"
                >
                </mm-icon>
              </ng-template>
            </span>
          </ng-template>
        </ng-container>
      </ul>
    </ng-template>
  `,
  styleUrls: ['./combined-key-value-cell.component.scss']
})
export class CombinedKeyValueCellComponent extends CellComponent {
  @Input() keys: string[];
  @Input() isWithSpaceBetween = false;
  @Input() isVerticalSpaceBetween = false;
  @Input() isWithErrorsNextToValues = false;
  @Input() translationKeys: RecordOf<string>;
  @Input() templateRefs?: RecordOf<TemplateRef<ImplicitAny>>;
  @Input() hideLabels?: RecordOf<boolean>;
  @Input() hideNullValues?: RecordOf<boolean>;
  @Input() hasDependencyOnField?: RecordOf<DependencyConfig>;
  @Input() isHighlighted?: RecordOf<boolean>;
  @Input() cellListClass?: string;
  @Input() cellItemClassByKey?: RecordOf<string>;

  hasOption(option: RecordOf<string> | RecordOf<boolean> | RecordOf<DependencyConfig>, key: string) {
    return option?.[key];
  }

  isValueNull(row: RecordOf<string>, key: string) {
    return row[key] === null || typeof row[key] === 'undefined';
  }

  isHiddenByOption(row: RecordOf<string>, key: string, option: RecordOf<string> | RecordOf<boolean>) {
    return this.hasOption(option, key) && this.isValueNull(row, key);
  }
}
