import { Injectable } from '@angular/core';
import { ApiService, HttpStatus, SentryService } from '@mm-ui/core';
import { HttpErrorExtResponse } from '@mm-ui/core/lib/services/api/http-error-ext-response.interface';
import { Angulartics2 } from 'angulartics2';
import { AsyncSubject, BehaviorSubject, of, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PROFILE_LOADING_FAILED } from '../../constants/data-loading-error.constant';
import { HttpResponseHandlerService } from '@fnc-shared/services/http-response-handler.service';
import { url } from '@fnc-core/services/user/user.url';
import { Profile } from '@fnc-core/services/user/profile';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  static $initialized: Subject<boolean | undefined> = new AsyncSubject();

  static initialized() {
    UserService.$initialized.next(true);
    UserService.$initialized.complete();
  }

  profile: Profile;
  isLoggedIn$ = new BehaviorSubject(false);

  constructor(
    private readonly api: ApiService,
    private readonly angulartics2: Angulartics2,
    private readonly responseHandler: HttpResponseHandlerService,
    private readonly sentryService: SentryService
  ) {}

  getProfile(options: RecordExtPrimitive) {
    return this.api.get(url.profile, undefined, options, { isCacheEnabled: true, ignoreBasePath: true }).pipe(
      catchError((error: HttpErrorExtResponse) => {
        if ((error.status as HttpStatus) === HttpStatus.CODE401) {
          return of({});
        } else {
          this.responseHandler.errorAlert(PROFILE_LOADING_FAILED);

          return throwError(error);
        }
      }),
      tap((response: Profile) => {
        this.profile = response;

        if (response?.id) {
          this.isLoggedIn$.next(true);
          this.sentryService.setUser({ id: response.id.toString(), roles: response.roles.join(',') });
          this.angulartics2.setUsername.next(response.id.toString());
        }
      })
    );
  }
}
