import { Injectable } from '@angular/core';
import { InvoiceStatus } from '@fnc-shared-fnc/constants/invoice-status.constant';
import { Color, ColorDegree, tint } from '@fnc-shared/constants/color';
import { ColorType } from '@mm-ui/components/lib/components/icon/color.type';

@Injectable()
export class VendorInvoiceHelpersService {
  getInvoiceTagColor(invoiceStatus: InvoiceStatus) {
    switch (invoiceStatus) {
      case InvoiceStatus.WAITING_FOR_CLEARING:
        return tint(Color.GREY, ColorDegree.L90);

      case InvoiceStatus.WAITING_FOR_APPROVAL:
        return tint(Color.YELLOW, ColorDegree.L60);

      case InvoiceStatus.SUSPENDED:
      case InvoiceStatus.WAITING_FOR_CORRECTION:
        return Color.YELLOW;

      case InvoiceStatus.DECLINED:
      case InvoiceStatus.REVISED:
        return tint(Color.RED, ColorDegree.L80);

      case InvoiceStatus.DRAFT:
        return Color.RED;

      case InvoiceStatus.APPROVED:
        return tint(Color.GREEN, ColorDegree.L90);

      case InvoiceStatus.SUBMITTED_FOR_PAYMENT:
        return tint(Color.GREEN, ColorDegree.L60);

      case InvoiceStatus.SETTLED:
        return Color.GREEN;

      default:
        return tint(Color.GREY, ColorDegree.L90);
    }
  }

  getInvoiceTagTextColor(invoiceStatus: InvoiceStatus): ColorType {
    switch (invoiceStatus) {
      case InvoiceStatus.WAITING_FOR_CLEARING:
      case InvoiceStatus.WAITING_FOR_APPROVAL:
      case InvoiceStatus.SUSPENDED:
      case InvoiceStatus.APPROVED:
      case InvoiceStatus.WAITING_FOR_CORRECTION:
      case InvoiceStatus.SUBMITTED_FOR_PAYMENT:
      case InvoiceStatus.DECLINED:
      case InvoiceStatus.REVISED:
        return Color.GREY;

      case InvoiceStatus.DRAFT:
      case InvoiceStatus.SETTLED:
        return Color.WHITE;

      default:
        return Color.GREY;
    }
  }

  getInvoiceReasonBoxBgColor(status: InvoiceStatus) {
    switch (status) {
      case InvoiceStatus.SUSPENDED:
      case InvoiceStatus.WAITING_FOR_CORRECTION:
        return tint(Color.YELLOW, ColorDegree.L80);
      case InvoiceStatus.DECLINED:
      case InvoiceStatus.DRAFT:
      case InvoiceStatus.REVISED:
        return tint(Color.RED, ColorDegree.L90);
      default:
        return tint(Color.GREEN, ColorDegree.L80);
    }
  }

  getInvoiceNumberNgClass(invoiceStatus: InvoiceStatus) {
    return {
      red: invoiceStatus === InvoiceStatus.DRAFT
    };
  }
}
