<div class="m-row">
  <div class="m-col-xs4of12"></div>
  <div class="m-col-xs4of12">
    <h2>Sign In</h2>
    <form [formGroup]="form" (submit)="onSubmit()">
      <mm-form-field [control]="form.get('username')" errorMessagePrefix="username">
        <mm-label class="mm-label">Username</mm-label>
        <input mmInput placeholder="Username" formControlName="username" data-cy="username" />
      </mm-form-field>
      <mm-form-field [control]="form.get('password')" errorMessagePrefix="password">
        <mm-label class="mm-label">Password</mm-label>
        <input mmInput type="password" placeholder="Password" formControlName="password" data-cy="password" />
      </mm-form-field>
      <button mmButton type="submit" kind="primaryRaised" block [disabled]="!form.valid" data-cy="submit">Submit</button>
    </form>
  </div>
  <div class="m-col-xs4of12"></div>
</div>
