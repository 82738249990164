import { Pipe, PipeTransform } from '@angular/core';
import { QueryParamsService } from '@mm-ui/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'fncWeeklyAggregationRange'
})
export class WeeklyAggregationRangePipe implements PipeTransform {
  private readonly daysInTheWeek = 7;
  private readonly mondayAsDayOfTheWeek = 1;
  private readonly formatTemplate = 'DD. MMM';

  constructor(
    private readonly queryParamsService: QueryParamsService
  ) {}

  transform(value: string | Date) {
    return this.getWeeklyRangeFormat(value);
  }

  getWeeklyRangeFormat(date: string | Date) {
    const dayOfTheWeek = dayjs(date).day();
    const queryParams = this.queryParamsService.getParams();
    const timePeriodFilter = queryParams?.filters?.DATE;
    const dayJsDateFormatted = dayjs(date).format(this.formatTemplate);

    if (dayOfTheWeek === this.mondayAsDayOfTheWeek) {
      const lastFilterDate = timePeriodFilter[1];
      const weekRange = dayjs(date).add(this.daysInTheWeek - this.mondayAsDayOfTheWeek, 'day');

      if (dayjs(weekRange).diff(lastFilterDate, 'day') === this.daysInTheWeek - this.mondayAsDayOfTheWeek) {
        return dayjs(lastFilterDate).format(this.formatTemplate);
      }

      if (dayjs(lastFilterDate).diff(weekRange, 'day') < 0) {
        return `${dayJsDateFormatted} - ${dayjs(lastFilterDate).format(this.formatTemplate)}`;
      }

      return `${dayJsDateFormatted} - ${dayjs(weekRange).format(this.formatTemplate)}`;
    }

    if (dayOfTheWeek === 0) {
      return dayJsDateFormatted;
    }

    const lastDateOfTimePeriod = dayjs(date).add(this.daysInTheWeek - dayOfTheWeek, 'day');

    return `${dayJsDateFormatted} - ${lastDateOfTimePeriod.format(this.formatTemplate)}`;
  }

}
