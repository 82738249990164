import { URLDescriptor } from '@mm-ui/core/lib/services/api/url-descriptor.interface';

export const url: RecordOf<URLDescriptor> = {
  reportsInfo: {
    url: 'v1/finance-reports'
  },
  generateReport: {
    url: 'v1/finance-reports'
  }
};
