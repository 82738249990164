import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { SideMenuProtected } from '@fnc-shared/interfaces/side-menu.interface';
import { Path } from '@fnc-shared/constants/path.constant';

export const FulfilmentSideMenuItems: SideMenuProtected[] = [
  {
    title: _('NAVIGATION.MAIN.FULFILMENT'),
    items: [
      {
        items: [
          {
            routerLink: ['/', Path.fulfilment, Path.saleOrders],
            title: _('NAVIGATION.ACCOUNTING.SALE_ORDERS'),
            rights: ['ROLE_CAN_VIEW_SALE_ORDERS']
          },
          {
            routerLink: ['/', Path.fulfilment, Path.returns],
            title: _('NAVIGATION.FULFILMENT.RETURNS'),
            rights: ['ROLE_CAN_VIEW_RETURNS_LIST']
          }
        ]
      }
    ]
  }
];
