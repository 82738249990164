<h3 class="fnc-page-subtitle">
  <span (click)="onToggleLog()" class="fnc-audit-log__toggler">
    {{ 'COMMON.AUDIT_LOG.TITLE' | translate }}
    <mm-icon [kind]="isOpenedLog ? 'chevron-up' : 'chevron-down'"></mm-icon>
  </span>
</h3>
<mm-datatable
  *ngIf="isOpenedLog"
  [columns]="tableColumnsConfig"
  [config]="tableConfig"
  [headerTooltip]="true"
  [loadingIndicator]="isLoading | async"
  [rows]="tableRows | async"
  class="material sms-table table-small-padding-cell"
  [noDataHeaderVisibility]="true"
  (treeAction)="onTreeAction($event)"
></mm-datatable>
