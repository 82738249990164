import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { getEntityType, PaginationResponse, PaginatorPlugin } from '@datorama/akita';
import { NotificationChannel } from '@fnc-core/services/notification-events/notification-events.constant';
import { NotificationEventsService } from '@fnc-core/services/notification-events/notification-events.service';
import { NOTIFICATION_PAGINATOR } from '@fnc-core/services/notification-events/state';
import { SettingsService } from '@fnc-core/services/settings/settings.service';
import { NotificationsListService } from '@fnc-shared/components/notifications-list/notifications-list.service';
import { PageConfig } from '@fnc-shared/models/page-config';
import { Notification } from '@mm-ui/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'fnc-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  providers: [NotificationsListService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListComponent implements OnInit {
  notifications$: Observable<PaginationResponse<getEntityType<Notification<NotificationChannel>>>>;
  private readonly metaData: Partial<PageConfig> = { limit: 6 };

  constructor(
    public settingsService: SettingsService,
    @Inject(NOTIFICATION_PAGINATOR) public pagerRef: PaginatorPlugin<getEntityType<Notification<NotificationChannel>>>,
    private readonly notificationsListService: NotificationsListService,
    private readonly notificationEventsService: NotificationEventsService
  ) {}

  ngOnInit() {
    this.initPagination();
    this.pagerRef.clearCache();
    this.pagerRef.setFirstPage();
  }

  onPreventClick(event: MouseEvent) {
    event.stopPropagation();
  }

  onTileClick(event: MouseEvent, notification: Notification<NotificationChannel>) {
    event.stopPropagation();
    if (!notification.isRead) {
      this.notificationEventsService.showNotification(notification);
      this.pagerRef.clearPage(this.pagerRef.currentPage);
      this.pagerRef.setPage(this.pagerRef.currentPage);
    }
    const navigationUrl = this.notificationsListService.getNavigationRoute(notification);
    if (navigationUrl) {
      window.open(navigationUrl);
    }
  }

  getPreviousPage(event: MouseEvent) {
    event.stopPropagation();
    this.pagerRef.prevPage();
  }

  getNextPage(event: MouseEvent) {
    event.stopPropagation();
    this.pagerRef.nextPage();
  }

  getTileModifier(notification: Notification<NotificationChannel>) {
    const classList = ['fnc-notifications-list__tile'];
    if (!notification.isRead) {
      classList.push('fnc-notifications-list__tile--unread');
    }
    classList.push(`fnc-notifications-list__tile--${this.notificationEventsService.severityToType(notification.severity)}`);

    return classList.join(' ');
  }

  protected initPagination() {
    this.pagerRef.clearCache({ clearStore: true });

    this.notifications$ = this.pagerRef.pageChanges
      .pipe(
        untilDestroyed(this),
        switchMap((page: number) => {
          const params = {
            limit: this.metaData.limit,
            page
          };

          return this.pagerRef.getPage(
            () => this.notificationEventsService.get(params)
          );
        })
      );
  }
}
