<div (click)="onPreventClick($event)" class="fnc-notifications-list">
  <mm-skeleton-loader
    *ngIf="pagerRef.isLoading$ | async"
    [class]="'fnc-notifications-list__loader'"
    [isAnimated]="true"
    [isShown]="true"
    [rowHeight]="'large'"
    [rowsNumber]="6"
  ></mm-skeleton-loader>
  <div *ngIf="notifications$ | async as notifications" [hidden]="pagerRef.isLoading$ | async">
    <div
      (click)="onTileClick($event, notification)"
      *ngFor="let notification of notifications.data"
      [class]="getTileModifier(notification)"
      [tooltip]="notification.message | translate: notification?.parameters"
      delay="1000"
      placement="left"
    >
      <div class="fnc-notifications-list__message">
        {{ notification.message | translate: notification?.parameters }}
      </div>
      <div class="fnc-notifications-list__information">{{ notification.date | mmDate: settingsService.locale.shortDateTime }}</div>
    </div>
    <div class="fnc-notifications-list__buttons" *ngIf="notifications.data.length; else noNotifications">
      <a
        (click)="getPreviousPage($event)"
        [ngClass]="{ 'fnc-notifications-list__previous-button--hidden': pagerRef.isFirst }"
        class="fnc-notifications-list__previous-button"
      >
        {{ 'NOTIFICATIONS.LIST.ACTION_PREVIOUS' | translate }}
      </a>
      <a
        (click)="getNextPage($event)"
        [ngClass]="{ 'fnc-notifications-list__next-button--hidden': pagerRef.isLast }"
        class="fnc-notifications-list__next-button"
      >
        {{ 'NOTIFICATIONS.LIST.ACTION_NEXT' | translate }}
      </a>
    </div>
    <ng-template #noNotifications>
      <div class="fnc-notifications-list__no-data">{{ 'NOTIFICATIONS.LIST.NO_DATA' | translate }}</div>
    </ng-template>
  </div>
</div>
